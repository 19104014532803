import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const MedicalModalComponent1 = (props) => {
    const [tabs, setTabs] = useState(1);
    return <React.Fragment>
        <ul className="d-flex" id="benifits">
            <li className={`${tabs == 1 ? "active" : ""}`} onClick={(e) => { setTabs(1) }}>Key Benefits</li>
            <li className={`${tabs == 2 ? "active" : ""}`} onClick={(e) => { setTabs(2) }}>Benefits Plus</li>
            <li className={`${tabs == 3 ? "active" : ""}`} onClick={(e) => { setTabs(3) }}>Waiting Period</li>
        </ul>
        <div className="row">
            {tabs == 1 ?
                <div id="tabs1">
                    <di className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>In-patient Treatment</li>
                        <li className="info" data-tooltip-id="In-patient">?</li>
                        <ReactTooltip id="In-patient" place="right" content={`Covers expenses for hospitalization due to disease/illness/lnjury during the policy period that requires an Insured Person's admission in a hospital as an inpatient. Medical expenses directly related to the hospitalization would be payable.`} effect="solid" style={{ width: 200 }} />

                    </di>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Pre Hospitalization Expenses</li>
                        <li className="info" data-tooltip-id="Pre-Hospitalization">?</li>
                        <ReactTooltip id="Pre-Hospitalization" place="right" content={`Cover for expenses for Pre-Hospitalization consultations, investigations and medicines incurred upto 60 days before the date of admission to the hospital.`} effect="solid" style={{ width: 200 }} />

                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Post Hospitalization Expenses</li>
                        <li className="info" data-tooltip-id="Hospitalization1">?</li>
                        <ReactTooltip id="Hospitalization1" place="right" content={`Cover over for expenses for Post-Hospitalization consultations, investigations and medicines incurred upto 90 days after discharge from the hospital.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Heath Checkup</li>
                        <li className="info" data-tooltip-id="Post-Hospitalization">?</li>
                        <ReactTooltip id="Post-Hospitalization" place="right" content={`Cover for expenses for a Preventive Health Check-up upto 1% of previous sum insured subject to a maximum of ₹ 10,000 per policy. The limit is the maximum per policy and more than one insured can utilize the amount`} effect="solid" style={{ width: 200 }} />
                    </div>

                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Daycare-Procedure</li>
                        <li className="info" data-tooltip-id="Checkup">?</li>
                        <ReactTooltip id="Checkup" place="right" content={`Covers expenses for 540+ Day Care Treatment due to disease/illness/injury during the policy period taken at a hospital or a Day Care Centre.`} effect="solid" style={{ width: 200 }} />

                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Cumulative Bonus</li>
                        <li className="info" data-tooltip-id="Procedure">?</li>
                        <ReactTooltip id="Procedure" place="right" content={`50% increase in cumulative bonus for every claim free year, upto a maximum of 100%. In the case a claim is made during the policy year, the cumulative bonus would reduce by 50% in the following year.`} effect="solid" style={{ width: 200 }} />

                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Ambulance Cover</li>
                        <li className="info" data-tooltip-id="Donor">?</li>
                        <ReactTooltip id="Donor" place="right" content={`Covers expenses, upto ₹ 3,000 per hospitalization, on transportation of Insured Person in an ambulance to a Hospital for admission in case of an Emergency or from one hospital to another for better treatment.`} effect="solid" style={{ width: 200 }} />

                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Tax Benefits</li>
                        <li className="info" data-tooltip-id="Benefits">?</li>
                        <ReactTooltip id="Benefits" place="right" content={`The premium amount paid under this policy qualifies for deduction under 80D of Income Tax (Amendment) Act, 1986. This benefit is not applicable for premium amount paid towards accidental death benefit if opted and for premium paid in cash/ or by demand draft. Tax benefits are subject to changes in Income Tax Law.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Room Type</li>
                        <li className="info" data-tooltip-id="Ayush-Treatment">?</li>
                        <ReactTooltip id="Ayush-Treatment" place="right" content={`All Room Categories Covered`} effect="solid" style={{ width: 200 }} />

                    </div>
                    
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Ayush Treatment</li>
                        <li className="info" data-tooltip-id="AYUSH">?</li>
                        <ReactTooltip id="AYUSH" place="right" content={`Cover for expenses incurred on in-patient treatment taken under Ayurveda, Unani, Sidha and Homeopathy in Ayush Hospital.`} effect="solid" style={{ width: 200 }} />

                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Organ Donor</li>
                        <li className="info" data-tooltip-id="Ambulance">?</li>
                        <ReactTooltip id="Ambulance" place="right" content={`Covers Medical and surgical Expenses of the organ donor for harvesting the organ where an Insured Person is the recipient`} effect="solid" style={{ width: 200 }} />

                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Domiciliary Treatment</li>
                        <li className="info" data-tooltip-id="Consumable">?</li>
                        <ReactTooltip id="Consumable" place="right" content={`Cover for expenses related to Domiciliary Hospitalization of the insured person if the treatment exceeds beyond three days. The treatment must be for management of an illness and not for enteral feedings or end of life care.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Consumable Benefits</li>
                        <li className="info" data-tooltip-id="Bariatric">?</li>
                        <ReactTooltip id="Bariatric" place="right" content={`Covers expenses incurred, for consumables which are consumed during the period of hospitalization directly related to the insured person's medical or surgical treatment of illness/disease/injury.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Restore Benefits</li>
                        <li className="info" data-tooltip-id="dTreatment">?</li>
                        <ReactTooltip id="dTreatment" place="right" content={`It automatically restores your sum insured to 100% for you and your family members. We also offer restore for same illness/same person as well.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Compassionate Travel</li>
                        <li className="info" data-tooltip-id="dTreatment1">?</li>
                        <ReactTooltip id="dTreatment1" place="right" content={`Covers expenses upto ₹20,000 related to a round trip economy class air ticket, or ﬁrst-class railway ticket, to allow the Immediate Family Member to be at insured person's bedside during his stay in the hospital.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Second Opinion</li>
                        <li className="info" data-tooltip-id="Second-Opinion">?</li>
                        <ReactTooltip id="Second-Opinion" place="right" content={`Provide You a second opinion from Network Provider or Medical Practitioner, if an Insured Person is diagnosed with the below mentioned Illnesses during the Policy Period. The expert opinion would be directly sent to the Insured Person. Cancer, Kidney Failure, Myocardial Infarction, Angina, Coronary bypass surgery, Stroke/Cerebral hemorrhage, Organ failure requiring transplant, Heart Valve replacement, Brain tumors.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Global Cover</li>
                        <li className="info" data-tooltip-id="Benefit1">?</li>
                        <ReactTooltip id="Benefit1" place="right" content={`Covers Medical Expenses related to Inpatient & Day Care Hospitalization of the Insured Person incurred outside India, provided that the diagnosis was made in India.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Bariatric Surgery</li>
                        <li className="info" data-tooltip-id="Cumulative">?</li>
                        <ReactTooltip id="Cumulative" place="right" content={`Covers expenses incurred for Bariatric Surgery for treatment of Obesity and weight control. T&C Apply`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>In-Patient Dental Treatment</li>
                        <li className="info" data-tooltip-id="Room">?</li>
                        <ReactTooltip id="Room" place="right" content={`Cover for expenses for hospitalization due to disease/ illness/Injury during the policy period that requires an Insured Person’s admission in a hospital as an inpatient.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="d-flex " style={{ justifyContent: "space-between" }}>
                        <li>Vaccination Cover</li>
                        <li className="info" data-tooltip-id="Tax">?</li>
                        <ReactTooltip id="Tax" place="right" content={`Covers expenses related to Anti-rabies vaccine & Typhoid vaccination without any waiting period & Human Papilloma Virus (HPV) vaccine & Hepatitis B Vaccine after 2 years of continuous coverage and the maximum payable is actual or ₹5,000 per policy, whichever is lower.`} effect="solid" style={{ width: 200 }} />
                    </div>
                    <div className="tab-head">
                        Medical Exclusions
                    </div>
                    <p style={{ color: '#071e54' }}>
                        Obesity/ Weight Control, Alcoholic pancreatitis,<br /> Obesity/ Weight Control Treatment for,<br /> Alcoholism, drug or substance abuse or any<br /> addictive condition and consequences thereof.
                    </p>
                    <div className="tab-head">
                        Non Medical Exclusions
                    </div>
                    <p style={{ color: '#071e54' }}>War or any act of war, Hazardous or Adventure Sports, breach of law with criminal intent, Intentional self-injury or attempted suicide. Any Insured Person’s participation or involvement in naval, military or air force operation.</p>
                </div>
                : tabs == 2 ? <div>

                    <div id="tabs1">
                        <div className="tab-head">
                            Health VAS
                        </div>
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <li>Wellness Services</li>
                            <li className="info" data-tooltip-id="Wellness">?</li>
                            <ReactTooltip id="Wellness" place="right" content={`With our New Wellness Services, you can now avail benefits of-8 Teleconsultation sessions by a General Physician and Ambulance Booking facility.`} effect="solid" style={{ width: 200 }} />
                        </div>
                        <div className="tab-head">
                        Benefits Over & Above Sum Insured
                        </div>

                        <di className="d-flex " style={{ justifyContent: "space-between" }}>
                            <li>Daily Cash For Accompanying An Insured Child</li>
                            <li className="info" data-tooltip-id="Accompanying">?</li>
                            <ReactTooltip id="Accompanying" place="right" content={`Covers fixed amount per day, as mentioned in the schedule, if the Insured Person Hospitalized is a child Aged 12 years or less, for one accompanying adult for each complete period of 24 hours. The benefit payable per day would be 0.25% of base sum insured and maximum of ₹2,000 per day.`} effect="solid" style={{ width: 200 }} />
                        </di>
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <li>Daily Cash For Choosing Shared Accommodation</li>
                            <li className="info" data-tooltip-id="Choosing">?</li>
                            <ReactTooltip id="Choosing" place="right" content={`Covers fixed amount per day as mentioned in the policy schedule if the Insured Person is Hospitalized in Shared Accommodation in a Network Hospital for each continuous and completed period of 24 hours. The benefit payable per day would be 0.25% of base sum insured and a maximum of ₹2,000 per day.`} effect="solid" style={{ width: 200 }} />
                        </div>
                        <div className="d-flex " style={{ justifyContent: "space-between" }}>
                            <li>Hearing Aid</li>
                            <li className="info" data-tooltip-id="Hearing-Aid">?</li>
                            <ReactTooltip id="Hearing-Aid" place="right" content={`Cover for reasonable charges for a hearing aid every third year. The maximum amount payable is 50% of actual cost or ₹10,000 per policy, whichever is lower.`} effect="solid" style={{ width: 200 }} />
                        </div>

                        <div className="tab-head">
                            Medical Exclusions
                        </div>
                        <p style={{ color: '#071e54' }}>
                            Obesity/ Weight Control, Alcoholic pancreatitis,<br /> Obesity/ Weight Control Treatment for,<br /> Alcoholism, drug or substance abuse or any<br /> addictive condition and consequences thereof.
                        </p>
                        <div className="tab-head">
                            Non Medical Exclusions
                        </div>
                        <p style={{ color: '#071e54' }}>War or any act of war, Hazardous or Adventure Sports, breach of law with criminal intent, Intentional self-injury or attempted suicide. Any Insured Person’s participation or involvement in naval, military or air force operation.</p>
                    </div>
                </div> :
                    <div>

                        <div id="tabs1">
                            <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                <li>Policy coverage starts 30 days from the first inception of the policy (except accident).</li>
                            </div>
                            <div className="d-flex mt-1" style={{ justifyContent: "space-between" }}>
                                <li>Any existing illness will be covered after a waiting period of 24 months. T&C Apply.</li>
                            </div>
                            <div className="d-flex mt-1" style={{ justifyContent: "space-between" }}>
                                <li>Any pre-existing condition will be covered after a waiting period of 36 months. T&C Apply.</li>
                            </div>
                            <div className="tab-head mt-1">
                                Medical Exclusions
                            </div>
                            <p style={{ color: '#071e54' }}>
                                Obesity/ Weight Control, Alcoholic pancreatitis,<br /> Obesity/ Weight Control Treatment for,<br /> Alcoholism, drug or substance abuse or any<br /> addictive condition and consequences thereof.
                            </p>
                            <div className="tab-head">
                                Non Medical Exclusions
                            </div>
                            <p style={{ color: '#071e54' }}>War or any act of war, Hazardous or Adventure Sports, breach of law with criminal intent, Intentional self-injury or attempted suicide. Any Insured Person’s participation or involvement in naval, military or air force operation.</p>
                        </div>
                    </div>
            }
        </div>
    </React.Fragment>
}
export default MedicalModalComponent1;