import React, { useEffect, useState } from "react";
import "../SIP/sipcalculator.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";

export default function MutualFundCal() {
  const navigate = useNavigate();
  const [investValue, setInvestValue] = React.useState(25000);
  const [rate, setRate] = React.useState(12);
  const [time, setTime] = React.useState(10);
  const [investFlag, setInvestFlag] = React.useState(false);
  const [investErr, setInvestErr] = React.useState("");
  const [timetFlag, setTimeFlag] = React.useState(false);
  const [timetErr, setTimeErr] = React.useState("");
  const [ratetFlag, setRateFlag] = React.useState(false);
  const [ratetErr, setRateErr] = React.useState("");
  const [invsVal, setEnvVal] = React.useState("");
  const [returnVal, setReturnVal] = React.useState("");
  const [totalVal, setTotalVal] = React.useState("");
  const [data, setData] = useState({
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
      tooltip: {
        y: {
          title: {
            formatter(seriesName) {
              return seriesName;
            },
          },
        },
      },
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8,
        },
      },
      colors: ["#0073BB", "#E05776"],
    },
    series: [],
  });

  const handleChangeComplete = () => {};

  useEffect(() => {
    if (investValue < 500) {
      setInvestFlag(true);
      setInvestErr("Minimum value allowed is 500");
    } else if (investValue > 10000000) {
      setInvestFlag(true);
      setInvestErr("Maximun value allowed is 10000000");
    } else {
      setInvestFlag(false);
      setInvestErr("");
    }
  }, [investValue]);

  useEffect(() => {
    if (rate < 1) {
      setRateFlag(true);
      setRateErr("Minimum value allowed is 1");
    } else if (rate > 30) {
      setRateFlag(true);
      setRateErr("Maximun value allowed is 30");
    } else {
      setRateFlag(false);
      setRateErr("");
    }
  }, [rate]);

  useEffect(() => {
    if (time == 0) {
      setTimeFlag(true);
      setTimeErr("Minimum value allowed is 1");
    } else if (time > 40) {
      setTimeFlag(true);
      setTimeErr("Maximun value allowed is 40");
    } else {
      setTimeFlag(false);
      setTimeErr("");
    }
  }, [time]);

  const handleCalculation = (investment, rate, time) => {
    if (
      parseInt(investment) > 499 &&
      parseInt(rate) > 0 &&
      parseInt(time) > 0
    ) {
      // debugger
      const monthlyRate = rate / 12 / 100;
      const months = time * 12;
      let futureValue = 0;
      futureValue =
        investment *
        ((Math.pow(1 + monthlyRate, months) - 1) / monthlyRate) *
        (1 + monthlyRate);
      const invesmet_amonut = investment * 12 * time;
      const est_rtn = futureValue - invesmet_amonut;
      let nf = new Intl.NumberFormat("en-IN");
      setEnvVal(nf.format(invesmet_amonut.toFixed(0)));
      setReturnVal(nf.format(est_rtn.toFixed(0)));
      setTotalVal(nf.format(futureValue.toFixed(0)));
      let dt = data;
      dt.options.labels[0] =
        "Invested Amount(₹) : " + " " + nf.format(invesmet_amonut?.toFixed(0));
      dt.options.labels[1] =
        "Wealth Gained(₹) : " + " " + nf.format(est_rtn?.toFixed(0));
      dt.series[0] = invesmet_amonut;
      dt.series[1] = parseInt(est_rtn.toFixed(0));
      setData(dt);
    }
  };

  useEffect(() => {
    handleCalculation(investValue, rate, time);
  }, [investValue, rate, time]);

  function Example(props) {
    return (
      <Chart
        className="donut-chart"
        options={data.options}
        series={data.series}
        type="donut"
      />
    );
  }

  return (
    <div>
        <Tabs/>
    <div className="custome-row">
    <div className="container-fluid mt-5 pt-5">
      <div className="path-text ">
        {" "}
        <span
          className="ta-cursor"
          onClick={() => {
            navigate("/");
          }}
        >
          Calculator
        </span>{" "}
        &gt; Mutual Fund Calculator
      </div>
      <div className="mt-4 fd-cal-text ">Mutual Fund Calculator</div>
      <div className="row  ">
        <div className="col-lg-8 col-12 mt-4">
          <div className="background p-4" id="content-mf">
            <div>
              <div className="d-flex justify-content-between">
                <div>Monthly Investment</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {investFlag === true ? (
                      <svg
                        data-tooltip-id="invest"
                        style={{ color: "#E05776" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="18"
                        width="18"
                        class="cis93InfoIcon contentNegative"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                      </svg>
                    ) : (
                      ""
                    )}
                  </span>
                  &nbsp;
                  <ReactTooltip id="invest" place="top" content={investErr} />
                  <input
                    style={{
                      background:
                        investFlag === true
                          ? "rgba(187, 3, 0, 0.1)"
                          : "rgba(0, 115, 187, 0.10)",
                      color: investFlag === true ? "#E05776" : "",
                    }}
                    className={`form-control shadow-none sm-fl rd-max1`}
                    id={`${investFlag === true ? "fname1" : "fname"}`}
                    value={"₹"}
                    readOnly
                  />
                  <input
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                      } else if (e.target.value.includes(".") == true) {
                      } else if (e.target.value.includes("+") == true) {
                      } else if (e.target.value.includes("-") == true) {
                      } else {
                        if (e.target.value > 10000000) {
                          setInvestValue(10000000);
                        } else if (e.target.value == "") {
                          setInvestValue(0);
                        } else if (e.target.value == 0) {
                          setInvestValue(0);
                        } else {
                          if (
                            e.target.value.indexOf(0) == "0" &&
                            e.target.value.length > 1
                          ) {
                            let va = e.target.value.substring(1);
                            setInvestValue(va);
                          } else if (
                            investValue == 0 &&
                            e.target.value?.split("")[1] == "0"
                          ) {
                            let va = e.target.value?.split("")[0];
                            setInvestValue(va);
                          } else {
                            setInvestValue(e.target.value);
                          }
                        }
                      }
                    }}
                    value={investValue}
                    maxLength={10000000}
                    style={{
                      background:
                        investFlag === true
                          ? "rgba(187, 3, 0, 0.1)"
                          : "rgba(0, 115, 187, 0.10)",
                      color: investFlag === true ? "#E05776" : "",
                      paddingLeft: 0,
                      textAlign: "right",
                    }}
                    className={`shadow-none form-control sm-bg`}
                    type="text"
                    id={`${investFlag === true ? "fname1" : "fname"}`}
                    name="fname"
                  />
                </div>
              </div>
              <Slider
                min={500}
                max={10000000}
                value={investValue}
                onChange={(value) => {
                  setInvestValue(value);
                }}
                onChangeComplete={handleChangeComplete}
                step={500}
              />
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <div>Expected Return</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {ratetFlag === true ? (
                      <svg
                        data-tooltip-id="rate"
                        style={{ color: "#E05776" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="18"
                        width="18"
                        class="cis93InfoIcon contentNegative"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                      </svg>
                    ) : (
                      ""
                    )}
                  </span>
                  &nbsp;
                  <ReactTooltip id="rate" place="top" content={ratetErr} />
                  <div className="d-flex">
                    <input
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                        } else if (e.target.value.includes("+") == true) {
                        } else if (e.target.value.includes("-") == true) {
                        } else {
                          if (rate > 30) {
                            setRate(30);
                            return false;
                          } else if (rate != "0" && e.target.value > 30) {
                            setRate(30);
                            return false;
                          } else if (e.target.value === "") {
                            setRate(0);
                            return;
                          } else {
                            if (
                              e.target.value.includes(".") == true &&
                              e.target.value?.split("")[1] == "0" &&
                              e.target.value?.split("")[0] != 1
                            ) {
                              setRate(0);
                              return;
                            }
                            if (
                              e.target.value.indexOf(0) == "0" &&
                              e.target.value >= 1
                            ) {
                              let va = e.target.value.substring(1);
                              setRate(va);
                              return;
                            } else if (
                              rate == 0 &&
                              e.target.value?.split("")[1] == "0"
                            ) {
                              let va = e.target.value?.split("")[0];
                              setRate(va);
                              return;
                            } else {
                              if (
                                e.target.value.includes(".") == true &&
                                e.target.value >= 1
                              ) {
                                let srr = e.target.value.split(".");
                                if (srr[1]?.length > 2) {
                                } else {
                                  let str = e?.target?.value;
                                  setRate(str);
                                  return;
                                }
                              } else if (e.target.value >= 1) {
                                let str = e?.target?.value;
                                setRate(str);
                                return;
                              }
                            }
                          }
                        }
                      }}
                      value={rate}
                      className="shadow-none form-control sm-bgr "
                      style={{
                        color: ratetFlag === true ? "#E05776" : "",
                        textAlign: "right",
                        paddingRight: 0,
                        marginLeft: 10,
                      }}
                      type="text"
                      id={`${ratetFlag === true ? "fname1" : "fname"}`}
                      name="fname"
                    />
                    <span
                      style={{
                        background:
                          ratetFlag === true
                            ? "rgba(187, 3, 0, 0.1)"
                            : "rgba(0, 115, 187, 0.10)",
                        color: ratetFlag === true ? "#E05776" : "",
                        marginLeft: 3,
                        paddingLeft: 2,
                      }}
                      className="shadow-none form-control sm-fr wdthcls"
                      id={`${ratetFlag === true ? "fname1" : "fname"}`}
                      value={"%"}
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
              <Slider
                min={1}
                max={30}
                value={rate}
                onChange={(value) => {
                  setRate(value.toFixed(0));
                }}
                onChangeComplete={handleChangeComplete}
                step={1}
              />
            </div>
            <div>
              <div className="d-flex justify-content-between ">
                <div>Investment Period</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {timetFlag === true ? (
                      <svg
                        data-tooltip-id="time"
                        style={{ color: "#E05776" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="18"
                        width="18"
                        class="cis93InfoIcon contentNegative"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                      </svg>
                    ) : (
                      ""
                    )}
                  </span>
                  &nbsp;
                  <ReactTooltip id="time" place="top" content={timetErr} />
                  <input
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                      } else if (e.target.value.includes("+") == true) {
                      } else if (e.target.value.includes("-") == true) {
                      } else if (e.target.value.includes(".") == true) {
                      } else {
                        if (time != "0" && e.target.value > 40) {
                          setTime(40);
                        } else if (e.target.value == "") {
                          setTime(0);
                        } else if (e.target.value == 0) {
                          setTime(0);
                        } else {
                          if (
                            e.target.value.indexOf(0) == "0" &&
                            e.target.value.length > 1
                          ) {
                            let va = e.target.value.substring(1);
                            setTime(va);
                          } else if (
                            time == 0 &&
                            e.target.value?.split("")[1] == "0"
                          ) {
                            let va = e.target.value?.split("")[0];
                            setTime(va);
                          } else {
                            setTime(e.target.value);
                          }
                        }
                      }
                    }}
                    value={time}
                    className="shadow-none form-control sm-bgr absright"
                    style={{
                      color: timetFlag === true ? "#E05776" : "",
                      paddingLeft: 0,
                      textAlign: "right",
                      paddingRight: 2,
                    }}
                    type="text"
                    id={`${timetFlag === true ? "fname1" : "fname"}`}
                    name="fname"
                  />
                  <label
                    style={{
                      background:
                        timetFlag === true
                          ? "rgba(187, 3, 0, 0.1)"
                          : "rgba(0, 115, 187, 0.10)",
                      color: timetFlag === true ? "#E05776" : "",
                      marginLeft: 2,
                    }}
                    className="shadow-none form-control sm-fr wdthcls"
                    htmlFor={`${timetFlag === true ? "fname1" : "fname"}`}
                    id={`${timetFlag === true ? "fname1" : "fname"}`}
                  >
                    Yr
                  </label>
                </div>
              </div>
              <Slider
                min={1}
                max={40}
                value={time}
                onChange={(value) => {
                  setTime(value);
                }}
                onChangeComplete={handleChangeComplete}
              />
            </div>
            <div className="d-flex justify-content-between pb-4">
              <div className="mf-count-text mt-4">
                <b>Invested Amount</b>
              </div>
              <div className="mt-4">
                <b>₹ {invsVal}</b>
              </div>
            </div>
            <div className="d-flex justify-content-between ">
              <div className="mf-count-text">
                <b>Wealth Gained</b>
              </div>
              <div>
                <b>₹ {returnVal}</b>
              </div>
            </div>
            <div className="d-flex justify-content-between pt-3 pb-4 mb-1">
              <div className="mf-count-text">
                <b>Expected Amount</b>
              </div>
              <div>
                <b>₹ {totalVal}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0">
          <div className="background p-4 text-center">
            <div className="pt-4"></div>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <div className="circle-blue"></div>
                <div className="circle-text">Invested Amount</div>
              </div>
              <div className="d-flex justify-content-center mx-4 pb-3">
                <div className="circle-pink"></div>
                <div className="circle-text">Wealth Gained</div>
              </div>
            </div>
            {<Example />}
            <div className="pb-5"></div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}
