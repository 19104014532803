import React, { useEffect, useState } from "react";
import "./FutreValuePresentMoney.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";


export default function FutureValueCalculator() {
    const navigate = useNavigate()
    const [initialValue, setInitialValue] = React.useState(1000);
    const [periodicValue, setPeriodicValue] = React.useState(100);
    const [rate, setrate] = React.useState(5);
    const [duration, setDuration] = React.useState(1);
    const [initialFlag, setInitialFlag] = React.useState(false);
    const [initialErr, setInitialErr] = React.useState("");
    const [durationFlag, setDurationFlag] = React.useState(false);
    const [durationErr, setDurationErr] = React.useState("");
    const [rateFlag, setRateFlag] = React.useState(false);
    const [rateErr, setRateErr] = React.useState("");
    const [periodicFlag, setPeriodicFlag] = React.useState(false);
    const [periodicErr, setPerodicErr] = React.useState("");
    const [futureval, setFutureVal] = React.useState("");
    const [presentVal, setPrsentVal] = React.useState("");
    const [periodicDep, setPeriodicDep] = React.useState("");
    const [totalInt, setTotalInt] = React.useState("");
    const [data, setData] = useState({
        options: {
            chart: {
                type: 'donut',
            },
            labels: [],
            tooltip: {
                y: {
                    title: {
                        formatter(seriesName) {
                            return seriesName
                        }
                    }
                }
            },
            dataLabels: {
                dropShadow: {
                    blur: 3,
                    opacity: 0.8,
                }
            },
            colors: ['#0073BB', '#E05776'],
        },
        series: [],

    })

    const handleChangeComplete = () => {
    }
    useEffect(() => {
        
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (initialValue <= 0) {
            setInitialFlag(true);
            setInitialErr("Minimum value should be greater than 0")
        }
        else if (initialValue > 10000000) {
            setInitialFlag(true);
            setInitialErr("Maximum value should not be greater than 10000000")
        }
        else {
            setInitialFlag(false);
            setInitialErr("")
        }
    }, [initialValue]);

    useEffect(() => {
        if (periodicValue <= 0) {
            setPeriodicFlag(true);
            setPerodicErr("Minimum value should be greater than 0")
        }
        else if (periodicValue > 10000000) {
            setPeriodicFlag(true);
            setPerodicErr("Maximum value should not be greater than 10000000")
        } else {
            setPeriodicFlag(false);
            setPerodicErr("")
        }
    }, [periodicValue]);
    useEffect(() => {
        if (rate <= 0) {
            setRateFlag(true);
            setRateErr("Minimum value should be greater than 0")
        }
        else if (rate > 100) {
            setRateFlag(true);
            setRateErr("Maximum value should not be greater than 100")
        } else {
            setRateFlag(false);
            setRateErr("")
        }
    }, [rate]);


    useEffect(() => {
        if (duration <= 0) {
            setDurationFlag(true);
            setDurationErr("Minimum value should be greater than 0")
        } else if (duration > 100) {
            setDurationFlag(true);
            setDurationErr("Maximum value should not be greater than 100")
        } else {
            setDurationFlag(false);
            setDurationErr("")
        }
    }, [duration]);

    const handleCalculation = (initialValue1, periodicValue1, rate, duration) => {
        let starting_amount = parseFloat(initialValue1);//Initial Investment
        let periodic_investment = parseFloat(periodicValue1);//Periodic Investment
        let rate_ofinterest = parseFloat(rate);//Rate of Interest
        let number_ofperiods = parseFloat(duration);//Number of Periods
        if(starting_amount>0 && periodic_investment>0 && rate_ofinterest>0 && number_ofperiods>0){

            rate_ofinterest = rate_ofinterest / 100;
            let i = 0;
            let end_balance = 0;
            let total_interest = 0;
            let total_periodic_investment = 0;
            let present_value = 0;
            let ini = starting_amount * rate_ofinterest;
            let initial_investment = starting_amount;
            let interest = initial_investment * rate_ofinterest;
            end_balance = initial_investment * Math.pow(1 + rate_ofinterest, number_ofperiods) +
                periodic_investment * ((Math.pow(1 + rate_ofinterest, number_ofperiods) - 1) / rate_ofinterest);
            present_value = end_balance / Math.pow(1 + rate_ofinterest, number_ofperiods);
            total_periodic_investment = number_ofperiods * periodic_investment;
            total_interest = Math.ceil(end_balance - starting_amount - (periodic_investment * number_ofperiods));
            let nf = new Intl.NumberFormat('en-IN');
            setFutureVal(nf.format(end_balance?.toFixed(2)));
            setPrsentVal(nf.format(present_value?.toFixed(2)));
            setPeriodicDep(nf.format(total_periodic_investment.toFixed(2)));
            setTotalInt(nf.format(total_interest.toFixed(2)));
            let dt = data;
            dt.options.labels[0] = "Total Interest(₹) : " + " " + '₹' + " " + nf.format(total_interest?.toFixed(2));
            dt.options.labels[1] = "Total Periodic Investment(₹) : " + " " + '₹' + " " + nf.format(total_periodic_investment?.toFixed(2));
            dt.series[1] = parseFloat(total_periodic_investment.toFixed(2))
            dt.series[0] = parseFloat(total_interest.toFixed(2))
            setData(dt);
        }else{
            let nf = new Intl.NumberFormat('en-IN');
            setFutureVal(0);
            setPrsentVal(0);
            setPeriodicDep(0);
            setTotalInt(0);
            let dt = data;
            dt.options.labels[0] = "Total Interest(₹) : " + " " + '₹' + " " + nf.format(0);
            dt.options.labels[1] = "Total Periodic Investment(₹) : " + " " + '₹' + " " + nf.format(0);
            dt.series[0] = 50
            dt.series[1] = 50
            setData(dt);
        }
    }


    useEffect(() => {
        handleCalculation(initialValue, periodicValue, rate, duration);
    }, [initialValue, periodicValue, duration, rate]);

    function Example(props) {
        return (
            <Chart className='donut-chart' options={data.options} series={data.series} type="donut" />
        );
    }

    return (
        <div>
            <Tabs />
            <div className="custome-row">
                <div className="container-fluid">
                    <div className='path-text '> <span className="ta-cursor" onClick={() => {
                        navigate("/")
                    }}>Calculator</span> &gt; <span style={{color:"#0073bb"}}>Future Value Present Money Calculator</span></div>
                    {/* <div className='mt-4 fd-cal-text '> Future Value Present Money Calculator</div> */}
                    <div className='row  '>
                        <div className='col-lg-8 col-12 mt-2'>
                            <div className='background' id="content-sip">
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Initial Investment</div>
                                        <div className="d-flex" style={{ width: 115 }}>
                                            <span>
                                                {initialFlag === true ? <svg data-tooltip-id="initialValue" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                            </span>&nbsp;
                                            <ReactTooltip id="initialValue" place="top" content={initialErr} />
                                            <input style={{ background: initialFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: initialFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl rd-max1`} id={`${initialFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                                            <input onChange={e => {
                                                if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                                                    if (e.target.value > 10000000) { setInitialValue(10000000); } else if (e.target.value == "") { setInitialValue(0); } else if (e.target.value == 0) { setInitialValue(0); } else {
                                                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                            let va = e.target.value.substring(1)
                                                            setInitialValue(va)
                                                        } else if (initialValue == 0 && e.target.value?.split("")[1] == '0') {
                                                            let va = e.target.value?.split("")[0];
                                                            setInitialValue(va)
                                                        } else {
                                                            setInitialValue(e.target.value)
                                                        }
                                                    }
                                                }
                                            }} value={initialValue} maxLength={10000000} style={{ background: initialFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: initialFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${initialFlag === true ? "fname1" : "fname"}`} name="fname" />
                                        </div>
                                    </div>
                                    <Slider
                                        min={1}
                                        max={10000000}
                                        value={initialValue}
                                        onChange={(value) => {
                                            setInitialValue(value)
                                        }}
                                        onChangeComplete={handleChangeComplete}
                                        step={1000}


                                    />
                                </div>
                                <div>
                                    <div className='d-flex justify-content-between'>
                                        <div>Periodic Investment</div>
                                        <div className="d-flex" style={{ width: 115 }}>
                                            <span>
                                                {periodicFlag === true ? <svg data-tooltip-id="periodicValue" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                            </span>&nbsp;
                                            <ReactTooltip id="periodicValue" place="top" content={periodicErr} />
                                            <input style={{ background: periodicFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: periodicFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl rd-max1`} id={`${periodicFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                                            <input onChange={e => {
                                                if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                                                    if (e.target.value > 10000000) { setPeriodicValue(10000000); } else if (e.target.value == "") { setPeriodicValue(0); } else if (e.target.value == 0) { setPeriodicValue(0); } else {
                                                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                            let va = e.target.value.substring(1)
                                                            setPeriodicValue(va)
                                                        } else if (periodicValue == 0 && e.target.value?.split("")[1] == '0') {
                                                            let va = e.target.value?.split("")[0];
                                                            setPeriodicValue(va)
                                                        } else {
                                                            setPeriodicValue(e.target.value)
                                                        }
                                                    }
                                                }
                                            }} value={periodicValue} maxLength={10000000} style={{ background: periodicFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: periodicFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${periodicFlag === true ? "fname1" : "fname"}`} name="fname" />
                                        </div>
                                    </div>
                                    <Slider
                                        min={1}
                                        max={10000000}
                                        value={periodicValue}
                                        onChange={(value) => {
                                            setPeriodicValue(value)
                                        }}
                                        onChangeComplete={handleChangeComplete}

                                    />
                                </div>
                                <div>
                                    <div className='d-flex justify-content-between'>

                                        <div>Rate of Interest</div>
                                        <div className="d-flex" style={{ width: 115 }}>
                                            <span >
                                                {rateFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                            </span>&nbsp;
                                            <ReactTooltip id="rate" place="top" content={rateErr} />

                                            <input
                                                onChange={e => {
                                                    const inputValue = e.target.value;
                                                    if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true && e.target.value?.split(".")[1]?.length > 2) { } 
                                                    else if (parseFloat(inputValue) === 100 && inputValue.includes(".")) {
                                                        e.preventDefault();
                                                    }
                                                    else {
                                                        if (e.target.value == "") { setrate(0); } else if (e.target.value > 100) { setrate(100); } else if (e.target.value == 0) { setrate(0); } else {
                                                            if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                                let va = e.target.value.substring(1)
                                                                setrate(va)
                                                            } else if (rate == 0 && e.target.value?.split("")[1] == '0') {
                                                                let va = e.target.value?.split("")[0];
                                                                setrate(va)
                                                            } else {
                                                                setrate(e.target.value)
                                                            }
                                                        }
                                                    }

                                                }} value={rate} className='shadow-none form-control sm-bgr absright' style={{ color: rateFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${rateFlag === true ? "fname1" : "fname"}`} name="fname" />
                                            <label style={{ background: rateFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: rateFlag === true ? "#E05776" : '', marginLeft: 2 }} className='shadow-none form-control sm-fr wdthcls' htmlFor={`${rateFlag === true ? "fname1" : "fname"}`} id={`${rateFlag === true ? "fname1" : "fname"}`}>%</label>
                                        </div>

                                    </div>
                                    <Slider
                                        min={1}
                                        max={100}
                                        value={rate}
                                        onChange={(value) => {
                                            setrate(value.toFixed(2))
                                        }}
                                        onChangeComplete={handleChangeComplete}
                                        step={0.1}
                                    />
                                </div>



                                <div>
                                    <div className='d-flex justify-content-between'>

                                        <div>Number of Periods</div>
                                        <div className="d-flex" style={{ width: 115 }}>
                                            <span >
                                                {durationFlag === true ? <svg data-tooltip-id="duration" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                                            </span>&nbsp;
                                            <ReactTooltip id="duration" place="top" content={durationErr} />

                                            {/* <input
                                            onChange={e => {
                                                if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes(".") == true) { }else if (e.target.value.includes("-") == true) { } else {
                                                if (e.target.value == "") { setDuration(0); }else  if (e.target.value >100) { setDuration(100); } else if (e.target.value == 0) { setDuration(0); } else {
                                                        if (e.target.value.indexOf(101) == '0' && e.target.value.length > 1) {
                                                            let va = e.target.value.substring(1)
                                                            setDuration(va)
                                                        } else if (duration == 0 && e.target.value?.split("")[1] == '0') {
                                                            let va = e.target.value?.split("")[0];
                                                            setDuration(va)
                                                        } else {
                                                            setDuration(e.target.value)
                                                        }
                                                    }
                                                }

                                            }} value={duration} className='shadow-none form-control sm-bgr absright' style={{ color: durationFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${durationFlag === true ? "fname1" : "fname"}`} name="fname" />
                                        <label style={{ background: durationFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: durationFlag === true ? "#E05776" : '', marginLeft: 2 }} className='shadow-none form-control sm-fr wdthcls' htmlFor={`${durationFlag === true ? "fname1" : "fname"}`} id={`${durationFlag === true ? "fname1" : "fname"}`}>Yr</label> */}
                                            <input
                                                onChange={e => {
                                                    if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                                                        if (duration != "0" && e.target.value > 100) { setDuration(100) } else if (e.target.value == "") { setDuration(0); } else if (e.target.value == 0) { setDuration(0); } else {
                                                            if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                                                                let va = e.target.value.substring(1)
                                                                setDuration(va)
                                                            } else if (duration == 0 && e.target.value?.split("")[1] == '0') {
                                                                let va = e.target.value?.split("")[0];
                                                                setDuration(va)
                                                            } else {
                                                                setDuration(e.target.value)
                                                            }
                                                        }
                                                    }

                                                }} value={duration} className='shadow-none form-control sm-bgr absright' style={{ color: durationFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${durationFlag === true ? "fname1" : "fname"}`} name="fname" />
                                            <label style={{ background: durationFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: durationFlag === true ? "#E05776" : '', marginLeft: 2 }} className='shadow-none form-control sm-fr wdthcls' htmlFor={`${durationFlag === true ? "fname1" : "fname"}`} id={`${durationFlag === true ? "fname1" : "fname"}`}>Yr</label>
                                        </div>
                                    </div>
                                    <Slider
                                        min={1}
                                        max={100}
                                        value={duration}
                                        onChange={(value) => {
                                            setDuration(value)
                                        }}
                                        onChangeComplete={handleChangeComplete}

                                    />
                                </div>
                                <div className='d-flex justify-content-between pt-5 mt-3 pb-4 '>
                                    <div className='sip-count-text mt-4'><b>Future Value of Investment</b></div>
                                    <div className="mt-4"><b>₹ {futureval}</b></div>
                                </div>
                                <div className='d-flex justify-content-between pt-1 pb-4 mb-1 '>
                                    <div className='sip-count-text'><b>Present Value of Investment</b></div>
                                    <div><b>₹ {presentVal}</b></div>

                                </div>
                                <div className='d-flex justify-content-between pt-1 pb-4 mb-1'>
                                    <div className='sip-count-text'><b>Total Periodic Deposits</b></div>
                                    <div><b>₹ {periodicDep} </b></div>
                                </div>
                                <div className='d-flex justify-content-between pt-1 mb-4 '>
                                    <div className='sip-count-text'><b>Total Interest</b></div>
                                    <div><b>₹ {totalInt} </b></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
                            <div className='background p-4 text-center'>
                                <div className="pt-4"></div>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex justify-content-center">
                                        <div className="circle-blue"></div>
                                        <div className="circle-text">Total Interest</div>
                                    </div>
                                    <div className="d-flex justify-content-center mx-4 pb-3">
                                        <div className="circle-pink"></div>
                                        <div className="circle-text">Total Periodic Investment</div>
                                    </div>
                                </div>
                                {<Example />}
                                <div className="pb-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

