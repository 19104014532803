import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tooltipimg from '../../../assets/images/tooltip-image.svg'
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Tabs from '../../components/tabs/tabs';

export default function Retirement() {
    let nf = new Intl.NumberFormat('en-IN');
    const [currentAgeTerm, setCurrentAge] = useState(0)
    const [retirementAgeTerm, setretirementAge] = useState(0)
    const [lifeExpectancyTerm, setlifeExpectancy] = useState(0)
    const [currentAnnualExpenseTerm, setcurrentAnnualExpense] = useState(0)
    const [inflationTerm, setinflation] = useState(0)
    const [preRetirementRorTerm, setpreRetirementRor] = useState(0)
    const [returnsOnRetirementCorpusTerm, setreturnsOnRetirementCorpus] = useState(0)
    const [annualIncreaseInYearlyIncomeTerm, setannualIncreaseInYearlyIncome] = useState(0)
    const [existingInvestmentTerm, setexistingInvestment] = useState(0)
    const [rateOfExistingInvestmentTerm, setrateOfExistingInvestment] = useState(0)


    const [retirementcorpusrequired, setRetirementcorpusrequired] = useState(0)
    const [lumpsumFunding, setLumpsumFunding] = useState(0)
    const [monthlySIPrequired, setMonthlySIPrequired] = useState(0)
    const [stepupSIPrequired, setStepupSIPrequired] = useState(0)
    const [errMsg, setErrMsg] = useState("");
    const calculateTerm = () => {
        if (currentAgeTerm <= 0) { setErrMsg("Current age should be greater than 0 *"); }
        else if (retirementAgeTerm <= 0) { setErrMsg("Retirement age should be greater than 0 *"); }
        else if (lifeExpectancyTerm < parseInt(currentAgeTerm) || lifeExpectancyTerm < parseInt(retirementAgeTerm)) {
            if (lifeExpectancyTerm < parseInt(currentAgeTerm)) {
                setErrMsg("Life Expectancy should be greater Current age  *")
            }
            else if (lifeExpectancyTerm < parseInt(retirementAgeTerm)) {
                setErrMsg("Life Expectancy should be greater Retirement age  *")
            }
        }
        else if (currentAnnualExpenseTerm <= 0) { setErrMsg("Please enter Current Annual Expense *"); }
        else if (inflationTerm <= 0) { setErrMsg("Inflation Rate should be greater than 0 *"); }
        else if (preRetirementRorTerm <= 0) { setErrMsg("Expected Pre-retirement RoR should be greater than 0 *") }
        else if (returnsOnRetirementCorpusTerm <= 0) { setErrMsg("Expected Post-retirement RoR should be greater than 0 *") }
        else if (annualIncreaseInYearlyIncomeTerm <= 0) { setErrMsg("Expected annual Increase in Income should be greater than 0 *") }
        else if (rateOfExistingInvestmentTerm <= 0) { setErrMsg("Expected RoR of Existing Investment should be greater than 0 *") }
        else {
            setErrMsg("")
            const currentAge = parseInt(currentAgeTerm); //Current Age (Years)
            const retirementAge = parseInt(retirementAgeTerm); //Retirement Age (Years)
            const lifeExpectancy = parseInt(lifeExpectancyTerm); //Life Expectancy
            const currentAnnualExpense = parseInt(currentAnnualExpenseTerm); //Current Annual Expense (₹)
            const inflation = inflationTerm; //Inflation (%)
            const preRetirementRor = preRetirementRorTerm; //Expected Pre-retirement RoR (%)
            const returnsOnRetirementCorpus = returnsOnRetirementCorpusTerm; //Post-retirement
            const annualIncreaseInYearlyIncome = annualIncreaseInYearlyIncomeTerm; //Expected annual Increase in Income (%)
            const existingInvestment = parseInt(existingInvestmentTerm); //Existing Investments
            const rateOfExistingInvestment = rateOfExistingInvestmentTerm; //Expected RoR of Existing Investment (%)
            let retirementYears = 0;
            retirementYears = lifeExpectancy - retirementAge;
            // console.log("Retirement Years:",retirementYears);
            let numberOfYearsLeftForRetirement = 0;
            numberOfYearsLeftForRetirement = retirementAge - currentAge;

            // console.log("No Of Years Left For Retirement:",numberOfYearsLeftForRetirement);
            var FV_OfExistingInvestment = getFutureValue(existingInvestment, rateOfExistingInvestment, numberOfYearsLeftForRetirement)
            // console.log("Future Value:",FV_OfExistingInvestment);
            var expenseInFirstYearOfRetirement = getFutureValue(currentAnnualExpense, inflation, numberOfYearsLeftForRetirement + 1)
            // console.log("Expense In first Year Of retirement:",expenseInFirstYearOfRetirement);
            var expectedInflationAfterRetirement = inflation;
            var netReturn = ((1 + parseFloat(returnsOnRetirementCorpus / 100)) / (1 + parseFloat(expectedInflationAfterRetirement / 100))) - 1
            // console.log("Net Return:",netReturn);
            var preRetirementReturns = (Math.pow(1.0 + (preRetirementRor / 100), (1.0 / 12))) - 1;
            // console.log("Pre Retiremnet Returns:",preRetirementReturns);
            let retirementCorpus = 0;
            let corpus1 = 0;
            retirementCorpus = presentValue(netReturn, retirementYears, -expenseInFirstYearOfRetirement, 0, true);
            corpus1 = retirementCorpus;
            if (existingInvestment != "0") {
                // console.log("divya");
                corpus1 = presentValue(netReturn, retirementYears, -expenseInFirstYearOfRetirement, 0, true) - FV_OfExistingInvestment;
                console.log("Retiremnet Corpus:", Math.ceil(retirementCorpus));
                setRetirementcorpusrequired(Math.ceil(retirementCorpus))
                var lumpsum = calculateLumpsum(corpus1, preRetirementRor, numberOfYearsLeftForRetirement)
                if (lumpsum < 0) {
                    lumpsum = "0";
                }
                console.log("Lumpsum:", Math.ceil(lumpsum));
                setLumpsumFunding(Math.ceil(lumpsum))
                var monthlySip = getMonthlySIP(preRetirementReturns, numberOfYearsLeftForRetirement * 12, 0.0, -corpus1, false);
                if (monthlySip < 0) {
                    monthlySip = "0";
                }
                console.log("Monthly SIP:", parseInt(monthlySip));
                setMonthlySIPrequired(parseInt(monthlySip))
                var monthlyGrowth = (Math.pow(1.0 + (annualIncreaseInYearlyIncome / 100), (1.0 / 12))) - 1;
                // console.log("Monthly Growth:",monthlyGrowth);
                var stepUpSIP = getStepUpSIP(preRetirementReturns, corpus1, monthlyGrowth, numberOfYearsLeftForRetirement)
                if (stepUpSIP < 0) {
                    stepUpSIP = "0";
                }
                console.log("Step Up SIP:", Math.ceil(stepUpSIP));
                setStepupSIPrequired(Math.ceil(stepUpSIP))
            } else {
                console.log("Retiremnet Corpus:", Math.ceil(retirementCorpus));
                setRetirementcorpusrequired(Math.ceil(retirementCorpus))
                var lumpsum = calculateLumpsum(retirementCorpus, preRetirementRor, numberOfYearsLeftForRetirement)
                console.log("Lumpsum:", Math.ceil(lumpsum));
                setLumpsumFunding(Math.ceil(lumpsum))
                var monthlySip = getMonthlySIP(preRetirementReturns, numberOfYearsLeftForRetirement * 12, 0.0, -retirementCorpus, false);
                if (monthlySip < 0) {
                    monthlySip = "0";
                }
                console.log("Monthly SIP:", parseInt(monthlySip));
                setMonthlySIPrequired(parseInt(monthlySip))
                var monthlyGrowth = (Math.pow(1.0 + (annualIncreaseInYearlyIncome / 100), (1.0 / 12))) - 1;
                // console.log("Monthly Growth:",monthlyGrowth);
                var stepUpSIP = getStepUpSIP(preRetirementReturns, retirementCorpus, monthlyGrowth, numberOfYearsLeftForRetirement)
                if (stepUpSIP < 0) {
                    stepUpSIP = "0";
                }
                console.log("Step Up SIP:", Math.ceil(stepUpSIP));
                setStepupSIPrequired(Math.ceil(stepUpSIP))
            }
            function getFutureValue(presentCost, inflation, availableYears) {
                var futureCost = presentCost * Math.pow(1 + (inflation / 100), availableYears);
                return futureCost;
            }
            function presentValue(rate, numberOfPayments, pv, fv, type) {
                // debugger
                var retval = 0;
                if (rate == 0) {
                    retval = -1 * ((numberOfPayments * pv) + fv);
                }
                else {
                    var rate1 = rate + 1;
                    retval = (((1 - Math.pow(rate1, numberOfPayments)) / rate) * (type ? rate1 : 1) * pv - fv) / (Math.pow(rate1, numberOfPayments));
                }
                return parseInt(retval);
            }
            function calculateLumpsum(fv, ROIInvestment, availableYears) {
                var OneMonthROI = ROIInvestment / 100
                var lumpsum = fv / Math.pow(1 + OneMonthROI, availableYears)
                return parseInt(lumpsum);
            }
            function getMonthlySIP(rate, months, pv, fv, type) {
                // debugger
                var retval = 0;
                if (rate == 0) {
                    retval = -1 * (fv + pv) / months;
                }
                else {
                    var rate1 = rate + 1;
                    retval = (fv + pv * Math.pow(rate1, months)) * rate
                        /
                        ((type ? rate1 : 1) * (1 - Math.pow(rate1, months)));
                }
                return (retval == "-Infinity" || retval == "Infinity") ? 0 : parseInt(retval);
            }
            function getStepUpSIP(rateOfStepUpSIP, actualAmountRequired, rateOfMonthlySIP, remaingYears) {
                // debugger
                var getStep = 0
                getStep = (actualAmountRequired * (rateOfMonthlySIP - rateOfStepUpSIP)
                    / (Math.pow((1 + rateOfMonthlySIP), (remaingYears * 12)) - Math.pow((1 + rateOfStepUpSIP), (remaingYears * 12))));

                return (getStep == "Infinity" || getStep == "-Infinity") ? 0 : parseInt(getStep)
            }
        }
    }

    const navigate = useNavigate()
    return (
        <div>
            <Tabs />
            <div className='custome-row' id="retirment-tool-id">
                <div className="container-fluid">
                    <div class="path-text mt-3">
                        <span style={{ cursor: 'pointer' }} onClick={() => {
                            navigate("/")
                        }}>Calculator</span> &gt;<span style={{color:"#0073bb"}}> Retirement Calculator</span>
                    </div>
                    {/* <div class="mt-4 mb-3 fd-cal-text">Retirement Calculator</div> */}
                    <div className='row'>
                        <div className='col-lg-8 col-12'>
                            <div className='background'>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                Basic Info
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Current Age  (Years)<sup style={{ color: "red" }}>*</sup> <img src={tooltipimg} className='info-icons' data-tooltip-id="Age1" />
                                                            </label>
                                                            <ReactTooltip id="Age1" place="right" content={`Age as of today. `} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control shadow-none"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={currentAgeTerm}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 100) {
                                                                            setCurrentAge(100);
                                                                        } else if (e.target.value == "") {
                                                                            setCurrentAge(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setCurrentAge(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setCurrentAge(va);
                                                                            } else if (
                                                                                currentAgeTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setCurrentAge(va);
                                                                            } else {
                                                                                setCurrentAge(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Retirement Age  (Years)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="ret_age" />
                                                            </label>
                                                            <ReactTooltip id="ret_age" place="right" content={`Expected age by when you are planning to Retire. `} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control shadow-none"
                                                                id="father_name"
                                                                value={retirementAgeTerm}
                                                                aria-describedby="emailHelp"

                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 100) {
                                                                            setretirementAge(100);
                                                                        } else if (e.target.value == "") {
                                                                            setretirementAge(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setretirementAge(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setretirementAge(va);
                                                                            } else if (
                                                                                retirementAgeTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setretirementAge(va);
                                                                            } else {
                                                                                setretirementAge(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Life Expectancy<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="life_exp" />
                                                            </label>
                                                            <ReactTooltip id="life_exp" place="right" content={`Age you are expected to live. Suggestion - 90 years to 100 years `} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={lifeExpectancyTerm}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 120) {
                                                                            setlifeExpectancy(120);
                                                                        } else if (e.target.value == "") {
                                                                            setlifeExpectancy(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setlifeExpectancy(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setlifeExpectancy(va);
                                                                            } else if (
                                                                                lifeExpectancyTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setlifeExpectancy(va);
                                                                            } else {
                                                                                setlifeExpectancy(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Current Annual Expense  (₹)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="curr_income" />
                                                            </label>
                                                            <ReactTooltip id="curr_income" place="right" content={`If you retire today, what will be your Annual Expense? It is all today's expense - Children expense, EMIs and Life Insurance Premiums `} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={currentAnnualExpenseTerm}

                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 9999999999999) {
                                                                            setcurrentAnnualExpense(9999999999999);
                                                                        } else if (e.target.value == "") {
                                                                            setcurrentAnnualExpense(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setcurrentAnnualExpense(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setcurrentAnnualExpense(va);
                                                                            } else if (
                                                                                currentAnnualExpenseTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setcurrentAnnualExpense(va);
                                                                            } else {
                                                                                setcurrentAnnualExpense(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="accordion-item mt-5">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Return Expectation
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Inflation  (%)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="inflation" />
                                                            </label>
                                                            <ReactTooltip id="inflation" place="right" content={`Expected Inflation of your expenses. Suggestion - 7% `} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control shadow-none"
                                                                id="father_name"
                                                                value={inflationTerm}
                                                                aria-describedby="emailHelp"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes(".") == true && e.target.value?.split(".")[1]?.length > 2) { } else if (e.target.value.includes("-") == true) {
                                                                    } else {
                                                                        if (e.target.value > 98669) {
                                                                            setinflation(98669);
                                                                        } else if (e.target.value == "") {
                                                                            setinflation(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setinflation(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setinflation(va);
                                                                            } else if (
                                                                                inflationTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setinflation(va);
                                                                            } else {
                                                                                setinflation(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Expected Pre-retirement RoR  (%)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="pre_ror" />
                                                            </label>
                                                            <ReactTooltip id="pre_ror" place="right" content={`Expected Rate of Return on Planned Investments before Retirement Age.`} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                value={preRetirementRorTerm}
                                                                aria-describedby="emailHelp"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes(".") == true && e.target.value?.split(".")[1]?.length > 2) { } else if (e.target.value.includes("-") == true) {
                                                                    } else {
                                                                        if (e.target.value > 99999) {
                                                                            setpreRetirementRor(99999);
                                                                        } else if (e.target.value == "") {
                                                                            setpreRetirementRor(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setpreRetirementRor(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setpreRetirementRor(va);
                                                                            } else if (
                                                                                preRetirementRorTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setpreRetirementRor(va);
                                                                            } else {
                                                                                setpreRetirementRor(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Expected Post-retirement RoR  (%)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="post_ror" />
                                                            </label>
                                                            <ReactTooltip id="post_ror" place="right" content={`Expected Rate of Return on Retirement Corpus Post Retirement. Suggestion - Not more than 10%`} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={returnsOnRetirementCorpusTerm}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes(".") == true && e.target.value?.split(".")[1]?.length > 2) { } else if (e.target.value.includes("-") == true) {
                                                                    } else {
                                                                        if (e.target.value > 99999) {
                                                                            setreturnsOnRetirementCorpus(99999);
                                                                        } else if (e.target.value == "") {
                                                                            setreturnsOnRetirementCorpus(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setreturnsOnRetirementCorpus(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setreturnsOnRetirementCorpus(va);
                                                                            } else if (
                                                                                returnsOnRetirementCorpusTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setreturnsOnRetirementCorpus(va);
                                                                            } else {
                                                                                setreturnsOnRetirementCorpus(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="accordion-item mt-5">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Other
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className='row'>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Expected annual Increase in Income  (%)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="annual_inc" />
                                                            </label>
                                                            <ReactTooltip id="annual_inc" place="right" content={`Expected Yearly Increase in Income till Retirement. Suggestion - Not more than 10%`} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={annualIncreaseInYearlyIncomeTerm}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes(".") == true && e.target.value?.split(".")[1]?.length > 2) { } else if (e.target.value.includes("-") == true) {
                                                                    } else {
                                                                        if (e.target.value > 99999) {
                                                                            setannualIncreaseInYearlyIncome(99999);
                                                                        } else if (e.target.value == "") {
                                                                            setannualIncreaseInYearlyIncome(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setannualIncreaseInYearlyIncome(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setannualIncreaseInYearlyIncome(va);
                                                                            } else if (
                                                                                returnsOnRetirementCorpusTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setannualIncreaseInYearlyIncome(va);
                                                                            } else {
                                                                                setannualIncreaseInYearlyIncome(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Existing Investments  (₹) <img src={tooltipimg} className='info-icons' data-tooltip-id="exist_inv" />
                                                            </label>
                                                            <ReactTooltip id="exist_inv" place="right" content={`Sum of all Existing Investments which can be tagged to Retirement Goal`} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={existingInvestmentTerm}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 9999999999999) {
                                                                            setexistingInvestment(9999999999999);
                                                                        } else if (e.target.value == "") {
                                                                            setexistingInvestment(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setexistingInvestment(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setexistingInvestment(va);
                                                                            } else if (
                                                                                returnsOnRetirementCorpusTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setexistingInvestment(va);
                                                                            } else {
                                                                                setexistingInvestment(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12'>
                                                        <div className="pt-3">
                                                            <label htmlFor="" className="form-label">
                                                                Expected RoR of Existing Investment  (%)<sup style={{ color: "red" }}>*</sup><img src={tooltipimg} className='info-icons' data-tooltip-id="exp_ror" />
                                                            </label>
                                                            <ReactTooltip id="exp_ror" place="right" content={`Expected Rate of Return on Tagged Existing Investments till Retirement Age`} effect="solid" style={{ width: 200 }} />
                                                            <input
                                                                type="name"
                                                                className="form-control"
                                                                id="father_name"
                                                                aria-describedby="emailHelp"
                                                                value={rateOfExistingInvestmentTerm}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes(".") == true && e.target.value?.split(".")[1]?.length > 2) { } else if (e.target.value.includes("-") == true) {
                                                                    } else {
                                                                        if (e.target.value > 99999) {
                                                                            setrateOfExistingInvestment(99999);
                                                                        } else if (e.target.value == "") {
                                                                            setrateOfExistingInvestment(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setrateOfExistingInvestment(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setrateOfExistingInvestment(va);
                                                                            } else if (
                                                                                returnsOnRetirementCorpusTerm == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setrateOfExistingInvestment(va);
                                                                            } else {
                                                                                setrateOfExistingInvestment(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='m-0' />
                                        <div className='container-fluid mt-3'>
                                            <div className='pb-2'>
                                                <span className='err-msg'>{errMsg ? errMsg : ""}</span>
                                            </div>
                                            <button className='btn btn-primary' onClick={calculateTerm}>Calculate</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 mb-5'>
                            <div className='background p-3 mb-5'>
                                <div className="row pt-4">
                                    <div className="col-md-6 col-12">
                                        <div>Retirement corpus required <img src={tooltipimg} className='info-icons' data-tooltip-id="corpus" /> </div>
                                    </div>
                                    <ReactTooltip id="corpus" place="top" content={`Retirement corpus is the amount you save and invest  
                            during your working years to enjoy a
                             worry-free retirement from retirement age to life expectancy.`} effect="solid" style={{ width: 200 }} />
                                    <div className="col-md-6 col-12">
                                        <div className="bg-blue py-1">₹ {nf.format(retirementcorpusrequired)}</div>
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-md-6 col-12">
                                        <div>Lumpsum Funding <img src={tooltipimg} className='info-icons' data-tooltip-id="lumpsum" /></div>
                                    </div>
                                    <ReactTooltip id="lumpsum" place="top" content={`Lump sum funding is like making a big deposit at once, into your retirement savings for a stronger financial foundation.`} effect="solid" style={{ width: 200 }} />
                                    <div className="col-md-6 col-12">
                                        <div className="bg-blue py-1">₹ {nf.format(lumpsumFunding)}</div>
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-md-6 col-12">
                                        <div>Monthly SIP required  <img src={tooltipimg} className='info-icons' data-tooltip-id="sip" /></div>
                                    </div>
                                    <ReactTooltip id="sip" place="top" content={` It is an amount you invest  every month for your retirement, creating a consistent and manageable investment plan.`} effect="solid" style={{ width: 200 }} />
                                    <div className="col-md-6 col-12">
                                        <div className="bg-blue py-1">₹ {nf.format(monthlySIPrequired)}</div>
                                    </div>
                                </div>
                                <div className="row pt-4">
                                    <div className="col-md-6 col-12">
                                        <div>Step up SIP required  <img src={tooltipimg} className='info-icons' data-tooltip-id="stepup" /></div>
                                    </div>
                                    <ReactTooltip id="stepup" place="top" content={` Increase your monthly investment gradually, adjusting, for example, by the rate you set annually, to adapt to a growing capacity and reach your retirement goals effectively.`} effect="solid" style={{ width: 200 }} />
                                    <div className="col-md-6 col-12">
                                        <div className="bg-blue py-1">₹ {nf.format(stepupSIPrequired)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4" style={{ paddingLeft: 12, paddingRight: 20 }}>
                            <div className="col-lg-8 col-12 background" style={{ marginLeft: 10 }}>
                                <div >
                                    <div style={{ marginLeft: 10, marginTop: 10 }}>

                                        <strong>FAQs</strong>
                                    </div>
                                </div>
                                <div class="accordion mt-0 mb-5" id="RETIRaccordionExample1">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="RETIRheadingOne">
                                            <button
                                                class="accordion-button collapsed px-1 shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#RETIRcollapseOne"
                                                aria-expanded="true"
                                                aria-controls="RETIRcollapseOne"
                                            >
                                                &nbsp;&nbsp;Why do you need to plan your retirement?
                                            </button>
                                        </h2>
                                        <div
                                            id="RETIRcollapseOne"
                                            class="accordion-collapse collapse"
                                            aria-labelledby="RETIRheadingOne"
                                            data-bs-parent="#RETIRaccordionExample1"
                                        >
                                            <div class="accordion-body px-1">
                                                <>
                                                    <p id="isPasted">
                                                        Saving for retirement is crucial because it helps ensure financial security when you no longer have a
                                                        regular income. It provides a source of funds to cover living expenses, healthcare, and other needs
                                                        during your retirement years, allowing you to maintain a comfortable lifestyle without relying solely
                                                        on social security or other support systems. Starting early and consistently saving over time allows
                                                        your investments to grow, providing a nest egg for your future.
                                                    </p>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item pb-md-0" id="RETIRaccordionExample1">
                                        <h2 class="accordion-header" id="RETIRheadingTwo">
                                            <button
                                                class="accordion-button collapsed px-1 shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#RETIRcollapseTwo"
                                                aria-expanded="false"
                                                aria-controls="RETIRcollapseTwo"
                                            >
                                                &nbsp; Products that help you plan your retirement.
                                            </button>
                                        </h2>
                                        <div
                                            id="RETIRcollapseTwo"
                                            class="accordion-collapse collapse"
                                            aria-labelledby="RETIRheadingTwo"
                                            data-bs-parent="#RETIRaccordionExample1"
                                        >
                                            <div class="accordion-body px-1">
                                                <p style={{ textAlign: "left" }} id="isPasted">
                                                    Insurance plays a valuable role in Retirement planning by providing financial security and support for
                                                    your financial needs. Here's how insurance can help in this regard:<br /><br />
                                                    <strong>1. Annuities:</strong><br />
                                                    <span style={{ marginLeft: 0 }}> ● Provide a steady stream of income during retirement.
                                                    </span><br />
                                                    <span style={{ marginLeft: 0 }}> ● Types include fixed, variable, and indexed annuities.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Offer options for immediate or deferred payouts.</span>
                                                    <br /><br />
                                                    <strong>2. Long-Term Care Insurance:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Covers costs associated with extended healthcare needs in retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Helps with expenses like nursing homes, assisted living, or in-home care.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Mitigates the impact of healthcare expenses on retirement savings.</span><br /><br />
                                                    <strong>3. Life Insurance with Cash Value:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Some permanent life insurance policies accrue cash value over time.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Policyholders can access this cash value during retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Combines life coverage with a potential savings component.</span><br /><br />
                                                    <strong>4. Indexed Universal Life Insurance:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Ties cash value growth to the performance of a stock market index.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Offers potential for higher returns compared to traditional whole life policies.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Provides flexibility in premium payments and death benefits.</span><br /><br />
                                                    <strong>5. Income Protection Insurance:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Safeguards against the loss of income due to disability or critical illness.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Can be essential for protecting retirement savings in case of unexpected health
                                                        challenges.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Ensures financial stability during periods of reduced or no income.
                                                        When considering insurance products for retirement planning, it’s crucial to assess individual needs,
                                                        risk tolerance, and long-term financial goals. Consulting with a financial advisor can help tailor these
                                                        products to your specific circumstances.</span><br /><br />

                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item" id="RETIRaccordionExample1">
                                        <h2 class="accordion-header" >
                                            <button
                                                class="accordion-button collapsed px-1 shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#RETIRheadingFour"
                                                aria-expanded="false"
                                                aria-controls="RETIRheadingFour"
                                            >
                                                &nbsp; How to use Retirement Calculator?
                                            </button>
                                        </h2>
                                        <div
                                            id="RETIRheadingFour"
                                            class="accordion-collapse collapse"
                                            aria-labelledby="RETIRheadingFour"
                                            data-bs-parent="#RETIRaccordionExample1"
                                        >
                                            <div class="accordion-body px-1">
                                                <p style={{ textAlign: "left" }} id="isPasted">
                                                    The Retirement Calculator is a powerful tool designed to help you plan for a financially
                                                    secure retirement. Follow these steps to make the most out of it:<br /><br />
                                                    <strong>Step 1: Basic Information</strong><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Current Age (Years):</strong> Input your current age. This forms the starting point for
                                                        planning your retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Retirement Age (Years):</strong> Specify the age at which you plan to retire. This
                                                        influences the duration of your retirement savings.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Life Expectancy:</strong> Enter your expected life expectancy. This ensures your savings
                                                        last throughout your retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Current Annual Expense (₹):</strong> Provide an estimate of your current yearly expenses
                                                        to gauge your lifestyle.</span><br /><br />
                                                    <strong>Step 2: Return Expectations</strong><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Inflation (%):</strong> Indicate the expected inflation rate. Inflation erodes purchasing
                                                        power, affecting future expenses.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected Pre-retirement RoR (%):</strong> Enter the expected rate of return on investments
                                                        before retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected Post-retirement RoR (%):</strong> Specify the anticipated rate of return on
                                                        investments during retirement.</span><br /><br />
                                                    <strong>Step 3: Other Factors</strong><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected Annual Increase in Income (%):</strong> If applicable, input the expected annual
                                                        increase in your income.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Existing Investments (₹):</strong> Include the current value of your existing investments.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected RoR of Existing Investment (%):</strong> Specify the anticipated rate of return on
                                                        your existing investments.</span><br /><br />
                                                    <strong>Step 4: Click on the Calculate Button</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● After filling in all the details, click on the 'Calculate' button to obtain
                                                        comprehensive results.</span><br /><br />
                                                    <strong>Factors Responsible for Results:-</strong><br />
                                                    <strong>Retirement Corpus Required:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Influenced by current age, retirement age, life expectancy, and current annual expenses.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Reflects the estimated amount needed to maintain your lifestyle during retirement.</span><br />
                                                    <br />
                                                    <strong>Lumpsum Funding:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Takes into account inflation, pre-retirement rate of return, and
                                                        post-retirement rate of return.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Represents the one-time amount needed at the beginning of retirement.</span><br /><br />
                                                    <strong>Monthly SIP Required:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Considers expected annual increase in income, existing investments, and
                                                        their expected rate of return.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Suggests the monthly systematic investment required to meet retirement
                                                        goals.</span><br /><br />
                                                    <strong>Step up SIP Required:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Similar to Monthly SIP but factors in an increase in investment over time
                                                        to align with changing financial circumstances.</span><br /><br />
                                                    <strong>How Factors Impact Results:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Age influences the time available for savings to grow.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Inflation affects future expenses, requiring higher savings.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Rate of return impacts the growth of investments.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Existing investments contribute to the overall retirement portfolio.</span><br /><br/>
                                                    By understanding these factors and customizing the calculator inputs based on your
                                                    unique situation, you can make informed decisions to secure a financially sound
                                                    retirement
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="accordion-item" id="RETIRaccordionExample1">
                                        <h2 class="accordion-header" >
                                            <button
                                                class="accordion-button collapsed px-1 shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#RETIRheadingFour1"
                                                aria-expanded="false"
                                                aria-controls="RETIRheadingFour1"
                                            >
                                                &nbsp; How Factors Impact Results
                                            </button>
                                        </h2>
                                        <div
                                            id="RETIRheadingFour1"
                                            class="accordion-collapse collapse"
                                            aria-labelledby="RETIRheadingFour1"
                                            data-bs-parent="#RETIRaccordionExample1"
                                        >
                                            <div class="accordion-body px-1">
                                                <p style={{ textAlign: "left" }} id="isPasted">
                                                    The Retirement Calculator is a powerful tool designed to help you plan for a financially
                                                    secure retirement. Follow these steps to make the most out of it:<br /><br />
                                                    <strong>Step 1: Basic Information</strong><br />
                                                    <span style={{ marginLeft: 0 }}><strong></strong>● Current Age (Years): Input your current age. This forms the starting point for
                                                        planning your retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Retirement Age (Years):</strong> Specify the age at which you plan to retire. This
                                                        influences the duration of your<br /> retirement savings.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Life Expectancy:</strong> Enter your expected life expectancy. This ensures your savings
                                                        last throughout your<br /> retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Current Annual Expense (₹):</strong> Provide an estimate of your current yearly expenses
                                                        to gauge your lifestyle.</span><br /><br />
                                                    <strong>Step 2: Return Expectations</strong><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Inflation (%):</strong> Indicate the expected inflation rate. Inflation erodes purchasing
                                                        power, affecting future <br />expenses.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected Pre-retirement RoR (%):</strong> Enter the expected rate of return on investments
                                                        before retirement.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected Post-retirement RoR (%):</strong> Specify the anticipated rate of return on
                                                        investments during retirement.</span><br /><br />
                                                    <strong>Step 3: Other Factors</strong><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected Annual Increase in Income (%):</strong> If applicable, input the expected annual
                                                        increase in your income.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Existing Investments (₹):</strong> Include the current value of your existing investments.</span><br />
                                                    <span style={{ marginLeft: 0 }}><strong>● Expected RoR of Existing Investment (%):</strong> Specify the anticipated rate of return on
                                                        your existing <br />investments.</span><br /><br />
                                                    <strong>Step 4: Click on the Calculate Button</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● After filling in all the details, click on the 'Calculate' button to obtain
                                                        comprehensive results.</span><br /><br />
                                                    <strong>Factors Responsible for Results:-</strong><br />
                                                    <strong>Retirement Corpus Required:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Influenced by current age, retirement age, life expectancy, and current annual expenses.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Reflects the estimated amount needed to maintain your lifestyle during retirement.</span><br />
                                                    <br />
                                                    <strong>Lumpsum Funding:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Takes into account inflation, pre-retirement rate of return, and
                                                        post-retirement rate of return.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Represents the one-time amount needed at the beginning of retirement.</span><br /><br />
                                                    <strong>Monthly SIP Required:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Considers expected annual increase in income, existing investments, and
                                                        their expected rate of return.</span><br />
                                                    <span style={{ marginLeft: 0 }}>● Suggests the monthly systematic investment required to meet retirement
                                                        goals.</span><br /><br />
                                                    <strong>Step up SIP Required:</strong><br />
                                                    <span style={{ marginLeft: 0 }}>● Similar to Monthly SIP but factors in an increase in investment over time
                                                        to align with changing financial<br/> circumstances.</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-5 col-12 ">
                            </div>
                            <div className="pb-5"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

