
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../src/views/components/header/header'
import Tabs from '../src/views/components/tabs/tabs'
import Home from './views/pages/home-calculator/home';
import FDCalculator from './views/pages/FD/fdcalculator';
import Term from './views/pages/term/term';
import ChildEducation from './views/pages/child-education/child-education';
import RDCalculator from './views/pages/RD/rdcalculator';
import SPCalculator from './views/pages/SIP/sipcalculator';
import Retirement from './views/pages/retirement/retirement';
import PPFCalculator from './views/pages/PPF/ppf';
import IncomeTax from './views/pages/income-tax/income-tax';
import CAGRCalculator from './views/pages/CAGR/Cagr';
import MutualFundCal from './views/pages/MutualFund/MutualFund';
import FutureValueCalculator from './views/pages/FutreValuePresentMoney/FutreValuePresentMoney';
import PresentValueFutureMoney from './views/pages/PresentValueFutureMoney/PresentValueFutureMoney';
import Health from './views/pages/Health/Health';
import { Toaster } from 'react-hot-toast';
import Calculated from './views/pages/Health/Calculated';
import CalculatorSpeech from './views/pages/Health/Calculator-speech';

function App() {
  return (
    <>
   
      
      {/* <Header /> */}
      <Routes>
      {/* <Tabs/> */}
        <Route exact path='/' element={< Home />}></Route>
        <Route exact path='/fd-calulator' element={< FDCalculator />}></Route>
        <Route exact path='/term-calulator' element={< Term />}></Route>
        <Route exact path='/child-calulator' element={< ChildEducation />}></Route>
        <Route exact path='/rd-calulator' element={< RDCalculator />}></Route>
        <Route exact path='/sip-calulator' element={< SPCalculator />}></Route>
        <Route exact path='/retirment-calulator' element={< Retirement />}></Route>
        <Route exact path='/ppf-calulator' element={< PPFCalculator />}></Route>
        <Route exact path='/incometax-calulator' element={< IncomeTax />}></Route>
        <Route exact path='/cagr-calulator' element={< CAGRCalculator />}></Route>
        <Route exact path='/mutual-calulator' element={< MutualFundCal />}></Route>
        <Route exact path='/future-calulator' element={< FutureValueCalculator />}></Route>
        <Route exact path='/present-calulator' element={< PresentValueFutureMoney />}></Route>
        <Route exact path='/medical-calulator' element={< Health />}></Route>
        <Route exact path='/medical-calculated' element={< Calculated />}></Route>
        <Route exact path='/medical-speech' element={< CalculatorSpeech />}></Route>
      </Routes>
    </>
  );
}

export default App;
