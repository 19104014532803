import React, { useEffect, useState } from "react";
import "./tabs.css";
import FD from "../../../assets/images/fixed-deposit.png";
import Term from "../../../assets/images/term-insurance.png";
import Recurring from "../../../assets/images/recurring.png";
import Invest from "../../../assets/images/investment-cal.png";
import PPF from "../../../assets/images/ppf.png";
import SIP from "../../../assets/images/sip-cal.png";
import Ulip from "../../../assets/images/ulip-cal.png";
import CagrCal from "../../../assets/images/compound.png";
import Mutualimg from "../../../assets/images/mutual.png";
import FVPMimg from "../../../assets/images/future.png";
import PMFVimg from "../../../assets/images/current-account.png";
import health from "../../../assets/images/health.png";

import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import PPFCaculator from "../../pages/PPF/ppf";
import SPCalculator from "../../pages/SIP/sipcalculator";
import { useLocation, useNavigate } from "react-router-dom";
import IncomeImg from "../../../assets/images//income-tax.png";
import RDCalculator from "../../pages/RD/rdcalculator";
import FDCalculator from "../../pages/FD/fdcalculator";
import IncomeTax from "../../pages/income-tax/income-tax";
import ChildEducation from "../../pages/child-education/child-education";
import TermCal from "../../pages/term/term";
import Retirement from "../../pages/retirement/retirement";
import CAGR from "../../pages/CAGR/Cagr";
import MutualFund from "../../pages/MutualFund/MutualFund";
import FutreValuePresentMoney from "../../pages/FutreValuePresentMoney/FutreValuePresentMoney";
import PresentValueFutureMoney from "../../pages/PresentValueFutureMoney/PresentValueFutureMoney";
import Health from "../../pages/Health/Health";

export default function Tabs() {
  import("../../pages/retirement/rt.css").then(() => {});
  let navigate = useNavigate();
  const [tabId, setTab] = React.useState(window.location.hash?.slice(2, 100));

  useEffect(() => {
    openCity();
  }, []);

  const openCity = () => {
    setTimeout(() => {
      let ids=document.getElementsByClassName(`${tabId}`);
      console.log("ids[0]",tabId)
      ids[0].className +=" active";
     }, 1);
    
  };

  return (
    <>
      <div className="tab d-none d-lg-block  pt-5">
        <button
          className="tablinks FDCal fd-calulator"
          data-tooltip-id="fd"
          onClick={(event) =>{navigate("/fd-calulator")}}
        >
          <img className="tab-img" src={FD} />
        </button>
        <ReactTooltip
          id="fd"
          place="top"
          content="Fixed Deposit Calculator"
        />
        <button
          className="tablinks TermCal term-calulator"
          data-tooltip-id="Term-cal"
          onClick={(event) =>{navigate("/term-calulator")}}
        >
          <img className="tab-img" src={Term} />
        </button>
        <ReactTooltip
          id="Term-cal"
          place="top"
          content="Term Insurance Calculator"
        />
        <button
          className="tablinks childCal child-calulator"
          data-tooltip-id="Invest"
          onClick={(event) =>{navigate("/child-calulator")}}
        >
          <img className="tab-img" src={Invest} />
        </button>
        <ReactTooltip
          id="Invest"
          place="top"
          content="Child Education Calculator"
        />
        <button
          className="tablinks ReccuringCal rd-calulator"
          data-tooltip-id="Recurring"
          onClick={(event) =>{navigate("/rd-calulator")}}
        >
          <img className="tab-img" src={Recurring} />
        </button>
        <ReactTooltip
          id="Recurring"
          place="top"
          content="Recurring Deposit Calculator"
        />
        <button
          className="tablinks SIPCal sip-calulator"
          data-tooltip-id="SIP"
          onClick={(event) =>{navigate("/sip-calulator")}}
        >
          <img className="tab-img" src={SIP} />
        </button>
        <ReactTooltip id="SIP" place="top" content="SIP Calculator" />

        <button
          className="tablinks RetirementCal retirment-calulator"
          data-tooltip-id="Retirement"
          onClick={(event) =>{navigate("/retirment-calulator")}}
        >
          <img className="tab-img" src={Ulip} />
        </button>
        <ReactTooltip
          id="Retirement"
          place="top"
          content="Retirement Calculator"
        />
        <button
          className="tablinks PPFCal ppf-calulator"
          data-tooltip-id="PPF"
          onClick={(event) =>{navigate("/ppf-calulator")}}
        >
          <img className="tab-img" src={PPF} />
        </button>
        <ReactTooltip id="PPF" place="top" content="PPF Calculator" />
        <button
          className="tablinks IncomeTaxCal incometax-calulator"
          data-tooltip-id="IncomeTax"
          onClick={(event) =>{navigate("/incometax-calulator")}}
        >
          <img className="tab-img" src={IncomeImg} />
        </button>
        <ReactTooltip
          id="IncomeTax"
          place="top"
          content="IncomeTax Calculator"
        />

        <button
          className="tablinks CagrCal cagr-calulator"
          data-tooltip-id="CagrCal1"
          onClick={(event) =>{navigate("/cagr-calulator")}}
        >
          <img className="tab-img" src={CagrCal} />
        </button>
        <ReactTooltip id="CagrCal1" place="top" content="CAGR Calculator" />

        {/* <button
          className="tablinks MutualFundCal mutual-calulator"
          data-tooltip-id="MutualFundCal1"
          onClick={(event) =>{navigate("/mutual-calulator")}}
        >
          <img className="tab-img" src={Mutualimg} />
        </button>
        <ReactTooltip
          id="MutualFundCal1"
          place="top"
          content="Mutual Fund Calculator"
        /> */}


          <button
          className="tablinks FVPMCal future-calulator"
          data-tooltip-id="FVPMCal1"
          onClick={(event) =>{navigate("/future-calulator")}}
        >
          <img className="tab-img" src={FVPMimg} />
        </button>
        <ReactTooltip id="FVPMCal1" place="top" content="Future Value  Present Money Calculator" />

        <button
          className="tablinks PMFVCal present-calulator"
          data-tooltip-id="PMFVCal1"
          onClick={(event) =>{navigate("/present-calulator")}}
        >
          <img className="tab-img" src={PMFVimg} />
        </button>
        <ReactTooltip
          id="PMFVCal1"
          place="top"
          content="Present Value Future Money Calculator"
        />

        <button
          className="tablinks HealthCal medical-calulator medical-calculated medical-speech"
          data-tooltip-id="HealthCal1"
          onClick={(event) =>{navigate("/medical-calulator")}}
        >
          <img className="tab-img" src={health} />
        </button>
        <ReactTooltip
          id="HealthCal1"
          place="top"
          content="Health Calculator"
        />
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/> <br/>
        <br/>
        <br/> <br/>
        <br/>
        <br/>
      </div>
      
     
    </>
  );
}
