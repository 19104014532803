import React, { useEffect, useState } from "react";
import "./ppf.css"
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Chart from 'react-apexcharts'
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";

export default function PPFCalculator() {

  const navigate = useNavigate()
  const [investValue, setInvestValue] = React.useState(10000);
  const [rate, setRate] = React.useState(7.1);
  const [time, setTime] = React.useState(15);
  const [investFlag, setInvestFlag] = React.useState(false);
  const [investErr, setInvestErr] = React.useState("");
  const [timetFlag, setTimeFlag] = React.useState(false);
  const [timetErr, setTimeErr] = React.useState("");
  const [ratetFlag, setRateFlag] = React.useState(false);
  const [ratetErr, setRateErr] = React.useState("");
  const [invsVal, setEnvVal] = React.useState("");
  const [returnVal, setReturnVal] = React.useState("");
  const [totalVal, setTotalVal] = React.useState("");
  const [data, setData] = useState({
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
      tooltip: {
        y: {
          title: {
            formatter(seriesName) {
              return seriesName
            }
          }
        }
      },
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8,
        }
      },
      colors: ['#0073BB', '#E05776'],
    },
    series: [],
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<span>" +
          w.globals.labels[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      }
    }

  })

  const handleChangeComplete = () => {
  }

  useEffect(() => {
    if (investValue < 500) {
      setInvestFlag(true);
      setInvestErr("Minimum value allowed is 500")
    } else if (investValue > 10000000) {
      setInvestFlag(true);
      setInvestErr("Maximun value allowed is 10000000")
    } else {
      setInvestFlag(false);
      setInvestErr("")
    }
  }, [investValue]);

  useEffect(() => {
    if (rate < 1) {
      setRateFlag(true);
      setRateErr("Minimum value allowed is 1")
    } else if (rate > 15) {
      setRateFlag(true);
      setRateErr("Maximun value allowed is 15")
    } else {
      setRateFlag(false);
      setRateErr("")
    }
  }, [rate]);

  useEffect(() => {
    if (time < 15) {
      setTimeFlag(true);
      setTimeErr("Minimum value allowed is 15")
    } else if (time > 40) {
      setTimeFlag(true);
      setTimeErr("Maximun value allowed is 40")
    } else {
      setTimeFlag(false);
      setTimeErr("")
    }
  }, [time]);

  const handleCalculation = (investment, rate, time) => {
    if (parseInt(investment) > 499 && parseInt(rate) > 0 && parseInt(time) > 14) {
      let open = 0
      let i = 1;
      let invesmet_amonut = investment * time;
      if (investment === "" || rate === "" || time === "") { } else {
        investment = +investment;
        rate = +rate;
        time = +time;
        open = 0
      }
      while (i <= time) {
        var interast = (open + investment) * rate / 100;
        var futureValue = open + investment + interast;
        open = futureValue;
        i++
      }
      let est_rtn = futureValue - invesmet_amonut;
      let nf = new Intl.NumberFormat('en-IN');



      setEnvVal(nf.format(invesmet_amonut.toFixed(0)));
      setReturnVal(nf.format(est_rtn?.toFixed(0)));
      setTotalVal(nf.format(futureValue?.toFixed(0)));
      let dt = data;
      dt.options.labels[0] = "Total Investment(₹) : " + " " + nf.format(futureValue?.toFixed(0));
      dt.options.labels[1] = "Total Returns(₹) : " + " " + nf.format(est_rtn?.toFixed(0));
      dt.series[0] = invesmet_amonut
      dt.series[1] = parseInt(est_rtn?.toFixed(0))
      setData(dt);

    }
    else{
      let nf = new Intl.NumberFormat('en-IN');
      setEnvVal(0);
      setReturnVal(0);
      setTotalVal(0);
      
      let dt = data;
      dt.options.labels[0] = "Total Investment(₹) : " + " " + '₹' + " " + nf.format(0);
      dt.options.labels[1] = "Total Returns(₹) : " + " " + '₹' + " " + nf.format(0);
      dt.series[0] = 50
      dt.series[1] = 50
      setData(dt);
  }

  }

  useEffect(() => {
    handleCalculation(investValue, rate, time);
  }, [investValue, rate, time]);

  function Example(props) {
    return (
      <Chart className='donut-chart' options={data.options} series={data.series} type="donut" />
    );
  }

  return (
    <div>
      <Tabs/>
    <div className="custome-row">
    <div className="container-fluid">
      <div className='path-text'> <span className="ta-cursor" onClick={() => {
        navigate("/")
      }}>Calculator</span> &gt; <span style={{color:"#0073bb"}}>PPF Calculator</span></div>
      {/* <div className='mt-4 fd-cal-text '>PPF Calculator</div> */}
      <div className='row  '>
        <div className='col-lg-8 col-12 mt-2'>
          <div className='background' id="content-sip">
            <div>
              <div className='d-flex justify-content-between'>
                <div>Yearly Investment</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {investFlag === true ? <svg data-tooltip-id="invest" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                  </span>&nbsp;
                  <ReactTooltip id="invest" place="top" content={investErr} />
                  <input style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: investFlag === true ? "#E05776" : '' }} className={`form-control shadow-none sm-fl`} id={`${investFlag === true ? "fname1" : "fname"}`} value={"₹"} readOnly />
                  <input onChange={e => {

                    if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else if (e.target.value.includes(".") == true) { } else {
                      if (e.target.value > 10000000) { setInvestValue(10000000); } else if (e.target.value == "") { setInvestValue(0); } else if (e.target.value == 0) { setInvestValue(0); } else {
                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                          let va = e.target.value.substring(1)
                          setInvestValue(va)
                        } else if (investValue == 0 && e.target.value?.split("")[1] == '0') {
                          let va = e.target.value?.split("")[0];
                          setInvestValue(va)
                        } else {
                          setInvestValue(e.target.value)
                        }
                      }
                    }

                    // if(isNaN(e.target.value)){}else{
                    //   if (e.target.value > 10000000) {
                    //     setInvestValue(10000000)
                    //   } else if (e.target.value === "") {
                    //     setInvestValue(0)
                    //   } else {
                    //     let str = Number(e.target.value).toString();
                    //     setInvestValue(str)
                    //   }
                    // }
                  }} value={investValue} maxLength={10000000} style={{ background: investFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: investFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right" }} className={`shadow-none form-control sm-bg`} type="text" id={`${investFlag === true ? "fname1" : "fname"}`} name="fname" />
                </div>
              </div>
              <Slider
                min={500}
                max={10000000}
                value={investValue}
                onChange={(value) => {
                  setInvestValue(value)
                }}
                onChangeComplete={handleChangeComplete}
                step={500}
              />
            </div>
            <div>
              <div className='d-flex justify-content-between '>
                <div>Time Period (In Years)</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span >
                    {timetFlag === true ? <svg data-tooltip-id="time" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                  </span>&nbsp;
                  <ReactTooltip id="time" place="top" content={timetErr} />

                  <input onChange={e => {
                    if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) { } else if (e.target.value.includes("-") == true) { } else {
                      if (time != "0" && e.target.value > 40) { setTime(40); } else if (e.target.value == "") { setTime(0); } else if (e.target.value.includes(".") == true) { } else if (e.target.value == 0) { setTime(0); } else {
                        if (e.target.value.indexOf(0) == '0' && e.target.value.length > 1) {
                          let va = e.target.value.substring(1)
                          setTime(va)
                        } else if (time == 0 && e.target.value?.split("")[1] == '0') {
                          let va = e.target.value?.split("")[0];
                          setTime(va)
                        } else {
                          setTime(e.target.value)
                        }
                      }
                    }

                  }} value={time} className='shadow-none form-control sm-bgr' style={{ color: timetFlag === true ? "#E05776" : '', paddingLeft: 0, textAlign: "right", paddingRight: 2 }} type="text" id={`${timetFlag === true ? "fname1" : "fname"}`} name="fname" />
                  <span style={{ background: timetFlag === true ? "rgba(187, 3, 0, 0.1)" : 'rgba(0, 115, 187, 0.10)', color: timetFlag === true ? "#E05776" : '', marginLeft: 2, paddingLeft: 0 }} className='shadow-none form-control sm-fr wdthcls' id={`${timetFlag === true ? "fname1" : "fname"}`} >Yr</span>
                </div>
              </div>
              <Slider
                min={15}
                max={40}
                value={time}
                onChange={(value) => {
                  setTime(value)
                }}
                onChangeComplete={handleChangeComplete}

              />
              <div className='d-flex justify-content-between'>
                <div>Rate Of Interest</div>
                <div className="d-flex" style={{ width: 115 }}>
                  <span>
                    {ratetFlag === true ? <svg data-tooltip-id="rate" style={{ color: '#E05776' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" height="18" width="18" class="cis93InfoIcon contentNegative"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg> : ""}
                  </span>&nbsp;
                  <ReactTooltip id="rate" place="top" content={ratetErr} />
                  <div className="d-flex">
                    <input onChange={e => {
                      if (isNaN(e.target.value)) {

                      } else {
                        if (e.target.value === "") {
                          setRate(0)
                        } else {
                          if (e.target.value.indexOf(0) == '0' && e.target.value >= 1) {
                            let va = e.target.value.substring(1)
                            setRate(va)
                          } else if (rate == 0 && e.target.value?.split("")[1] == '0') {

                            let va = e.target.value?.split("")[0];
                            setRate(va)
                          } else {
                            if (e.target.value.includes(".") == true && e.target.value >= 1) {
                              let srr = e.target.value.split(".");
                              if (srr[1]?.length > 2) {
                              } else {
                                let str = (e?.target?.value)
                                setRate(str)
                              }
                            } else if (e.target.value >= 1) {
                              let str = e?.target?.value
                              setRate(str)
                            }
                          }
                        }
                      }
                    }} value={rate} readOnly className='shadow-none form-control sm-bgr' style={{ color: ratetFlag === true ? "#E05776" : '', textAlign: "right", paddingRight: 0, marginLeft: 10 }} type="text" id={`${ratetFlag === true ? "fname12" : "fname2"}`} name="fname" />
                    <span style={{
                      background: ratetFlag === true ? "rgb(165 184 196 / 10%) !important" : 'rgb(165 184 196 / 10%)', color: ratetFlag === true ? "#E05776" : '',
                      marginLeft: 3, paddingLeft: 6
                    }} className='shadow-none form-control sm-fr wdthcls' id={`${ratetFlag === true ? "fname12" : "fname2"}`} >%</span>
                  </div>
                </div>
              </div>

            </div>
            <div>

            </div>
            <div className='d-flex justify-content-between pb-4'>
              <div className='sip-count-text mt-4'><b>Invested Amount</b></div>
              <div className="mt-4"><b>₹ {invsVal}</b></div>
            </div>
            <div className='d-flex justify-content-between '>
              <div className='sip-count-text'><b>Total Interest</b></div>
              <div><b>₹ {returnVal}</b></div>
            </div>
            <div className='d-flex justify-content-between pt-3 pb-4 mb-1'>
              <div className='sip-count-text'><b>Maturity Value</b></div>
              <div><b>₹ {totalVal}</b></div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-12 mt-4 mb-5 pb-5 mb-lg-0'>
          <div className='background p-4 text-center'>
            <div className="pt-4"></div>
            <div className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <div className="circle-blue"></div>
                <div className="circle-text">Total Investment</div>
              </div>
              <div className="d-flex justify-content-center mx-4 pb-3">
                <div className="circle-pink"></div>
                <div className="circle-text">Total Interest</div>
              </div>
            </div>
            {<Example />}
            {/* <div className="pb-5"></div> */}
          </div>
        </div>
        <div className="col-lg-8 col-12">
          <div >
            <span style={{ marginLeft: 10 }}>

              <strong>FAQs</strong>
            </span>
          </div>
          <div class="accordion mt-0 mb-5" id="PPFCaccordionExample1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="PPFCheadingOne">
                <button
                  class="accordion-button collapsed px-1 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#PPFCcollapseOne"
                  aria-expanded="true"
                  aria-controls="PPFCcollapseOne"
                >
                  &nbsp;&nbsp;What is PPF?
                </button>
              </h2>
              <div
                id="PPFCcollapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="PPFCheadingOne"
                data-bs-parent="#PPFCaccordionExample1"
              >
                <div class="accordion-body px-1">
                  <>
                    <p id="isPasted">
                      Public Provident Fund (PPF) is considered an excellent investment option, especially for people
                      uncomfortable with taking risks. While the returns may not be very high because they depend on
                      the market, they offer stability. Additionally, investing in PPF can help diversify your portfolio and
                      has tax benefits.
                    </p>
                  </>
                </div>
              </div>
            </div>
            <div class="accordion-item  pb-5 pb-md-0" id="PPFCaccordionExample1">
              <h2 class="accordion-header" id="PPFCheadingTwo">
                <button
                  class="accordion-button collapsed px-1 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#PPFCcollapseTwo"
                  aria-expanded="false"
                  aria-controls="PPFCcollapseTwo"
                >
                  &nbsp; Products that offer similar + added benefits.
                </button>
              </h2>
              <div
                id="PPFCcollapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="PPFCheadingTwo"
                data-bs-parent="#PPFCaccordionExample1"
              >
                <div class="accordion-body px-1">
                  <p style={{ textAlign: "left" }} id="isPasted">
                    <p>
                      Insurance products also offer savings or investment benefits, here are some options:<br />
                    </p>
                    <p>
                   
                      1.<strong> Unit-Linked Insurance Plans (ULIPs):</strong>
                       ULIPs combine insurance coverage with an investment
                      component. A portion of your premium goes toward insurance, and the remainder is
                      invested in various funds like equity, debt, or balanced funds. ULIPs offer the potential for
                      market-linked returns but come with associated costs and lock-in periods.<br/><br/>
                      2. <strong>Endowment Plans:</strong> Endowment insurance policies offer both insurance coverage and savings
                      benefits. These policies provide a lump sum payout at maturity, making them similar to long-
                      term savings plans.<br/><br/>

                      3. <strong>Money-Back Policies:</strong> Money-back insurance policies provide periodic payouts (usually a
                      percentage of the sum assured) during the policy term. These policies can be considered a
                      form of savings with an insurance component.<br/><br/>
                      4. <strong> Whole Life Insurance with Cash Value: </strong> Some whole life insurance policies accumulate cash
                      value over time, which can be accessed or borrowed against. The cash value component can
                      serve as a long-term savings and investment option.<br/><br/>
                      5. <strong>Child Plans and Education Plans:</strong> These insurance policies are designed to provide for a
                      child's future financial needs, such as education. They combine insurance coverage with an
                      investment component to fund specific milestones.<br/><br/>
                      6. <strong>Retirement Plans (Pension Plans):</strong> Retirement or pension plans provide income security
                      during your retirement years. These plans involve regular premium payments, and the
                      accumulated corpus is invested to generate returns that can be received as a pension after
                      retirement. It's important to note that while these insurance-linked products offer an
                      investment or savings component, they often come with associated costs, including charges
                      and fees.
                      When considering insurance products with an investment element, carefully review the policy terms,
                      assess the historical and expected returns, understand the cost structure, and ensure the product
                      aligns with your financial goals and risk tolerance. Consult with a financial advisor to make informed
                      decisions based on your specific needs and objectives.
                    </p>
                  </p>

                </div>
              </div>
            </div>
            <div class="accordion-item" id="PPFCaccordionExample1">
            <h2 class="accordion-header" >
              <button
                class="accordion-button collapsed px-1 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#PPFCheadingFour"
                aria-expanded="false"
                aria-controls="PPFCheadingFour"
              >
                &nbsp; How to use PPF Calculator?
              </button>
            </h2>
            <div
              id="PPFCheadingFour"
              class="accordion-collapse collapse"
              aria-labelledby="PPFCheadingFour"
              data-bs-parent="#PPFCaccordionExample1"
            >
              <div class="accordion-body px-1">
              To compute your Public Provident Fund (PPF), follow these steps:<br/><br/>
              <strong>1.Yearly Investment:</strong>  Enter the amount manually or use the slider.<br/>
              <strong>2.Time Period (In Years):</strong>   Enter the duration manually or use the slider.<br/>
              <strong>3.Rate of Interest:</strong>  The interest rate is fixed.<br/><br/>
              <strong>Result Display:</strong><br/>
              After entering all the required details, the calculated results will display at the bottom of
              the screen.<br/><br/>
              <strong>Additional Functionality:</strong><br/>
                <strong>Pie Chart Feature:</strong><br/>
               <strong>1.Total Investment:</strong>   Shows the total investment.<br/>
               <strong>2.Total Interest:</strong>  Displays the total Interest.<br/><br/>
               <strong>Tooltips:</strong>  <br/>
               <strong>1.On PC:</strong>   Mouse hover over Total Investment for a tooltip displaying the
              invested amount.<br/>
               <strong>2.On PC:</strong>   Mouse hover over Total Interest for a tooltip displaying the interest
              amount.<br/>
               <strong>3.On Mobile:</strong>   Tap & hold on Total Investment for a tooltip showing the
              invested amount.<br/>
               <strong>4.On Mobile:</strong>   Tap & hold on Total Interest for a tooltip displaying the interest
              amount.<br/><br/>
              Effortlessly calculate your PPF today!
              </div>
            </div>
          </div>
          </div>

        </div>
        <div className="pb-5"></div>
      </div>
    </div>
    </div>
    </div>
  )
}