import React, { useEffect, useState } from "react";
import "./Health.css";
import axios from 'axios';
import "react-tooltip/dist/react-tooltip.css";
import "react-rangeslider/lib/index.css";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";
import MedicarePre from "./MedicarePremier.json";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MedicalModalComponent from "./modal-content";
import MedicalModalComponent1 from "./modal-content1";
import toast, { Toaster } from "react-hot-toast";
import Info from '../../../assets/images/info.png'
export default function Calculated() {
  const [getPlanFlag, setGetPlanFlag] = useState(false);
  const [cityValue, setCity] = useState("Zone-A");
  const [er, setEr] = useState("");
  const [nameEr, setNameEr] = useState("");
  const [name, setName] = useState("");
  const [eamil, setEmail] = useState("");
  const [insuredValue, setInsuredValue] = useState("5-lacs");
  const [ageValue, setAgeValue] = useState(0);
  const [insuredAmount1, setInsuredAmount1] = useState("");
  const [insuredAmountPre, setInsuredAmountPre] = useState("");
  const [dob, setDob] = useState("");
  const [getMedicarePlanFlag, setMedicarePlanFlag] = useState(true);
  const [onShow, setonShow] = useState(false);
  const [onShow1, setonShow1] = useState(false);
  const [number, setNumber] = useState("");
  const [age, setAge] = useState("");
  const [option1, setoption1] = useState("NO");
  const [option2, setoption2] = useState("NO");
  const [option3, setoption3] = useState("NO");
  const [policyTenure, setPolicyTenure] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [erCov, setErCov] = useState("");
  const [preArr, setPreArr] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("state")) {
      let info = localStorage.getItem("state");
      let usr = JSON.parse(info);
      setName(usr?.name);
      setEmail(usr?.email);
      setNumber(usr?.number);
      setDob(usr?.age);
      setCity(usr?.cityValue?.split(",")[0]);
      if (usr?.age != "") {
        const getAge = Math.floor(
          (new Date() - new Date(usr?.age).getTime()) / 3.15576e10
        );
        setAge(getAge);
        if (usr?.policy == "") {
          setPolicyTenure("")
        } else {
          setPolicyTenure(parseInt(usr?.policy));
        }
      }
    } else {
      let initial = {
        name: '',
        email: "",
        age: '',
        age: "",
        cityValue: "Zone-A",
        number: "",
        policy: ""
      }
      localStorage.setItem("state", JSON.stringify(initial));
    }
  }, []);

  useEffect(() => {
    setNameEr("");
    // debugger
    // console.log(policyTenure,"policyTenurepolicyTenurepolicyTenure")
    let info = localStorage.getItem("state");
    let usr = JSON.parse(info);
    // if (age > 0 && age != "" && age != NaN && age <= 80 && policyTenure >= age) {
    // if (age > 0 && age != "" && age != NaN ) {
      let zone = MedicarePre[cityValue?.split(",")[0]];
      let pre = [];
      let ab = (usr?.policy!="")? parseInt(usr?.policy):1;
      // debugger
      // if(ab!="" || ab!=NaN){
        for (let j = 0; j <= ab+age; j++) {
          // console.log("=>>>>>>>>>>>",j)
          // debugger
          // if()
          if(j>age){
            for (let i = 0; i < zone?.length; i++) {
              if (j >= zone[i]['Age']?.split("-")[0] && j <= zone[i]['Age']?.split("-")[1]) {
                pre.push(zone[i][insuredValue]);
                setPreArr(pre);
                setAgeValue(parseInt(zone[i][insuredValue]));
              };
            };
            if (parseInt(preArr[0]) != NaN) {
              let ins1 = (parseInt(preArr[0]) * 18) / 100;
              setInsuredAmountPre(parseInt(preArr[0]) + ins1);
            };
          }
          
        };
        
      // }
     
    // };

    const numbersArray = preArr.map(Number);
    let dataList = [];
    const interestRate = 18; 
    const inflationRate = 15; 
    let inflationPremium = 0;
    let compoundInterest = 0;
    let allTotal = 0;
    let total = 0;
   numbersArray.map((value, index) => {
      if (index == 0) {
        inflationPremium = 0.00;
        total = value + Math.round(inflationPremium);
        compoundInterest = Math.round((total * interestRate) / 100);
        allTotal += Math.round(inflationPremium) + Math.round(compoundInterest) + value;
        let obj = {
          interest: inflationPremium,
          gst: compoundInterest,
          total: allTotal,
          premium: value,
          age: age
        };
        dataList.push(obj);
        setGraphData(dataList);
        setInsuredAmount1(allTotal);
      } else {
        inflationPremium = Math.round((total * inflationRate) / 100);
        total = value + Math.round(inflationPremium);
        compoundInterest = Math.round(((total) * interestRate / 100));
        allTotal += Math.round(inflationPremium) + Math.round(compoundInterest) + value;
        let obj = {
          interest: inflationPremium,
          gst: compoundInterest,
          total: allTotal,
          premium: value,
          age: age
        };
        dataList.push(obj);
        setGraphData(dataList);
        // console.log("abc",allTotal)
        setInsuredAmount1(allTotal);
      }
    });
  });

  const onSubmit = () => {
    let obj = {
      name: name,
      email: eamil,
      contact: number,
      city: cityValue,
      dob: dob,
      sumInsured: insuredValue,
      annualizedpremium: insuredAmountPre,
      coverageAge: policyTenure,
      gst: 18,
      inflation: 15,
      option1: option1,
      option2: option2,
      option3: option3,
      totalPremium: insuredAmount1,
      age: age
    };

    let decode=btoa(btoa(JSON.stringify(obj)));

    axios.post(`https://api.calculator.taction.in/api/api/v1/create_medical_calculator`, {data:decode})
      .then(res => {
        toast.success('Thank you for your reasponse');
        setTimeout(() => {
          navigate("/medical-speech")
        }, 1500);
      }).catch(ee=>{
        console.log(ee)
      });
    
  };

  return (
    <div>
      <Tabs />
      <Toaster
        position="top-right"
        reverseOrder={false}
        style={{ zIndex: 999999999 }}
      />
      <div className="custome-row">
        <div className="container-fluid">
          <div className="path-text mt-4" style={{ marginLeft: 14 }}>
            {" "}
            <span
              className="ta-cursor"
              onClick={() => {
                navigate("/");
              }}
            >
              Calculator
            </span>{" "}
            &gt;<span style={{color:"#0073bb"}}>Health Calculator</span>
          </div>
          {/* <div
            className="mt-0 fd-cal-text mt-4 pb-1"
            style={{ marginLeft: 14 }}
          >
            Medical Calculator
          </div> */}
          <div className="">
            <div className="container-fluid pb-3">
              {/* <div className="row m-3 pb-0">
              </div> */}
              {getPlanFlag === false ? (
                <>
                  <div className="row text-center">
                    <div id="detail-label" className="pb-3">
                      Mediclaim Plan
                    </div>
                    <div className="d-flex justify-content-center">
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-12 pb-0 ">
                        <div className="row justify-content-end">
                          <div className="col-6 col-lg-5 col-12 policy-label text-md-start">
                            Choose Your Sum Insured
                          </div>
                          <div className="col-lg-5 col-3 col-12">
                            <select
                              className="form-control shadow-none"
                              onChange={(e) => {
                                setInsuredValue((e.target.value))
                              }}
                              value={insuredValue}
                              style={{
                                height: 30,
                                paddingTop: 4,
                                width: "100%",
                              }}
                            >
                              <option value="5-lacs" selected>5 Lacs</option>
                              <option value="10-lacs">10 Lacs</option>
                              <option value="15-lacs">15 Lacs</option>
                              <option value="20-lacs">20 Lacs</option>
                              <option value="25-lacs">25 Lacs</option>
                              <option value="50-lacs">50 Lacs</option>
                              <option value="75-lacs">75 Lacs</option>
                              <option value="1-crore">1 Crore</option>
                              <option value="2-crore">2 Crore</option>
                              <option value="3-crore">3 Crore</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-12 text-start d-lg-flex mt-4 mt-md-0 justify-content-center justify-content-lg-start">
                        <div className="policy-label text-center text-md-start mx-lg-3 my-auto">
                          {" "}
                          Policy Term
                        </div>
                        <div className="d-flex">
                            <div>
                            <input
                              id="cov"
                              type="email"
                              value={policyTenure}
                              onChange={(e) => {
                                // debugger
                                setEr("");
                                setErCov("")
                                let str = localStorage.getItem("state");
                                let usr = JSON.parse(str);
                                if (isNaN(e.target.value)) {
                                } 
                                // if (e.target.value < age) {
                                //   setErCov(`Coverage age should be greater than current age ${age}`)
                                //   setPolicyTenure(e.target.value);
                                // } 
                                else if (e.target.value > 100) {
                                  setPolicyTenure(100);
                                  usr.policy = 100;
                                  localStorage.setItem("state", JSON.stringify(usr))
                                } else {
                                 
                                  setPolicyTenure(e.target.value!=""?e.target.value:"");
                                  usr.policy = e.target.value!=""?e.target.value:"";
                                  localStorage.setItem("state", JSON.stringify(usr))
                                }
                              }}
                              placeholder="Tenure in years"
                              className="form-control shadow-none"
                            />
                            </div>
                            <div className="my-auto mx-2 my-0">
                              {/* Till  {policyTenure > age ? <span>{policyTenure > age ? policyTenure - age : 0} Year{policyTenure > 9 ? "s" : ""}</span> : ""} age should the coverage continue */}
                              {/* {policyTenure > 9 ? "Year(s)" : "Year"} */}
                              </div>

                          </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`row text-center mt-3 ${getMedicarePlanFlag == false
                      ? "justify-content-center"
                      : ""
                      }`}
                  >
                    <div className="col-md-6 d-flex justify-content-center order-2  mt-4 mt-md-0">
                      <div className="background-div1">
                        <div className="text-bar1">
                          <div className="">Average mediclaim annualized premium</div>
                          <div>
                            1 Year
                          </div>
                          <div className="pb-3">
                            {/* Annualized premium */}
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          <div className="total-value">
                            <span className="strikethrough">
                            </span>
                           {policyTenure?<>
                            ₹ {new Intl.NumberFormat('en-IN').format(
                              parseInt(insuredAmount1/policyTenure)?.toFixed(2)
                            )}.00
                           </>:<>₹ {new Intl.NumberFormat('en-IN').format(
                              insuredAmount1
                            )}.00</>}
                            
                          </div>

                          <div>Inclusive of 18% GST</div>
                        </div>
                      </div>
                    </div>
                    {getMedicarePlanFlag == true ? (
                      <div className="col-md-6 d-flex justify-content-center order-1">
                        <div className="background-div">
                          <div className="text-bar">
                            <div className="">Total premium</div>
                            {/* {age} Till what age should the coverage continue */}
                            {/* {policyTenure?policyTenure:"0"} */}
                            {policyTenure?policyTenure:1} Year{policyTenure > 9 ? "s" : ""}
                            {/* {policyTenure >= age ? <div>{policyTenure > age ? policyTenure  : 0} Year{policyTenure > 9 ? "s" : ""}</div> : ""} */}
                            {/* {policyTenure > age ? <div>{policyTenure > age ? policyTenure - age : 0} Year{policyTenure > 9 ? "s" : ""}</div> : ""} */}
                            <div className="pb-3">
                              <div>Inclusive of 15% inflation approx</div>
                            </div>
                          </div>
                          <div className="text-center mt-4">
                            <div className="total-value">
                              ₹ {new Intl.NumberFormat('en-IN').format(parseInt(insuredAmount1))}.00
                              {insuredAmount1 > 0 ? <img src={Info} width={20} style={{ marginLeft: 5, marginBottom: 5, cursor: 'pointer' }} onClick={() => { setonShow(true) }} /> : ""}
                            </div>
                            <div>Inclusive of 18% GST</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center mt-2">{erCov ? <span style={{ color: 'red', fontSize: 12 }}>{erCov}</span> : ""}</div>
                </>
              ) : (
                ""
              )}
            </div>
            {getPlanFlag === false ?
              <div className="row m-5 my-0 pb-0">
                <div class="form-check">
                  <input onChange={(e) => { setoption1(e.target.checked === true ? "YES" : "NO") }} class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault" />
                  <label class="form-check-label paraa" for="flexCheckDefault">
                    Would you like a health insurance plan where you get all the premium back?
                  </label>
                </div>

                <div class="form-check mt-2">
                  <input onChange={(e) => { setoption2(e.target.checked === true ? "YES" : "NO") }} class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault1" />
                  <label class="form-check-label paraa" for="flexCheckDefault1">
                    Would you prefer a plan where premium remains consistence throughout the coverage term?
                  </label>
                </div>
                <div class="form-check mt-2">
                  <input onChange={(e) => { setoption3(e.target.checked === true ? "YES" : "NO") }} class="form-check-input shadow-none" type="checkbox" value="" id="flexCheckDefault2" />
                  <label class="form-check-label paraa" for="flexCheckDefault2">
                    Would you want the premium being paid back to you corrected as per the market rate?
                  </label>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-3 col-12 mt-4">
                    <button
                      onClick={onSubmit}
                      className="btn btn-danger "
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              : ""}
            {/* <div className="ml-4">
      <h2>Compound Interest, Inflation Premium, and Total Table</h2>
      <table>
        <thead>
          <tr>
            <th>Principal</th>
            <th>Compound Interest (18%)</th>
            <th>Inflation Premium (10%)</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </div> */}
          </div>
          <div className="pb-2"></div>
        </div>
      </div>
      {onShow == true ? <Modal show={onShow}
        size="lg"
        scrollable
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="shadow-none" closeButton onHide={(e) => { setonShow(false) }}>
          <Modal.Title id="contained-modal-title-vcenter">
            Premium detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MedicalModalComponent graphData={graphData ? graphData : []} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="close-btn-med" onClick={(e) => { setonShow(false) }}>Close</Button>
        </Modal.Footer>
      </Modal> : onShow1 == true ? <Modal show={onShow1}
        size="md"
        scrollable
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={(e) => { setonShow1(false) }}>
          <Modal.Title id="contained-modal-title-vcenter">
            MediCare
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MedicalModalComponent1 />
        </Modal.Body>
        <Modal.Footer>
          <Button className="close-btn-med" onClick={(e) => { setonShow1(false) }}>Close</Button>
        </Modal.Footer>
      </Modal> : ""}





    </div>
  );
}
