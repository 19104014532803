
import React, { useEffect, useState } from "react";
import "../income-tax/income-tax.css";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";

export default function IncomeTax() {
    const [gross, setGross] = useState(0);

    const [incomeVal, setIncome] = useState(0);
    const [incomefromInterest, setIncomeFromInterest] = useState(0);
    const [rentalIncomeReceived, setRentalIncomeReceived] = useState(0);
    const [incomeFromDigitalAssets, setIncomeFromDigitalAssets] = useState(0);
    const [shortTermCapitalGain, setShortTermCapitalGain] = useState(0);
    const [otherIncome, setOtherIncome] = useState(0);
    const [otherInterest, setOtherInterest] = useState(0);
    const [businessImcome, setBusinessImcome] = useState(0);
    const [intertainmentAllownce, setIntertainmentAllownce] = useState(0)
    const [professinalTax, setProfessinalTax] = useState(0)
    const [exemptAllowances, setExemptAllowances] = useState(0);
    const [interestOnHomeLoanSelfOccupied, setInterestOnHomeLoanSelfOccupied] = useState(0);
    const [interestOnHomeLoanLetOut, setInterestOnHomeLoanLetOut] = useState(0);


    const navigate = useNavigate()
    // New Regim
    const [taxAtNormal, setTaxAtNormal] = useState(0);
    const [taxBeforeRebate, setTaxBeforeRebate] = useState(0);
    const [taxRebate, setTaxRebate87A] = useState(0);
    const [taxAfterRebate, setTaxAfterRebate] = useState(0);
    const [surcharge, setSurcharge] = useState(0);
    const [healthEducation, setHealthEducation] = useState(0);
    const [totalTaxOnIncome, setTotalTaxOnIncome] = useState(0);
    // Old Regim
    const [oldTaxAtNormal, setOldTaxAtNormal] = useState(0);
    const [oldTaxBeforeRebate, setOldTaxBeforeRebate] = useState(0);
    const [oldTaxRebate, setOldTaxRebate87A] = useState(0);
    const [oldTaxAfterRebate, setOldTaxAfterRebate] = useState(0);
    const [oldTurcharge, setOldSurcharge] = useState(0);
    const [oldTealthEducation, setOldHealthEducation] = useState(0);
    const [oldTotalTaxOnIncome, setOldTotalTaxOnIncome] = useState(0);
    // Deduction
    const [d80C, setD80C] = useState(0);
    const [d80D, setD80D] = useState(0);
    const [d80E, setD80E] = useState(0);
    const [d80CCD, setD80CCD] = useState(0);
    const [d80TTA, setD80TTA] = useState(0);
    const [d80G, setD80G] = useState(0);
    const [d80EEA, setD80EEA] = useState(0);
    // HRA
    const [basicHRA, setBasicHRA] = useState(0);
    const [dearnessHRA, setDearnessHRA] = useState(0);
    const [receivedHRA, setReceivedHRA] = useState(0);
    const [rentPaidHRA, setRentPaidHRA] = useState(0);
    const [metroCityHRA, setMetroCityHRA] = useState('yes');
    const [displayValue, setDisplayValue] = useState('yes');

    const [newRegime, setNewRegime] = useState(false);
    const [ageCategory, setAgeCategory] = useState('60');
    const [hraFinal, setHraFinal] = useState(0);
    const [lessIncomeVal, setLessIncomeVal] = useState(0);
    const [capGain, setCapGain] = useState(0);

    useEffect(()=>{

        let shorPer=(parseInt(shortTermCapitalGain)*15)/100;
        let longPer=(parseInt(incomeFromDigitalAssets)*10)/100;
        let totalPer=parseInt(shorPer)+parseInt(longPer)
        setCapGain(totalPer);

    },[incomeFromDigitalAssets,shortTermCapitalGain])

    useEffect(() => {
        let sum = (parseInt(incomeVal) - parseInt(lessIncomeVal))
            + parseInt(incomefromInterest)
            + parseInt(rentalIncomeReceived)
            + parseInt(incomeFromDigitalAssets)
            + parseInt(otherIncome)
            + parseInt(exemptAllowances)
            +parseInt(shortTermCapitalGain)
            + parseInt(otherInterest)
            + parseInt(businessImcome)
            - parseInt(professinalTax)
            - parseInt(intertainmentAllownce)

        let deduct =
            parseInt(d80C) +
            parseInt(professinalTax) +
            parseInt(intertainmentAllownce) +
            parseInt(d80D) +
            parseInt(d80E) +
            parseInt(d80CCD) +
            parseInt(incomefromInterest >= d80TTA ? d80TTA : 0) +
            parseInt(d80G) +
            parseInt(d80EEA)
            // - parseInt(interestOnHomeLoanSelfOccupied)
            // - parseInt(interestOnHomeLoanLetOut)
        let hrsPer = 0
        if (metroCityHRA == "yes") {
            hrsPer = 50
        } else {
            hrsPer = 40
        }
        let baisc = parseInt(basicHRA) + parseInt(dearnessHRA)
        let perAll = baisc * hrsPer / 100;
        let basic10 = baisc * 10 / 100;
        let hra = (parseInt(rentPaidHRA) - basic10)
        let arr = [
            perAll,
            parseInt(receivedHRA),
            hra < 0 ? 0 : hra
        ]


        setDisplayValue(sum);

        arr.sort(function (a, b) { return a - b })
        setHraFinal(arr[0])
        if (sum > deduct && sum > arr[0]) {
            setGross(sum - deduct - arr[0]);
        } else {
            setGross(0);
        }
    }, [incomeVal, incomefromInterest, rentalIncomeReceived, incomeFromDigitalAssets, otherIncome,
        exemptAllowances, interestOnHomeLoanSelfOccupied, interestOnHomeLoanLetOut,
        d80C,
        d80D,
        d80E,
        d80CCD,
        d80TTA,
        d80G,
        d80EEA,
        basicHRA,
        dearnessHRA,
        receivedHRA,
        rentPaidHRA,
        metroCityHRA,
        otherInterest,
        businessImcome,shortTermCapitalGain,
        intertainmentAllownce,
        professinalTax
    ])

    const clearState = () => {
        setTaxAtNormal(0);
        setTaxBeforeRebate(0);
        setTaxRebate87A(0);
        setTaxAfterRebate(0);
        setSurcharge(0);
        setHealthEducation(0);
        setTotalTaxOnIncome(0);
        setOldTaxAtNormal(0)
        setOldTaxBeforeRebate(0)
        setOldTaxRebate87A(0)
        setOldTaxAfterRebate(0)
        setOldSurcharge(0)
        setOldHealthEducation(0)
        setOldTotalTaxOnIncome(0)

    }
    const clrStateOther = () => {
        setShortTermCapitalGain(0)
        setIncome(0)
        setIncomeFromInterest(0)
        setRentalIncomeReceived(0)
        setIncomeFromDigitalAssets(0)
        setOtherIncome(0)
        setExemptAllowances(0)
        setInterestOnHomeLoanSelfOccupied(0)
        setInterestOnHomeLoanLetOut(0)
        setD80C(0)
        setD80D(0)
        setD80E(0)
        setD80CCD(0)
        setD80TTA(0)
        setD80G(0)
        setD80EEA(0)
        setBasicHRA(0)
        setDearnessHRA(0)
        setReceivedHRA(0)
        setRentPaidHRA(0)
    }

    // useEffect(() => {
    //     calculateIncomeTax();
    //     oldRegimCalculator()
    // }, [businessImcome])

    useEffect(() => {
        calculateIncomeTax();
        oldRegimCalculator()
    }, [incomeVal, incomefromInterest, rentalIncomeReceived, incomeFromDigitalAssets, otherIncome, gross, ageCategory, businessImcome,shortTermCapitalGain])


    const calculateIncomeTax = () => {

        if (businessImcome > 0
            && incomeVal == 0
            && incomefromInterest == 0
            && rentalIncomeReceived == 0
            && incomeFromDigitalAssets == 0
            && shortTermCapitalGain==0
            && otherIncome == 0
            && interestOnHomeLoanSelfOccupied == 0
            && interestOnHomeLoanLetOut == 0
            && otherInterest == 0
            && professinalTax==0
            && intertainmentAllownce==0
        ) {

            let tax = 0
            clearState();
            let income = businessImcome;
            if (income <= 300000) {
                tax = 0;
            } else if (income > 300000 && income <= 600000) {
                tax = (income - 300000) * 0.05;
            } else if (income > 600001 && income <= 900000) {
                tax = 300000 * 0.05 + (income - 600000) * 0.1;
            } else if (income > 900001 && income <= 1200000) {
                tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
            } else if (income > 1200001 && income <= 1500000) {
                tax =
                    300000 * 0.05 +
                    300000 * 0.1 +
                    300000 * 0.15 +
                    (income - 1200000) * 0.2;
            } else {
                tax =
                    300000 * 0.05 +
                    300000 * 0.1 +
                    300000 * 0.15 +
                    300000 * 0.2 +
                    (income - 1500000) * 0.3;
            }
            if (income > 0 && income <= 700000) {
                setTaxAtNormal(parseInt(tax));
                setTaxBeforeRebate(parseInt(tax));
                setTaxRebate87A(parseInt(tax));
                setTaxAfterRebate(0);
                setSurcharge(0);
                setHealthEducation(0);
                setTotalTaxOnIncome(0);


            } else if (income > 700000 && income <= 10000000) {
                if (income > 700000 && income <= 727776) {
                    let diff = 0;
                    diff = income - 700000;
                    let A = 0;
                    A = tax - diff
                    let H = 0;
                    H = diff * 4 / 100;
                    let N = 0;
                    N = diff + H;
                    setTaxAtNormal(parseInt(tax));
                    setTaxBeforeRebate(parseInt(tax));
                    setTaxRebate87A(parseInt(A));
                    setTaxAfterRebate(parseInt(diff));
                    setSurcharge(0);
                    setHealthEducation(parseInt(H));
                    setTotalTaxOnIncome(parseInt(N));

                } else if (income > 727776 && income <= 5000000) {
                    let HE = 0;
                    HE = tax * 4 / 100;
                    setTaxAtNormal(parseInt(tax));
                    setTaxBeforeRebate(parseInt(tax));
                    setTaxRebate87A(0);
                    setTaxAfterRebate(parseInt(tax));
                    setSurcharge(0);
                    setHealthEducation(parseInt(HE));
                    setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE));

                } else if (income > 5000000 && income <= 10000000) {
                    let difference = 0;
                    difference = income - 5000000;
                    difference = difference * 70 / 100
                    let HE = 0;
                    let Surcharge = 0;
                    Surcharge = (parseInt(tax) * 10) / 100;
                    if (difference < Surcharge && income >= 5000001 && income <= 5179103) {
                        HE = ((tax + difference) * 4) / 100;
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(Math.ceil(difference));
                        setHealthEducation(parseInt(HE));
                        setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));

                    } else if (difference > Surcharge && income > 5179103 && income <= 10000000) {
                        HE = ((tax + Surcharge) * 4) / 100;
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(parseInt(Surcharge));
                        setHealthEducation(parseInt(HE));
                        setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.floor(Surcharge)));
                    }
                }

            }
            else if (income > 10000000 && income <= 20000000) {

                let Surcharge = 0;
                let difference = 0;
                let HE = 0;
                if (income > 10000000 && income <= 10206105) {
                    Surcharge = (2700000 * 10) / 100;
                    difference = income - 10000000;
                    difference = difference * 70 / 100;
                    difference = difference + Surcharge;
                    HE = ((tax + difference) * 4) / 100;
                    setTaxAtNormal(parseInt(tax));
                    setTaxBeforeRebate(parseInt(tax));
                    setTaxRebate87A(0);
                    setTaxAfterRebate(parseInt(tax));
                    setSurcharge(Math.ceil(difference));
                    setHealthEducation(parseInt(HE));
                    setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
                }
                else if (income > 10206105 && income <= 20000000) {
                    Surcharge = (tax * 15) / 100;
                    HE = ((tax + Surcharge) * 4) / 100;
                    setTaxAtNormal(parseInt(tax));
                    setTaxBeforeRebate(parseInt(tax));
                    setTaxRebate87A(0);
                    setTaxAfterRebate(parseInt(tax));
                    setSurcharge(Math.ceil(Surcharge));
                    setHealthEducation(parseInt(HE));
                    setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

                }
            }

            else if (income > 20000000) {
                let Surcharge = 0;
                let HE = 0;
                let difference = 0;
                if (income >= 20000001 && income <= 20912000) {
                    Surcharge = (5700000 * 15) / 100;
                    difference = income - 20000000;
                    difference = difference * 70 / 100;
                    difference = difference + Surcharge;
                    HE = ((tax + difference) * 4) / 100;

                    setTaxAtNormal(parseInt(tax));
                    setTaxBeforeRebate(parseInt(tax));
                    setTaxRebate87A(0);
                    setTaxAfterRebate(parseInt(tax));
                    setSurcharge(Math.ceil(difference));
                    setHealthEducation(parseInt(HE));
                    setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));

                }
                else if (income > 20912000) {
                    let Surcharge = 0;
                    let HE = 0;
                    // let difference=0;
                    Surcharge = (tax * 25) / 100;
                    HE = ((tax + Surcharge) * 4) / 100;
                    setTaxAtNormal(parseInt(tax));
                    setTaxBeforeRebate(parseInt(tax));
                    setTaxRebate87A(0);
                    setTaxAfterRebate(parseInt(tax));
                    setSurcharge(parseInt(Surcharge));
                    setHealthEducation(parseInt(HE));
                    setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

                }
            }

            // End bussiness calculation
        } else {
            clearState();
            let tax = 0;
            const income = displayValue+parseInt(professinalTax)+parseInt(intertainmentAllownce);
            if (ageCategory == '60') {

                // debugger
                if (income > 0 && income <= 10000000) {
                    if (income > 0 && income < 300000) {
                        tax = 0;
                    } else if (income >= 300000 && income <= 650000) {
                        tax = (income - 300000) * 0.05;
                    } else if (income > 650000 && income <= 900000) {
                        tax = 300000 * 0.05 + (income - 600000) * 0.1;
                    } else if (income > 900000 && income <= 1200000) {
                        tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
                    } else if (income > 1200000 && income <= 1500000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            (income - 1200000) * 0.2;
                    } else {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    // console.log(tax)
                    // debugger
                    const diff = income - 5000000 - (tax - 1200000);
                    const diff1 = income - 1500000 - (tax - 750000);
                    const y = Math.abs(diff1) - 50000;
                    let D = 0;
                    D = ((tax - y) * 4) / 100;
                    let Surcharge = 0;
                    let Edu_Cess = 0;
                    let sur = 0;
                    Surcharge = (tax * 10) / 100;
                    Edu_Cess = ((tax + diff) * 4) / 100;
                    sur = ((tax + Surcharge) * 4) / 100;
                    let Education_Cess = 0;
                    Education_Cess = (tax * 4) / 100;
                    const Totalpaytax = tax + Education_Cess;
                    let b = 0;
                    b = income - 750000;
                    let c = 0;

                    c = income;
                    let tttt = 300000 * 0.05 + (c - 600000) * 0.1
                    const aaaaaa = income - 700000
                    // console.log(income)
                    const ooo = tax - aaaaaa

                    if (income > 0 && income <= 300019) {
                        setTaxAtNormal(parseInt(0));
                        setTaxBeforeRebate(parseInt(0));
                        setTaxRebate87A(parseInt(0));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }
                    // else if (income > 300019 && income <= 600008) {
                    //     setTaxAtNormal(parseInt(0));
                    //     setTaxBeforeRebate(parseInt(0));
                    //     setTaxRebate87A(parseInt(0));
                    //     setTaxAfterRebate(0);
                    //     setSurcharge(0);
                    //     setHealthEducation(parseInt(0));
                    //     setTotalTaxOnIncome(0);
                    // }
                    else if (c > 300019 && c <= 600009) {
                        // console.log("hi")
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(tax));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }

                    else if (c > 600009 && c <= 700000) {
                        // console.log(income)
                        setTaxAtNormal(parseInt(tttt));
                        setTaxBeforeRebate(parseInt(tttt));
                        setTaxRebate87A(parseInt(tttt));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }

                    else if (income > 700000 && income <= 700024) {


                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(ooo));
                        setTaxAfterRebate(parseInt(aaaaaa));
                        setSurcharge(0);
                        setHealthEducation(0);
                        setTotalTaxOnIncome(parseInt(aaaaaa));
                    }
                    else if (income > 700024 && income <= 727777) {


                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(ooo));
                        setTaxAfterRebate(Math.round(aaaaaa));
                        setSurcharge(0);
                        setHealthEducation(Math.round(aaaaaa * 4 / 100));
                        setTotalTaxOnIncome(parseInt(aaaaaa + Math.round(aaaaaa * 4 / 100)));
                    }

                    else if (income > 727777 && income <= 775000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(0);
                        setHealthEducation(parseInt((tax * 4) / 100));
                        setTotalTaxOnIncome(parseInt(parseInt(tax) + (parseInt(tax * 4) / 100)));
                    } else if (income > 775000 && income < 1500000) {
                        const sur = 0;
                        const E = (b * 4) / 100;

                        if (tax - b < 0) {
                            const T = parseInt(tax);
                            const F = parseInt((tax * 4) / 100);
                            setTaxAtNormal(parseInt(T));
                            setTaxBeforeRebate(parseInt(T));
                            setTaxRebate87A(0);
                            setTaxAfterRebate(parseInt(T));
                            setSurcharge(parseInt(sur));
                            setHealthEducation(parseInt(F));
                            setTotalTaxOnIncome(parseInt(T + F));
                        } else if (tax - b > 0) {
                            setTaxAtNormal(parseInt(tax));
                            setTaxBeforeRebate(parseInt(tax));
                            setTaxRebate87A(parseInt(tax - b));
                            setTaxAfterRebate(parseInt(b));
                            setSurcharge(parseInt(sur));
                            setHealthEducation(parseInt(D));
                            setTotalTaxOnIncome(parseInt(D + b));
                        }
                    } else if (income >= 1500000 && income <= 5000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(0);
                        setHealthEducation(parseInt(Education_Cess));
                        setTotalTaxOnIncome(parseInt(Education_Cess + tax));
                    } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(parseInt(Surcharge));
                        setHealthEducation(parseInt(sur));
                        setTotalTaxOnIncome(parseInt(tax + Surcharge + sur));
                    } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(parseInt(diff));
                        setHealthEducation(parseInt(Edu_Cess));
                        setTotalTaxOnIncome(parseInt(tax + Edu_Cess + diff));
                    }
                }
                else if (income > 10000000 && income < 20000000) {
                    // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
                    if (income > 10000000 && income < 20050000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    let diff = 0;
                    let sur = 0;
                    let edu_cess1 = 0;
                    if (income > 10000000 && income <= 10050000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = tax * 10 / 100
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);

                    }
                    else if (income > 10050000 && income <= 10060000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 10060000 && income <= 10110000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 10110000 && income < 20000000) {

                        const sssss = parseInt(tax * 15 / 100)
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sssss + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);

                        // console.log("sur",tax*15/100)
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(sssss);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + sssss + E1);
                    }

                }
                else if (income >= 20000000 && income < 50000000) {
                    if (income >= 20000000 && income <= 50000000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;

                    }

                    let diff = 0;
                    let sur = 0;
                    let edu_cess1 = 0;
                    if (income >= 20000000 && income <= 20050000) {
                        diff = income - 20000000 - (tax - 855000);
                        sur = tax * 15 / 100
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);

                    }
                    else if (income > 20050000 && income <= 20060000) {
                        const C = (income - 50000) - 20000000
                        const A = 5700000 + (C * 30 / 100)
                        const B = A * 25 / 100;
                        const D = C - (A - 5700000)
                        const sss = (5700000 * 15 / 100) + D
                        edu_cess1 = (tax + sss) * 4 / 100
                        const T = parseInt(tax);
                        const S = parseInt(sss);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 20060000 && income <= 20110000) {
                        const C = (income - 50000) - 20000000
                        const A = 5700000 + (C * 30 / 100)
                        const B = A * 25 / 100;
                        const D = C - (A - 5700000)
                        const sss = (5700000 * 15 / 100) + D
                        edu_cess1 = (tax + sss) * 4 / 100
                        const T = parseInt(tax);
                        const S = parseInt(sss);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 20110000 && income < 50000000) {
                        const T = parseInt(tax);
                        const sss = T * 25 / 100;
                        const S = parseInt(sss);
                        edu_cess1 = (T + S) * 4 / 100
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(T);
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                }
                else if (income >= 50000000) {
                    let edu_cess1 = 0;
                    if (income >= 50000000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    const T = parseInt(tax);
                    const sss = T * 25 / 100;
                    // console.log(T * 30 / 100)
                    const S = parseInt(sss);
                    edu_cess1 = (T + S) * 4 / 100
                    const E1 = parseInt(edu_cess1);
                    setTaxAtNormal(T);
                    setTaxBeforeRebate(T);
                    setTaxRebate87A("0");
                    setTaxAfterRebate(T);
                    setSurcharge(S);
                    setHealthEducation(E1);
                    setTotalTaxOnIncome(T + S + E1);
                }
            } else if (ageCategory == "60-79") {

                if (income > 0 && income <= 10000000) {
                    if (income > 0 && income <= 300000) {
                        tax = 0;
                    } else if (income > 300000 && income <= 650000) {
                        tax = (income - 300000) * 0.05;
                    } else if (income > 650000 && income <= 900000) {
                        tax = 300000 * 0.05 + (income - 600000) * 0.1;
                    } else if (income > 900000 && income <= 1200000) {
                        tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
                    } else if (income > 1200000 && income <= 1500000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            (income - 1200000) * 0.2;
                    } else {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    // debugger
                    const diff = income - 5000000 - (tax - 1200000);
                    const diff1 = income - 1500000 - (tax - 750000);
                    const y = Math.abs(diff1) - 50000;
                    let D = 0;
                    D = ((tax - y) * 4) / 100;
                    let Surcharge = 0;
                    let Edu_Cess = 0;
                    let sur = 0;
                    Surcharge = (tax * 10) / 100;
                    Edu_Cess = ((tax + diff) * 4) / 100;
                    sur = ((tax + Surcharge) * 4) / 100;
                    let Education_Cess = 0;
                    Education_Cess = (tax * 4) / 100;
                    const Totalpaytax = tax + Education_Cess;
                    let b = 0;
                    b = income - 750000;
                    let c = 0;

                    c = income;
                    let tttt = 300000 * 0.05 + (c - 600000) * 0.1
                    const aaaaaa = income - 700000
                    // console.log(income)
                    const ooo = tax - aaaaaa

                    // console.log("newwww",parseInt(tax))
                    // console.log("income",income)
                    // const aaa=tax-2500;

                    if (income > 0 && income <= 300019) {
                        setTaxAtNormal(parseInt(0));
                        setTaxBeforeRebate(parseInt(0));
                        setTaxRebate87A(parseInt(0));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }

                    else if (income > 300019 && income <= 500004) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(tax));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }




                    else if (c > 500004 && c <= 600009) {
                        // console.log("hi")
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(tax));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }

                    else if (c > 600009 && c <= 700000) {
                        // console.log(income)
                        setTaxAtNormal(parseInt(tttt));
                        setTaxBeforeRebate(parseInt(tttt));
                        setTaxRebate87A(parseInt(tttt));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                        setTotalTaxOnIncome(0);
                    }

                    else if (income > 700000 && income <= 700024) {


                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(ooo));
                        setTaxAfterRebate(parseInt(aaaaaa));
                        setSurcharge(0);
                        setHealthEducation(0);
                        setTotalTaxOnIncome(parseInt(aaaaaa));
                    }
                    else if (income > 700024 && income <= 727777) {


                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(ooo));
                        setTaxAfterRebate(Math.round(aaaaaa));
                        setSurcharge(0);
                        setHealthEducation(Math.round(aaaaaa * 4 / 100));
                        setTotalTaxOnIncome(parseInt(aaaaaa + Math.round(aaaaaa * 4 / 100)));
                    }

                    else if (income > 727777 && income <= 775000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(0);
                        setHealthEducation(parseInt((tax * 4) / 100));
                        setTotalTaxOnIncome(parseInt(parseInt(tax) + (parseInt(tax * 4) / 100)));
                    } else if (income > 775000 && income < 1500000) {
                        const sur = 0;
                        const E = (b * 4) / 100;
                        if (tax - b < 0) {
                            const T = parseInt(tax);
                            const F = parseInt((tax * 4) / 100);
                            setTaxAtNormal(parseInt(T));
                            setTaxBeforeRebate(parseInt(T));
                            setTaxRebate87A(0);
                            setTaxAfterRebate(parseInt(T));
                            setSurcharge(parseInt(sur));
                            setHealthEducation(parseInt(F));
                            setTotalTaxOnIncome(parseInt(T + F));
                        } else if (tax - b > 0) {
                            setTaxAtNormal(parseInt(tax));
                            setTaxBeforeRebate(parseInt(tax));
                            setTaxRebate87A(parseInt(tax - b));
                            setTaxAfterRebate(parseInt(b));
                            setSurcharge(parseInt(sur));
                            setHealthEducation(parseInt(D));
                            setTotalTaxOnIncome(parseInt(D + b));
                        }
                    } else if (income >= 1500000 && income <= 5000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(0);
                        setHealthEducation(parseInt(Education_Cess));
                        setTotalTaxOnIncome(parseInt(Education_Cess + tax));
                    } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(parseInt(Surcharge));
                        setHealthEducation(parseInt(sur));
                        setTotalTaxOnIncome(parseInt(tax + Surcharge + sur));
                    } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(parseInt(diff));
                        setHealthEducation(parseInt(Edu_Cess));
                        setTotalTaxOnIncome(parseInt(tax + Edu_Cess + diff));
                    }
                }
                else if (income > 10000000 && income < 20000000) {
                    // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
                    if (income > 10000000 && income < 20050000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    let diff = 0;
                    let sur = 0;
                    let edu_cess1 = 0;
                    if (income > 10000000 && income <= 10050000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = tax * 10 / 100
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);

                    }
                    else if (income > 10050000 && income <= 10060000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 10060000 && income <= 10110000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 10110000 && income < 20000000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }

                }
                else if (income >= 20000000 && income < 50000000) {
                    if (income >= 20000000 && income <= 50000000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;

                    }

                    let diff = 0;
                    let sur = 0;
                    let edu_cess1 = 0;
                    if (income >= 20000000 && income <= 20050000) {
                        diff = income - 20000000 - (tax - 855000);
                        sur = tax * 15 / 100
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);

                    }
                    else if (income > 20050000 && income <= 20060000) {
                        const C = (income - 50000) - 20000000
                        const A = 5700000 + (C * 30 / 100)
                        const B = A * 25 / 100;
                        const D = C - (A - 5700000)
                        const sss = (5700000 * 15 / 100) + D
                        edu_cess1 = (tax + sss) * 4 / 100
                        const T = parseInt(tax);
                        const S = parseInt(sss);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 20060000 && income <= 20110000) {
                        const C = (income - 50000) - 20000000
                        const A = 5700000 + (C * 30 / 100)
                        const B = A * 25 / 100;
                        const D = C - (A - 5700000)
                        const sss = (5700000 * 15 / 100) + D
                        edu_cess1 = (tax + sss) * 4 / 100
                        const T = parseInt(tax);
                        const S = parseInt(sss);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 20110000 && income < 50000000) {
                        const T = parseInt(tax);
                        const sss = T * 25 / 100;
                        const S = parseInt(sss);
                        edu_cess1 = (T + S) * 4 / 100
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(T);
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                }
                else if (income >= 50000000) {
                    let edu_cess1 = 0;
                    if (income >= 50000000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    const T = parseInt(tax);
                    const sss = T * 25 / 100;
                    const S = parseInt(sss);
                    edu_cess1 = (T + S) * 4 / 100
                    const E1 = parseInt(edu_cess1);
                    setTaxAtNormal(T);
                    setTaxBeforeRebate(T);
                    setTaxRebate87A("0");
                    setTaxAfterRebate(T);
                    setSurcharge(S);
                    setHealthEducation(E1);
                    setTotalTaxOnIncome(T + S + E1);
                }
                // if (income > 0 && income <= 10000000) {
                //     if (income > 0 && income < 350000) {
                //         tax = 0;
                //     } else if (income >= 350000 && income <= 650000) {
                //         tax = (income - 300000) * 0.05;
                //     } else if (income > 650000 && income <= 950000) {
                //         tax = 300000 * 0.05 + (income - 600000) * 0.1;
                //     } else if (income > 950000 && income <= 1250000) {
                //         tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
                //     } else if (income > 1250000 && income <= 1550000) {
                //         tax =
                //             300000 * 0.05 +
                //             300000 * 0.1 +
                //             300000 * 0.15 +
                //             (income - 1200000) * 0.2;
                //     } else {
                //         tax =
                //             300000 * 0.05 +
                //             300000 * 0.1 +
                //             300000 * 0.15 +
                //             300000 * 0.2 +
                //             (income - 1500000) * 0.3;
                //     }
                //     // debugger
                //     const diff = income - 5000000 - (tax - 1200000);
                //     const diff1 = income - 1500000 - (tax - 750000);
                //     const y = Math.abs(diff1) - 50000;
                //     let D = 0;
                //     D = ((tax - y) * 4) / 100;
                //     let Surcharge = 0;
                //     let Edu_Cess = 0;
                //     let sur = 0;
                //     Surcharge = (tax * 10) / 100;
                //     Edu_Cess = ((tax + diff) * 4) / 100;
                //     sur = ((tax + Surcharge) * 4) / 100;
                //     let Education_Cess = 0;
                //     Education_Cess = (tax * 4) / 100;
                //     const Totalpaytax = tax + Education_Cess;
                //     let b = 0;
                //     b = income - 750000;
                //     let c = 0;

                //     // console.log(tax)
                //     // console.log(income)
                //     c = income;
                //     let tttt = 300000 * 0.05 + (c - 600000) * 0.1
                //     // console.log(tttt)
                //     // console.log(aaaaa)
                //     // console.log(c)

                //     if (c > 1 && c <= 700000) {
                //         // console.log("hi")
                //         setTaxAtNormal(parseInt(tax));
                //         setTaxBeforeRebate(parseInt(tax));
                //         setTaxRebate87A(parseInt(tax));
                //         setTaxAfterRebate(0);
                //         setSurcharge(0);
                //         setHealthEducation(parseInt(0));
                //         setTotalTaxOnIncome(0);
                //     }

                //     else if(income > 700000 && income <= 750000)
                //     {

                //         setTaxAtNormal(parseInt(tax));
                //         setTaxBeforeRebate(parseInt(tax));
                //         setTaxRebate87A(0);
                //         setTaxAfterRebate(parseInt(tax));
                //         setSurcharge(0);
                //         setHealthEducation(parseInt((tax * 4) / 100));
                //         setTotalTaxOnIncome(parseInt(tax + (tax * 4) / 100));
                //     } 

                //     else if (income > 750000 && income <= 775000) {
                //         setTaxAtNormal(parseInt(tax));
                //         setTaxBeforeRebate(parseInt(tax));
                //         setTaxRebate87A(parseInt(tax - b));
                //         setTaxAfterRebate(parseInt(b));
                //         setSurcharge(0);
                //         setHealthEducation(parseInt((b * 4) / 100));
                //         setTotalTaxOnIncome(parseInt(b + (b * 4) / 100));
                //     } else if (income > 775000 && income < 1500000) {
                //         const sur = 0;
                //         const E = (b * 4) / 100;
                //         if (tax - b < 0) {
                //             const T = parseInt(tax);
                //             const F = parseInt((tax * 4) / 100);
                //             setTaxAtNormal(parseInt(T));
                //             setTaxBeforeRebate(parseInt(T));
                //             setTaxRebate87A(0);
                //             setTaxAfterRebate(parseInt(T));
                //             setSurcharge(parseInt(sur));
                //             setHealthEducation(parseInt(F));
                //             setTotalTaxOnIncome(parseInt(T + F));
                //         } else if (tax - b > 0) {
                //             setTaxAtNormal(parseInt(tax));
                //             setTaxBeforeRebate(parseInt(tax));
                //             setTaxRebate87A(parseInt(tax - b));
                //             setTaxAfterRebate(parseInt(b));
                //             setSurcharge(parseInt(sur));
                //             setHealthEducation(parseInt(D));
                //             setTotalTaxOnIncome(parseInt(D + b));
                //         }
                //     } else if (income >= 1500000 && income <= 5000000) {
                //         setTaxAtNormal(parseInt(tax));
                //         setTaxBeforeRebate(parseInt(tax));
                //         setTaxRebate87A(0);
                //         setTaxAfterRebate(parseInt(tax));
                //         setSurcharge(0);
                //         setHealthEducation(parseInt(Education_Cess));
                //         setTotalTaxOnIncome(parseInt(Education_Cess + tax));
                //     } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
                //         setTaxAtNormal(parseInt(tax));
                //         setTaxBeforeRebate(parseInt(tax));
                //         setTaxRebate87A(0);
                //         setTaxAfterRebate(parseInt(tax));
                //         setSurcharge(parseInt(Surcharge));
                //         setHealthEducation(parseInt(sur));
                //         setTotalTaxOnIncome(parseInt(tax + Surcharge + sur));
                //     } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
                //         setTaxAtNormal(parseInt(tax));
                //         setTaxBeforeRebate(parseInt(tax));
                //         setTaxRebate87A(0);
                //         setTaxAfterRebate(parseInt(tax));
                //         setSurcharge(parseInt(diff));
                //         setHealthEducation(parseInt(Edu_Cess));
                //         setTotalTaxOnIncome(parseInt(tax + Edu_Cess + diff));
                //     }
                // }
                // else if (income > 10000000 && income < 20000000) {
                //     // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
                //     if (income > 10000000 && income < 20050000) {
                //         tax =
                //             300000 * 0.05 +
                //             300000 * 0.1 +
                //             300000 * 0.15 +
                //             300000 * 0.2 +
                //             (income - 1500000) * 0.3;
                //     }
                //     let diff = 0;
                //     let sur = 0;
                //     let edu_cess1 = 0;
                //     if (income > 10000000 && income <= 10050000) {
                //         diff = income - 10000000 - (tax - 2700000);
                //         sur = tax * 10 / 100
                //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                //         const T = parseInt(tax);
                //         const S = parseInt(sur);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);

                //     }
                //     else if (income > 10050000 && income <= 10060000) {
                //         diff = income - 10000000 - (tax - 2700000);
                //         sur = (270000 + diff);
                //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                //         const T = parseInt(tax);
                //         const S = parseInt(sur);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);
                //     }
                //     else if (income > 10060000 && income <= 10110000) {
                //         diff = income - 10000000 - (tax - 2700000);
                //         sur = (270000 + diff);
                //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                //         const T = parseInt(tax);
                //         const S = parseInt(sur);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);
                //     }
                //     else if (income > 10110000 && income < 20000000) {
                //         diff = income - 10000000 - (tax - 2700000);
                //         sur = (270000 + diff);
                //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                //         const T = parseInt(tax);
                //         const S = parseInt(sur);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);
                //     }

                // }
                // else if (income >= 20000000 && income < 50000000) {
                //     if (income >= 20000000 && income <= 50000000) {
                //         tax =
                //             300000 * 0.05 +
                //             300000 * 0.1 +
                //             300000 * 0.15 +
                //             300000 * 0.2 +
                //             (income - 1500000) * 0.3;

                //     }

                //     let diff = 0;
                //     let sur = 0;
                //     let edu_cess1 = 0;
                //     if (income >= 20000000 && income <= 20050000) {
                //         diff = income - 20000000 - (tax - 855000);
                //         sur = tax * 15 / 100
                //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                //         const T = parseInt(tax);
                //         const S = parseInt(sur);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);

                //     }
                //     else if (income > 20050000 && income <= 20060000) {
                //         const C = (income - 50000) - 20000000
                //         const A = 5700000 + (C * 30 / 100)
                //         const B = A * 25 / 100;
                //         const D = C - (A - 5700000)
                //         const sss = (5700000 * 15 / 100) + D
                //         edu_cess1 = (tax + sss) * 4 / 100
                //         const T = parseInt(tax);
                //         const S = parseInt(sss);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);
                //     }
                //     else if (income > 20060000 && income <= 20110000) {
                //         const C = (income - 50000) - 20000000
                //         const A = 5700000 + (C * 30 / 100)
                //         const B = A * 25 / 100;
                //         const D = C - (A - 5700000)
                //         const sss = (5700000 * 15 / 100) + D
                //         edu_cess1 = (tax + sss) * 4 / 100
                //         const T = parseInt(tax);
                //         const S = parseInt(sss);
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(parseInt(T));
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);
                //     }
                //     else if (income > 20110000 && income < 50000000) {
                //         const T = parseInt(tax);
                //         const sss = T * 25 / 100;
                //         const S = parseInt(sss);
                //         edu_cess1 = (T + S) * 4 / 100
                //         const E1 = parseInt(edu_cess1);
                //         setTaxAtNormal(T);
                //         setTaxBeforeRebate(T);
                //         setTaxRebate87A("0");
                //         setTaxAfterRebate(T);
                //         setSurcharge(S);
                //         setHealthEducation(E1);
                //         setTotalTaxOnIncome(T + S + E1);
                //     }
                // }
                // else if (income >= 50000000) {
                //     let edu_cess1 = 0;
                //     if (income >= 50000000) {
                //         tax =
                //             300000 * 0.05 +
                //             300000 * 0.1 +
                //             300000 * 0.15 +
                //             300000 * 0.2 +
                //             (income - 1500000) * 0.3;
                //     }
                //     const T = parseInt(tax);
                //     const sss = T * 25 / 100;
                //     const S = parseInt(sss);
                //     edu_cess1 = (T + S) * 4 / 100
                //     const E1 = parseInt(edu_cess1);
                //     setTaxAtNormal(T);
                //     setTaxBeforeRebate(T);
                //     setTaxRebate87A("0");
                //     setTaxAfterRebate(T);
                //     setSurcharge(S);
                //     setHealthEducation(E1);
                //     setTotalTaxOnIncome(T + S + E1);
                // }
            }

            else if (ageCategory == "80") {
                //   debugger  
                if (income > 0 && income <= 10000000) {
                    if (income > 0 && income <= 350000) {
                        tax = 0;
                    } else if (income > 350000 && income <= 600000) {
                        tax = (income - 300000) * 0.05;
                    } else if (income > 600000 && income <= 900000) {
                        tax = 300000 * 0.05 + (income - 600000) * 0.1;
                    } else if (income > 900000 && income <= 1200000) {
                        tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
                    }
                    else if (income > 1200000 && income <= 1500000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            (income - 1200000) * 0.2;
                    } else {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    //  console.log(tax)
                    // debugger
                    const diff = income - 5000000 - (tax - 1200000);
                    const diff1 = income - 1500000 - (tax - 750000);
                    const y = Math.abs(diff1) - 50000;
                    let D = 0;
                    D = ((tax - y) * 4) / 100;
                    let Surcharge = 0;
                    let Edu_Cess = 0;
                    let sur = 0;
                    Surcharge = (tax * 10) / 100;
                    Edu_Cess = ((tax + diff) * 4) / 100;
                    sur = ((tax + Surcharge) * 4) / 100;
                    let Education_Cess = 0;
                    Education_Cess = (tax * 4) / 100;
                    const Totalpaytax = tax + Education_Cess;
                    let b = 0;
                    b = income - 750000;
                    let c = 0;

                    // console.log(tax)
                    // console.log(income)
                    c = income - 50000;
                    let tttt = 300000 * 0.05 + (c - 600000) * 0.1
                    // console.log(tax)
                    // console.log(tttt)
                    // console.log(aaaaa)
                    // console.log(tax-10000)
                    // const A=  (income - 300000) * 0.05;
                    // console.log(income)
                    let tx = 0;
                    tx = tax - 10000
                    // console.log(tax)
                    // debugger
                    //    console.log(income)
                    if (income > 1 && income <= 500019) {
                        //    console.log("hi")
                        setTaxAtNormal(parseInt(0));
                        setTaxBeforeRebate(parseInt(0));
                        setTaxRebate87A(parseInt(0));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));
                    }

                    else if (income > 500019 && income <= 600000) {
                        // console.log(income)
                        // console.log("yes")
                        setTaxAtNormal(parseInt(tx));
                        setTaxBeforeRebate(parseInt(tx));
                        setTaxRebate87A(parseInt(tx));
                        setTaxAfterRebate(0);
                        setSurcharge(0);
                        setHealthEducation(parseInt(0));

                    }

                    else if (income > 600000 && income <= 750000) {

                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(0);
                        setHealthEducation(parseInt((tax * 4) / 100));
                        setTotalTaxOnIncome(parseInt(tax + (tax * 4) / 100));
                    }

                    else if (income > 750000 && income <= 775000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(parseInt(tax - b));
                        setTaxAfterRebate(parseInt(b));
                        setSurcharge(0);
                        setHealthEducation(parseInt((b * 4) / 100));
                        setTotalTaxOnIncome(parseInt(b + (b * 4) / 100));
                    } else if (income > 775000 && income < 1500000) {
                        // console.log("income",income)
                        // console.log("tax",tax)
                        const sur = 0;
                        const E = (b * 4) / 100;
                        if (tax - b < 0) {
                            const T = parseInt(tax);
                            const F = parseInt((tax * 4) / 100);
                            setTaxAtNormal(parseInt(T));
                            setTaxBeforeRebate(parseInt(T));
                            setTaxRebate87A(0);
                            setTaxAfterRebate(parseInt(T));
                            setSurcharge(parseInt(sur));
                            setHealthEducation(parseInt(F));
                            setTotalTaxOnIncome(parseInt(T + F));
                        } else if (tax - b > 0) {
                            setTaxAtNormal(parseInt(tax));
                            setTaxBeforeRebate(parseInt(tax));
                            setTaxRebate87A(parseInt(tax - b));
                            setTaxAfterRebate(parseInt(b));
                            setSurcharge(parseInt(sur));
                            setHealthEducation(parseInt(D));
                            setTotalTaxOnIncome(parseInt(D + b));
                        }
                    } else if (income >= 1500000 && income <= 5000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(0);
                        setHealthEducation(parseInt(Education_Cess));
                        setTotalTaxOnIncome(parseInt(Education_Cess + tax));
                    } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(parseInt(Surcharge));
                        setHealthEducation(Math.round(sur));
                        setTotalTaxOnIncome(parseInt(tax + Surcharge + Math.round(sur)));
                    } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
                        setTaxAtNormal(parseInt(tax));
                        setTaxBeforeRebate(parseInt(tax));
                        setTaxRebate87A(0);
                        setTaxAfterRebate(parseInt(tax));
                        setSurcharge(Math.round(diff));
                        setHealthEducation(parseInt(Edu_Cess));
                        setTotalTaxOnIncome(parseInt(tax + Edu_Cess + Math.round(diff)));
                    }
                }
                else if (income > 10000000 && income < 20000000) {
                    // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
                    if (income > 10000000 && income < 20050000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    let diff = 0;
                    let sur = 0;
                    let edu_cess1 = 0;
                    if (income > 10000000 && income <= 10050000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = tax * 10 / 100
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);

                    }
                    else if (income > 10050000 && income <= 10060000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 10060000 && income <= 10110000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 10110000 && income < 20000000) {
                        diff = income - 10000000 - (tax - 2700000);
                        sur = (270000 + diff);
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }

                }
                else if (income >= 20000000 && income < 50000000) {
                    if (income >= 20000000 && income <= 50000000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;

                    }

                    let diff = 0;
                    let sur = 0;
                    let edu_cess1 = 0;
                    if (income >= 20000000 && income <= 20050000) {
                        diff = income - 20000000 - (tax - 855000);
                        sur = tax * 15 / 100
                        edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
                        const T = parseInt(tax);
                        const S = parseInt(sur);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);

                    }
                    else if (income > 20050000 && income <= 20060000) {
                        const C = (income - 50000) - 20000000
                        const A = 5700000 + (C * 30 / 100)
                        const B = A * 25 / 100;
                        const D = C - (A - 5700000)
                        const sss = (5700000 * 15 / 100) + D
                        edu_cess1 = (tax + sss) * 4 / 100
                        const T = parseInt(tax);
                        const S = parseInt(sss);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 20060000 && income <= 20110000) {
                        const C = (income - 50000) - 20000000
                        const A = 5700000 + (C * 30 / 100)
                        const B = A * 25 / 100;
                        const D = C - (A - 5700000)
                        const sss = (5700000 * 15 / 100) + D
                        edu_cess1 = (tax + sss) * 4 / 100
                        const T = parseInt(tax);
                        const S = parseInt(sss);
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(parseInt(T));
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                    else if (income > 20110000 && income < 50000000) {
                        const T = parseInt(tax);
                        const sss = T * 25 / 100;
                        const S = parseInt(sss);
                        edu_cess1 = (T + S) * 4 / 100
                        const E1 = parseInt(edu_cess1);
                        setTaxAtNormal(T);
                        setTaxBeforeRebate(T);
                        setTaxRebate87A("0");
                        setTaxAfterRebate(T);
                        setSurcharge(S);
                        setHealthEducation(E1);
                        setTotalTaxOnIncome(T + S + E1);
                    }
                }
                else if (income >= 50000000) {
                    let edu_cess1 = 0;
                    if (income >= 50000000) {
                        tax =
                            300000 * 0.05 +
                            300000 * 0.1 +
                            300000 * 0.15 +
                            300000 * 0.2 +
                            (income - 1500000) * 0.3;
                    }
                    const T = parseInt(tax);
                    const sss = T * 25 / 100;
                    const S = parseInt(sss);
                    edu_cess1 = (T + S) * 4 / 100
                    const E1 = parseInt(edu_cess1);
                    setTaxAtNormal(T);
                    setTaxBeforeRebate(T);
                    setTaxRebate87A("0");
                    setTaxAfterRebate(T);
                    setSurcharge(S);
                    setHealthEducation(E1);
                    setTotalTaxOnIncome(T + S + E1);
                }
            }

        }
    };

    const oldRegimCalculator = () => {

        if (businessImcome > 0
            && incomeVal == 0
            && incomefromInterest == 0
            && rentalIncomeReceived == 0
            && incomeFromDigitalAssets == 0
            && shortTermCapitalGain==0
            && otherIncome == 0
            && interestOnHomeLoanSelfOccupied == 0
            && interestOnHomeLoanLetOut == 0
            && otherInterest == 0
        ) {
            let Surcharge = 0;
            let HE = 0;
            let difference = 0;
            let taxableSum = businessImcome;
            let tax = 0;
            if (taxableSum > 0 && taxableSum <= 250019) {
                tax = 0;

            }
            else if (taxableSum > 250019 && taxableSum <= 500000) {
                tax = (taxableSum - 250000) * 0.05;

            } else if (taxableSum > 500000 && taxableSum <= 1000000) {
                tax = (taxableSum - 500000) * 0.2 + 250000 * 0.05;
            }
            else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

            }
            else if (taxableSum > 5000000) {
                tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

            }
            // console.log(tax)
            // debugger
            if (taxableSum > 0 && taxableSum <= 250019) {
                setOldTaxAtNormal(parseInt(tax));
                setOldTaxBeforeRebate(0);
                setOldTaxRebate87A(0);
                setOldTaxAfterRebate(0);
                setOldSurcharge(0);
                setOldHealthEducation(parseInt(0));
                setOldTotalTaxOnIncome(0);
            }
            else if (taxableSum > 250019 && taxableSum <= 500004) {
                // console.log("hi")
                setOldTaxAtNormal(parseInt(tax));
                setOldTaxBeforeRebate(parseInt(tax));
                setOldTaxRebate87A(parseInt(tax));
                setOldTaxAfterRebate(0);
                setOldSurcharge(0);
                setOldHealthEducation(parseInt(0));
                setOldTotalTaxOnIncome(0);
            }
            else if (taxableSum > 500004 && taxableSum <= 1000003) {
                // console.log("hi")
                const educess = tax * 4 / 100;
                setOldTaxAtNormal(parseInt(tax));
                setOldTaxBeforeRebate(parseInt(tax));
                setOldTaxRebate87A(0);
                setOldTaxAfterRebate(parseInt(tax));
                setOldSurcharge(0);
                setOldHealthEducation(parseInt(educess));
                setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
            }
            else if (taxableSum > 1000003 && taxableSum <= 5000000) {
                const educess = tax * 4 / 100;
                setOldTaxAtNormal(parseInt(tax));
                setOldTaxBeforeRebate(parseInt(tax));
                setOldTaxRebate87A(0);
                setOldTaxAfterRebate(parseInt(tax));
                setOldSurcharge(0);
                setOldHealthEducation(parseInt(educess));
                setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
            }
            else if (taxableSum > 5000000 && taxableSum <= 10000000) {
                let Surcharge = 0;
                Surcharge = (parseInt(tax) * 10) / 100;
                let difference = 0;
                difference = taxableSum - 5000000;
                difference = difference * 70 / 100;

                let edu = 0;

                if (difference < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                    edu = ((tax + difference) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(Math.ceil(difference));
                    setOldHealthEducation(parseInt(edu));
                    setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + Math.ceil(difference));
                }
                else if (difference > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                    edu = ((tax + Surcharge) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(edu));
                    setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(Surcharge));

                }

            }

            else if (taxableSum > 10000000 && taxableSum <= 20000000) {
                let Surcharge = 0;
                let difference = 0;
                let HE = 0;
                if (taxableSum > 10000000 && taxableSum <= 10206105) {
                    Surcharge = (2812500 * 10) / 100;
                    difference = taxableSum - 10000000;
                    difference = difference * 70 / 100;
                    difference = difference + Surcharge;
                    HE = ((tax + difference) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(Math.ceil(difference));
                    setOldHealthEducation(parseInt(HE));
                    setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));



                }
                else if (taxableSum > 10206105 && taxableSum <= 20000000) {
                    Surcharge = (tax * 15) / 100;
                    HE = ((tax + Surcharge) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(HE));
                    setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

                }

            }
            else if (taxableSum > 20000000 && taxableSum <= 50000000) {
                let Surcharge = 0;
                let HE = 0;
                let difference = 0;
                if (taxableSum >= 20000001 && taxableSum <= 20912000) {
                    Surcharge = (5812500 * 15) / 100;
                    difference = taxableSum - 20000000;
                    difference = difference * 70 / 100;
                    difference = difference + Surcharge;
                    HE = ((tax + difference) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(Math.ceil(difference));
                    setOldHealthEducation(parseInt(HE));
                    setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
                }
                else if (taxableSum > 20912000 && taxableSum <= 50000000) {
                    let Surcharge = 0;
                    let HE = 0;
                    // let difference=0;
                    Surcharge = (tax * 25) / 100;
                    HE = ((tax + Surcharge) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(HE));
                    setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));
                }
            }
            else if (taxableSum > 50000000) {
                if (taxableSum > 50000000 && taxableSum <= 53017825) {
                    Surcharge = (14812500 * 25) / 100;
                    difference = taxableSum - 50000000;
                    difference = difference * 70 / 100;
                    difference = difference + Surcharge;
                    let HE = 0;
                    HE = ((tax + difference) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(Math.ceil(difference));
                    setOldHealthEducation(parseInt(HE));
                    setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + Math.ceil(difference));
                }
                else if (taxableSum > 53017825) {
                    Surcharge = (parseInt(tax) * 37) / 100;
                    let HE = 0;
                    HE = ((tax + Surcharge) * 4) / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(HE));
                    setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + parseInt(Surcharge));
                }
            }
        } else {

            let taxableSum = gross;
            let tax = 0;

            if (ageCategory == '60') {
                // debugger

                if (taxableSum > 0 && taxableSum <= 250000) {
                    tax = 0;
                }
                else if (taxableSum > 250000 && taxableSum <= 500000) {
                    tax = (taxableSum - 250000) * 0.05;
                } else if (taxableSum > 500000 && taxableSum <= 1000000)
                    tax = (taxableSum - 500000) * 0.2 + 250000 * 0.05;
                else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                    tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

                }
                else if (taxableSum > 5000000) {
                    tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

                }

                if (taxableSum > 0 && taxableSum <= 250019) {
                    // console.log("hi")

                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(0);
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(0);
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(0));
                    setOldTotalTaxOnIncome(0);
                }
                else if (taxableSum > 250019 && taxableSum <= 500004) {
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(parseInt(tax));
                    setOldTaxAfterRebate(0);
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(0));
                    setOldTotalTaxOnIncome(0);
                }
                else if (taxableSum > 500004 && taxableSum <= 1000003) {
                    console.log("hi")
                    const educess = tax * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
                }
                else if (taxableSum > 1000003 && taxableSum <= 5000000) {
                    const educess = tax * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
                }
                else if (taxableSum > 5000000 && taxableSum <= 10000000) {
                    let Surcharge = 0;
                    Surcharge = (parseInt(tax) * 10) / 100;
                    let difference = 0;
                    difference = taxableSum - 5000000;
                    difference = difference * 70 / 100;

                    let edu = 0;

                    if (difference < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                        edu = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(edu));
                        setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + Math.ceil(difference));
                    }
                    else if (difference > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                        edu = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(edu));
                        setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(Surcharge));

                    }

                }
                else if (taxableSum > 10000000 && taxableSum <= 20000000) {
                    let Surcharge = 0;
                    let difference = 0;
                    let HE = 0;
                    if (taxableSum > 10000000 && taxableSum <= 10206105) {
                        Surcharge = (2812500 * 10) / 100;
                        difference = taxableSum - 10000000;
                        difference = difference * 70 / 100;
                        difference = difference + Surcharge;
                        HE = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));



                    }
                    else if (taxableSum > 10206105 && taxableSum <= 20000000) {
                        Surcharge = (tax * 15) / 100;
                        HE = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

                    }

                }
                else if (taxableSum > 20000000 && taxableSum <= 50000000) {
                    let Surcharge = 0;
                    let HE = 0;
                    let difference = 0;
                    if (taxableSum >= 20000001 && taxableSum <= 20912000) {
                        Surcharge = (5812500 * 15) / 100;
                        difference = taxableSum - 20000000;
                        difference = difference * 70 / 100;
                        difference = difference + Surcharge;
                        HE = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
                    }
                    else if (taxableSum > 20912000 && taxableSum <= 50000000) {
                        let Surcharge = 0;
                        let HE = 0;
                        // let difference=0;
                        Surcharge = (tax * 25) / 100;
                        HE = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));
                    }
                }
                else if (taxableSum > 50000000) {
                    let Surcharge = 0;
                    let difference = 0;
                    if (taxableSum > 50000000 && taxableSum <= 53017825) {

                        Surcharge = (14812500 * 25) / 100;
                        difference = taxableSum - 50000000;
                        difference = difference * 70 / 100;
                        difference = difference + Surcharge;
                        let HE = 0;
                        HE = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + Math.ceil(difference));
                    }
                    else if (taxableSum > 53017825) {
                        Surcharge = (parseInt(tax) * 37) / 100;
                        let HE = 0;
                        HE = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + parseInt(Surcharge));
                    }
                }

            }
            else if (ageCategory == "60-79") {
                // // if (taxableSum > 0 && taxableSum <= 250000) {
                // //     tax = 0;
                // // }
                // // else if (taxableSum > 250000 && taxableSum <= 500000) {
                // //     tax = (taxableSum - 300000) * 0.05;
                // // } else if (taxableSum > 500000 && taxableSum <= 1000000)
                // //     tax = (taxableSum - 500000) * 0.2 + 250000 * 0.04;
                // // else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                // //     // tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.25;
                // //     tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.24;

                // // }
                // // else if (taxableSum > 5000000) {
                // //     tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.24;

                // // }
                // if (taxableSum > 0 && taxableSum <= 250000) {
                //     tax = 0;
                // }
                // else if (taxableSum > 250000 && taxableSum <= 500000) {
                //     tax = (taxableSum - 250000) * 0.05;
                // } else if (taxableSum > 500000 && taxableSum <= 1000000)
                //     tax = (taxableSum - 500000) * 0.2 + 250000 * 0.05;
                // else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                //     tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

                // }
                // else if (taxableSum > 5000000) {
                //     tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

                // }
                if (taxableSum > 0 && taxableSum <= 250000) {
                    tax = 0;
                }
                else if (taxableSum > 250000 && taxableSum <= 500000) {
                    tax = (taxableSum - 250000) * 0.05;
                } else if (taxableSum > 500000 && taxableSum <= 1000000)
                    tax = (taxableSum - 500000) * 0.2 + 250000 * 0.04;
                else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                    tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.04;

                }
                else if (taxableSum > 5000000) {
                    tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.04;

                }
                const aaa = tax - 2500;
                // console.log("ttttt",tax)
                // console.log("tatatata",taxableSum)

                if (taxableSum > 0 && taxableSum <= 300019) {
                    // console.log("hi")

                    setOldTaxAtNormal(parseInt(0));
                    setOldTaxBeforeRebate(0);
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(0);
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(0));
                    setOldTotalTaxOnIncome(0);
                }


                //    else if (taxableSum > 350019 && taxableSum <= 250019) {
                //         // console.log("hi")

                //         setOldTaxAtNormal(parseInt(tax));
                //         setOldTaxBeforeRebate(0);
                //         setOldTaxRebate87A(0);
                //         setOldTaxAfterRebate(0);
                //         setOldSurcharge(0);
                //         setOldHealthEducation(parseInt(0));
                //         setOldTotalTaxOnIncome(0);
                //     }
                else if (taxableSum > 300019 && taxableSum <= 500004) {
                    setOldTaxAtNormal(parseInt(aaa));
                    setOldTaxBeforeRebate(parseInt(aaa));
                    setOldTaxRebate87A(parseInt(aaa));
                    setOldTaxAfterRebate(0);
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(0));
                    setOldTotalTaxOnIncome(0);
                }
                else if (taxableSum > 500004 && taxableSum <= 1000003) {
                    const educess = tax * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
                }
                else if (taxableSum > 1000003 && taxableSum <= 5000000) {
                    const educess = tax * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
                }
                else if (taxableSum > 5000000 && taxableSum <= 10000000) {
                    let Surcharge = 0;
                    Surcharge = (parseInt(tax) * 10) / 100;
                    let difference = 0;
                    difference = taxableSum - 5000000;
                    difference = difference * 70 / 100;

                    let edu = 0;

                    if (difference < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                        edu = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(edu));
                        setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + Math.ceil(difference));
                    }
                    else if (difference > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                        edu = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(edu));
                        setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(Surcharge));

                    }

                }
                else if (taxableSum > 10000000 && taxableSum <= 20000000) {
                    let Surcharge = 0;
                    let difference = 0;
                    let HE = 0;
                    if (taxableSum > 10000000 && taxableSum <= 10206105) {
                        Surcharge = (2812500 * 10) / 100;
                        difference = taxableSum - 10000000;
                        difference = difference * 70 / 100;
                        difference = difference + Surcharge;
                        HE = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));



                    }
                    else if (taxableSum > 10206105 && taxableSum <= 20000000) {
                        Surcharge = (tax * 15) / 100;
                        HE = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

                    }

                }
                else if (taxableSum > 20000000 && taxableSum <= 50000000) {
                    let Surcharge = 0;
                    let HE = 0;
                    let difference = 0;
                    if (taxableSum >= 20000001 && taxableSum <= 20912000) {
                        Surcharge = (5812500 * 15) / 100;
                        difference = taxableSum - 20000000;
                        difference = difference * 70 / 100;
                        difference = difference + Surcharge;
                        HE = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
                    }
                    else if (taxableSum > 20912000 && taxableSum <= 50000000) {
                        let Surcharge = 0;
                        let HE = 0;
                        // let difference=0;
                        Surcharge = (tax * 25) / 100;
                        HE = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));
                    }
                }
                else if (taxableSum > 50000000) {
                    let Surcharge = 0;
                    let difference = 0;
                    if (taxableSum > 50000000 && taxableSum <= 53017825) {

                        Surcharge = (14812500 * 25) / 100;
                        difference = taxableSum - 50000000;
                        difference = difference * 70 / 100;
                        difference = difference + Surcharge;
                        let HE = 0;
                        HE = ((tax + difference) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(Math.ceil(difference));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + Math.ceil(difference));
                    }
                    else if (taxableSum > 53017825) {
                        Surcharge = (parseInt(tax) * 37) / 100;
                        let HE = 0;
                        HE = ((tax + Surcharge) * 4) / 100;
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(HE));
                        setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + parseInt(Surcharge));
                    }
                }

            }

            else if (ageCategory == "80") {
                if (taxableSum > 0 && taxableSum <= 250000) {
                    tax = 0;
                }
                else if (taxableSum > 250000 && taxableSum <= 500000) {
                    tax = (taxableSum - 250000) * 0.05;
                } else if (taxableSum > 500000 && taxableSum <= 1000000)
                    tax = (taxableSum - 500000) * 0.2 + 250000 * 0.0;
                else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                    tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.20;

                }
                else if (taxableSum > 5000000) {
                    tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.20;

                }


                if (taxableSum > 0 && taxableSum <= 500000) {

                    setOldTaxAtNormal(parseInt(0));
                    setOldTaxBeforeRebate(0);
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(0);
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(0));
                    setOldTotalTaxOnIncome(0);
                }
                else if (taxableSum > 500000 && taxableSum <= 500124) {

                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(parseInt(tax));
                    setOldTaxAfterRebate(0);
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(0));
                    setOldTotalTaxOnIncome(0);
                }
                else if (taxableSum > 500124 && taxableSum <= 550000) {
                    const educess = tax * 4 / 100;

                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(tax + educess));
                }
                else if (taxableSum > 550000 && taxableSum <= 1000000) {
                    const educess = tax * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(tax + educess));
                }
                else if (taxableSum > 1000000 && taxableSum <= 5000000) {
                    const educess = tax * 4 / 100;

                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(0);
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
                }

                else if (taxableSum > 5000000 && taxableSum <= 10000000) {
                    // console.log("hi")
                    let S = 0;
                    S = taxableSum - 5000000;
                    let Surcharge = 0;
                    Surcharge = tax * 10 / 100;
                    const sur = S * 70 / 100;
                    const edu = (tax + sur) * 4 / 100;
                    const edu1 = (tax + Surcharge) * 4 / 100;
                    const ssssssssss = (taxableSum - 5000000)
                    // console.log(ssssssssss)
                    console.log(Surcharge)
                    if (S < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                        // console.log("hi")

                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(ssssssssss));
                        setOldHealthEducation(parseInt(edu));
                        setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(ssssssssss));
                    }
                    else if (S > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
                        // console.log("bye")
                        setOldTaxAtNormal(parseInt(tax));
                        setOldTaxBeforeRebate(parseInt(tax));
                        setOldTaxRebate87A(0);
                        setOldTaxAfterRebate(parseInt(tax));
                        setOldSurcharge(parseInt(Surcharge));
                        setOldHealthEducation(parseInt(edu1));
                        setOldTotalTaxOnIncome(parseInt(edu1) + parseInt(tax) + parseInt(Surcharge));

                    }

                }

                else if (taxableSum > 10000000 && taxableSum <= 20000000) {
                    let Surcharge = 0;
                    Surcharge = tax * 15 / 100;
                    let educess = 0;
                    educess = (tax + Surcharge) * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax) + parseInt(Surcharge));
                }
                else if (taxableSum > 20000000 && taxableSum <= 50000000) {
                    let Surcharge = 0;
                    Surcharge = tax * 25 / 100;
                    let educess = 0;
                    educess = (tax + Surcharge) * 4 / 100;
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax) + parseInt(Surcharge));
                }
                else if (taxableSum > 50000000) {

                    let Surcharge = 0;
                    Surcharge = tax * 37 / 100;
                    let educess = 0;
                    educess = (tax + Surcharge) * 4 / 100;
                    // console.log(Surcharge)
                    setOldTaxAtNormal(parseInt(tax));
                    setOldTaxBeforeRebate(parseInt(tax));
                    setOldTaxRebate87A(0);
                    setOldTaxAfterRebate(parseInt(tax));
                    setOldSurcharge(parseInt(Surcharge));
                    setOldHealthEducation(parseInt(educess));
                    setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax) + parseInt(Surcharge));
                }



            }



        }

    };
    // const calculateIncomeTax = () => {

    //     if (businessImcome > 0
    //         && incomeVal == 0
    //         && incomefromInterest == 0
    //         && rentalIncomeReceived == 0
    //         && incomeFromDigitalAssets == 0
    //         && otherIncome == 0
    //         && interestOnHomeLoanSelfOccupied == 0
    //         && interestOnHomeLoanLetOut == 0
    //         && otherInterest == 0
    //     ) {

    //         let tax = 0
    //         clearState();
    //         let income = businessImcome;
    //         if (income <= 300000) {
    //             tax = 0;
    //         } else if (income > 300000 && income <= 600000) {
    //             tax = (income - 300000) * 0.05;
    //         } else if (income > 600001 && income <= 900000) {
    //             tax = 300000 * 0.05 + (income - 600000) * 0.1;
    //         } else if (income > 900001 && income <= 1200000) {
    //             tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
    //         } else if (income > 1200001 && income <= 1500000) {
    //             tax =
    //                 300000 * 0.05 +
    //                 300000 * 0.1 +
    //                 300000 * 0.15 +
    //                 (income - 1200000) * 0.2;
    //         } else {
    //             tax =
    //                 300000 * 0.05 +
    //                 300000 * 0.1 +
    //                 300000 * 0.15 +
    //                 300000 * 0.2 +
    //                 (income - 1500000) * 0.3;
    //         }
    //         if (income > 0 && income <= 700000) {
    //             setTaxAtNormal(parseInt(tax));
    //             setTaxBeforeRebate(parseInt(tax));
    //             setTaxRebate87A(parseInt(tax));
    //             setTaxAfterRebate(0);
    //             setSurcharge(0);
    //             setHealthEducation(0);
    //             setTotalTaxOnIncome(0);


    //         } else if (income > 700000 && income <= 10000000) {
    //             if (income > 700000 && income <= 727776) {
    //                 let diff = 0;
    //                 diff = income - 700000;
    //                 let A = 0;
    //                 A = tax - diff
    //                 let H = 0;
    //                 H = diff * 4 / 100;
    //                 let N = 0;
    //                 N = diff + H;
    //                 setTaxAtNormal(parseInt(tax));
    //                 setTaxBeforeRebate(parseInt(tax));
    //                 setTaxRebate87A(parseInt(A));
    //                 setTaxAfterRebate(parseInt(diff));
    //                 setSurcharge(0);
    //                 setHealthEducation(parseInt(H));
    //                 setTotalTaxOnIncome(parseInt(N));

    //             } else if (income > 727776 && income <= 5000000) {
    //                 let HE = 0;
    //                 HE = tax * 4 / 100;
    //                 setTaxAtNormal(parseInt(tax));
    //                 setTaxBeforeRebate(parseInt(tax));
    //                 setTaxRebate87A(0);
    //                 setTaxAfterRebate(parseInt(tax));
    //                 setSurcharge(0);
    //                 setHealthEducation(parseInt(HE));
    //                 setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE));

    //             } else if (income > 5000000 && income <= 10000000) {
    //                 let difference = 0;
    //                 difference = income - 5000000;
    //                 difference = difference * 70 / 100
    //                 let HE = 0;
    //                 let Surcharge = 0;
    //                 Surcharge = (parseInt(tax) * 10) / 100;
    //                 if (difference < Surcharge && income >= 5000001 && income <= 5179103) {
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(Math.ceil(difference));
    //                     setHealthEducation(parseInt(HE));
    //                     setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));

    //                 } else if (difference > Surcharge && income > 5179103 && income <= 10000000) {
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(parseInt(Surcharge));
    //                     setHealthEducation(parseInt(HE));
    //                     setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.floor(Surcharge)));
    //                 }
    //             }

    //         }
    //         else if (income > 10000000 && income <= 20000000) {

    //             let Surcharge = 0;
    //             let difference = 0;
    //             let HE = 0;
    //             if (income > 10000000 && income <= 10206105) {
    //                 Surcharge = (2700000 * 10) / 100;
    //                 difference = income - 10000000;
    //                 difference = difference * 70 / 100;
    //                 difference = difference + Surcharge;
    //                 HE = ((tax + difference) * 4) / 100;
    //                 setTaxAtNormal(parseInt(tax));
    //                 setTaxBeforeRebate(parseInt(tax));
    //                 setTaxRebate87A(0);
    //                 setTaxAfterRebate(parseInt(tax));
    //                 setSurcharge(Math.ceil(difference));
    //                 setHealthEducation(parseInt(HE));
    //                 setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
    //             }
    //             else if (income > 10206105 && income <= 20000000) {
    //                 Surcharge = (tax * 15) / 100;
    //                 HE = ((tax + Surcharge) * 4) / 100;
    //                 setTaxAtNormal(parseInt(tax));
    //                 setTaxBeforeRebate(parseInt(tax));
    //                 setTaxRebate87A(0);
    //                 setTaxAfterRebate(parseInt(tax));
    //                 setSurcharge(Math.ceil(Surcharge));
    //                 setHealthEducation(parseInt(HE));
    //                 setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

    //             }
    //         }

    //         else if (income > 20000000) {
    //             let Surcharge = 0;
    //             let HE = 0;
    //             let difference = 0;
    //             if (income >= 20000001 && income <= 20912000) {
    //                 Surcharge = (5700000 * 15) / 100;
    //                 difference = income - 20000000;
    //                 difference = difference * 70 / 100;
    //                 difference = difference + Surcharge;
    //                 HE = ((tax + difference) * 4) / 100;

    //                 setTaxAtNormal(parseInt(tax));
    //                 setTaxBeforeRebate(parseInt(tax));
    //                 setTaxRebate87A(0);
    //                 setTaxAfterRebate(parseInt(tax));
    //                 setSurcharge(Math.ceil(difference));
    //                 setHealthEducation(parseInt(HE));
    //                 setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));

    //             }
    //             else if (income > 20912000) {
    //                 let Surcharge = 0;
    //                 let HE = 0;
    //                 // let difference=0;
    //                 Surcharge = (tax * 25) / 100;
    //                 HE = ((tax + Surcharge) * 4) / 100;
    //                 setTaxAtNormal(parseInt(tax));
    //                 setTaxBeforeRebate(parseInt(tax));
    //                 setTaxRebate87A(0);
    //                 setTaxAfterRebate(parseInt(tax));
    //                 setSurcharge(parseInt(Surcharge));
    //                 setHealthEducation(parseInt(HE));
    //                 setTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

    //             }
    //         }

    //         // End bussiness calculation
    //     } else {
    //         clearState();
    //         let tax = 0;
    //         const income = displayValue;
    //         if (ageCategory == '60') {

    //             // debugger
    //             if (income > 0 && income <= 10000000) {
    //                 if (income > 0 && income < 350000) {
    //                     tax = 0;
    //                 } else if (income >= 350000 && income <= 650000) {
    //                     tax = (income - 300000) * 0.05;
    //                 } else if (income > 650000 && income <= 900000) {
    //                     tax = 300000 * 0.05 + (income - 600000) * 0.1;
    //                 } else if (income > 900000 && income <= 1250000) {
    //                     tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
    //                 } else if (income > 1250000 && income <= 1550000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         (income - 1200000) * 0.2;
    //                 } else {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 // debugger
    //                 const diff = income - 5000000 - (tax - 1200000);
    //                 const diff1 = income - 1500000 - (tax - 750000);
    //                 const y = Math.abs(diff1) - 50000;
    //                 let D = 0;
    //                 D = ((tax - y) * 4) / 100;
    //                 let Surcharge = 0;
    //                 let Edu_Cess = 0;
    //                 let sur = 0;
    //                 Surcharge = (tax * 10) / 100;
    //                 Edu_Cess = ((tax + diff) * 4) / 100;
    //                 sur = ((tax + Surcharge) * 4) / 100;
    //                 let Education_Cess = 0;
    //                 Education_Cess = (tax * 4) / 100;
    //                 const Totalpaytax = tax + Education_Cess;
    //                 let b = 0;
    //                 b = income - 750000;
    //                 let c = 0;

    //                 c = income;
    //                 let tttt = 300000 * 0.05 + (c - 600000) * 0.1
    //                 const aaaaaa = income - 700000
    //                 console.log(income)
    //                 const ooo = tax - aaaaaa
    //                 if (c > 1 && c <= 600009) {
    //                     // console.log("hi")
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(tax));
    //                     setTaxAfterRebate(0);
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(0));
    //                     setTotalTaxOnIncome(0);
    //                 }

    //                 else if (c > 600009 && c <= 700000) {
    //                     console.log(income)
    //                     setTaxAtNormal(parseInt(tttt));
    //                     setTaxBeforeRebate(parseInt(tttt));
    //                     setTaxRebate87A(parseInt(tttt));
    //                     setTaxAfterRebate(0);
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(0));
    //                     setTotalTaxOnIncome(0);
    //                 }

    //                 else if (income > 700000 && income <= 700024) {


    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(ooo));
    //                     setTaxAfterRebate(parseInt(aaaaaa));
    //                     setSurcharge(0);
    //                     setHealthEducation(0);
    //                     setTotalTaxOnIncome(parseInt(aaaaaa));
    //                 }
    //                 else if (income > 700024 && income <= 727777) {


    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(ooo));
    //                     setTaxAfterRebate(Math.round(aaaaaa));
    //                     setSurcharge(0);
    //                     setHealthEducation(Math.round(aaaaaa * 4 / 100));
    //                     setTotalTaxOnIncome(parseInt(aaaaaa + Math.round(aaaaaa * 4 / 100)));
    //                 }

    //                 else if (income > 727777 && income <= 775000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt((tax * 4) / 100));
    //                     setTotalTaxOnIncome(parseInt(parseInt(tax) + (parseInt(tax * 4) / 100)));
    //                 } else if (income > 775000 && income < 1500000) {
    //                     const sur = 0;
    //                     const E = (b * 4) / 100;
    //                     if (tax - b < 0) {
    //                         const T = parseInt(tax);
    //                         const F = parseInt((tax * 4) / 100);
    //                         setTaxAtNormal(parseInt(T));
    //                         setTaxBeforeRebate(parseInt(T));
    //                         setTaxRebate87A(0);
    //                         setTaxAfterRebate(parseInt(T));
    //                         setSurcharge(parseInt(sur));
    //                         setHealthEducation(parseInt(F));
    //                         setTotalTaxOnIncome(parseInt(T + F));
    //                     } else if (tax - b > 0) {
    //                         setTaxAtNormal(parseInt(tax));
    //                         setTaxBeforeRebate(parseInt(tax));
    //                         setTaxRebate87A(parseInt(tax - b));
    //                         setTaxAfterRebate(parseInt(b));
    //                         setSurcharge(parseInt(sur));
    //                         setHealthEducation(parseInt(D));
    //                         setTotalTaxOnIncome(parseInt(D + b));
    //                     }
    //                 } else if (income >= 1500000 && income <= 5000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(Education_Cess));
    //                     setTotalTaxOnIncome(parseInt(Education_Cess + tax));
    //                 } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(parseInt(Surcharge));
    //                     setHealthEducation(parseInt(sur));
    //                     setTotalTaxOnIncome(parseInt(tax + Surcharge + sur));
    //                 } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(parseInt(diff));
    //                     setHealthEducation(parseInt(Edu_Cess));
    //                     setTotalTaxOnIncome(parseInt(tax + Edu_Cess + diff));
    //                 }
    //             }
    //             else if (income > 10000000 && income < 20000000) {
    //                 // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
    //                 if (income > 10000000 && income < 20050000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 let diff = 0;
    //                 let sur = 0;
    //                 let edu_cess1 = 0;
    //                 if (income > 10000000 && income <= 10050000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = tax * 10 / 100
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);

    //                 }
    //                 else if (income > 10050000 && income <= 10060000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 10060000 && income <= 10110000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 10110000 && income < 20000000) {

    //                     const sssss = parseInt(tax * 15 / 100)
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sssss + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);

    //                     console.log("sur", tax * 15 / 100)
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(sssss);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + sssss + E1);
    //                 }

    //             }
    //             else if (income >= 20000000 && income < 50000000) {
    //                 if (income >= 20000000 && income <= 50000000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;

    //                 }

    //                 let diff = 0;
    //                 let sur = 0;
    //                 let edu_cess1 = 0;
    //                 if (income >= 20000000 && income <= 20050000) {
    //                     diff = income - 20000000 - (tax - 855000);
    //                     sur = tax * 15 / 100
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);

    //                 }
    //                 else if (income > 20050000 && income <= 20060000) {
    //                     const C = (income - 50000) - 20000000
    //                     const A = 5700000 + (C * 30 / 100)
    //                     const B = A * 25 / 100;
    //                     const D = C - (A - 5700000)
    //                     const sss = (5700000 * 15 / 100) + D
    //                     edu_cess1 = (tax + sss) * 4 / 100
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sss);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 20060000 && income <= 20110000) {
    //                     const C = (income - 50000) - 20000000
    //                     const A = 5700000 + (C * 30 / 100)
    //                     const B = A * 25 / 100;
    //                     const D = C - (A - 5700000)
    //                     const sss = (5700000 * 15 / 100) + D
    //                     edu_cess1 = (tax + sss) * 4 / 100
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sss);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 20110000 && income < 50000000) {
    //                     const T = parseInt(tax);
    //                     const sss = T * 25 / 100;
    //                     const S = parseInt(sss);
    //                     edu_cess1 = (T + S) * 4 / 100
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(T);
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //             }
    //             else if (income >= 50000000) {
    //                 let edu_cess1 = 0;
    //                 if (income >= 50000000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 const T = parseInt(tax);
    //                 const sss = T * 25 / 100;
    //                 // console.log(T * 30 / 100)
    //                 const S = parseInt(sss);
    //                 edu_cess1 = (T + S) * 4 / 100
    //                 const E1 = parseInt(edu_cess1);
    //                 setTaxAtNormal(T);
    //                 setTaxBeforeRebate(T);
    //                 setTaxRebate87A("0");
    //                 setTaxAfterRebate(T);
    //                 setSurcharge(S);
    //                 setHealthEducation(E1);
    //                 setTotalTaxOnIncome(T + S + E1);
    //             }
    //         } else if (ageCategory == "60-79") {

    //             if (income > 0 && income <= 10000000) {
    //                 if (income > 0 && income < 350000) {
    //                     tax = 0;
    //                 } else if (income >= 350000 && income <= 650000) {
    //                     tax = (income - 300000) * 0.05;
    //                 } else if (income > 650000 && income <= 900000) {
    //                     tax = 300000 * 0.05 + (income - 600000) * 0.1;
    //                 } else if (income > 900000 && income <= 1250000) {
    //                     tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
    //                 } else if (income > 1250000 && income <= 1550000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         (income - 1200000) * 0.2;
    //                 } else {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 // debugger
    //                 const diff = income - 5000000 - (tax - 1200000);
    //                 const diff1 = income - 1500000 - (tax - 750000);
    //                 const y = Math.abs(diff1) - 50000;
    //                 let D = 0;
    //                 D = ((tax - y) * 4) / 100;
    //                 let Surcharge = 0;
    //                 let Edu_Cess = 0;
    //                 let sur = 0;
    //                 Surcharge = (tax * 10) / 100;
    //                 Edu_Cess = ((tax + diff) * 4) / 100;
    //                 sur = ((tax + Surcharge) * 4) / 100;
    //                 let Education_Cess = 0;
    //                 Education_Cess = (tax * 4) / 100;
    //                 const Totalpaytax = tax + Education_Cess;
    //                 let b = 0;
    //                 b = income - 750000;
    //                 let c = 0;

    //                 c = income;
    //                 let tttt = 300000 * 0.05 + (c - 600000) * 0.1
    //                 const aaaaaa = income - 700000
    //                 console.log(income)
    //                 const ooo = tax - aaaaaa
    //                 if (c > 1 && c <= 600009) {
    //                     // console.log("hi")
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(tax));
    //                     setTaxAfterRebate(0);
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(0));
    //                     setTotalTaxOnIncome(0);
    //                 }

    //                 else if (c > 600009 && c <= 700000) {
    //                     console.log(income)
    //                     setTaxAtNormal(parseInt(tttt));
    //                     setTaxBeforeRebate(parseInt(tttt));
    //                     setTaxRebate87A(parseInt(tttt));
    //                     setTaxAfterRebate(0);
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(0));
    //                     setTotalTaxOnIncome(0);
    //                 }

    //                 else if (income > 700000 && income <= 700024) {


    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(ooo));
    //                     setTaxAfterRebate(parseInt(aaaaaa));
    //                     setSurcharge(0);
    //                     setHealthEducation(0);
    //                     setTotalTaxOnIncome(parseInt(aaaaaa));
    //                 }
    //                 else if (income > 700024 && income <= 727777) {


    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(ooo));
    //                     setTaxAfterRebate(Math.round(aaaaaa));
    //                     setSurcharge(0);
    //                     setHealthEducation(Math.round(aaaaaa * 4 / 100));
    //                     setTotalTaxOnIncome(parseInt(aaaaaa + Math.round(aaaaaa * 4 / 100)));
    //                 }

    //                 else if (income > 727777 && income <= 775000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt((tax * 4) / 100));
    //                     setTotalTaxOnIncome(parseInt(parseInt(tax) + (parseInt(tax * 4) / 100)));
    //                 } else if (income > 775000 && income < 1500000) {
    //                     const sur = 0;
    //                     const E = (b * 4) / 100;
    //                     if (tax - b < 0) {
    //                         const T = parseInt(tax);
    //                         const F = parseInt((tax * 4) / 100);
    //                         setTaxAtNormal(parseInt(T));
    //                         setTaxBeforeRebate(parseInt(T));
    //                         setTaxRebate87A(0);
    //                         setTaxAfterRebate(parseInt(T));
    //                         setSurcharge(parseInt(sur));
    //                         setHealthEducation(parseInt(F));
    //                         setTotalTaxOnIncome(parseInt(T + F));
    //                     } else if (tax - b > 0) {
    //                         setTaxAtNormal(parseInt(tax));
    //                         setTaxBeforeRebate(parseInt(tax));
    //                         setTaxRebate87A(parseInt(tax - b));
    //                         setTaxAfterRebate(parseInt(b));
    //                         setSurcharge(parseInt(sur));
    //                         setHealthEducation(parseInt(D));
    //                         setTotalTaxOnIncome(parseInt(D + b));
    //                     }
    //                 } else if (income >= 1500000 && income <= 5000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(Education_Cess));
    //                     setTotalTaxOnIncome(parseInt(Education_Cess + tax));
    //                 } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(parseInt(Surcharge));
    //                     setHealthEducation(parseInt(sur));
    //                     setTotalTaxOnIncome(parseInt(tax + Surcharge + sur));
    //                 } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(parseInt(diff));
    //                     setHealthEducation(parseInt(Edu_Cess));
    //                     setTotalTaxOnIncome(parseInt(tax + Edu_Cess + diff));
    //                 }
    //             }
    //             else if (income > 10000000 && income < 20000000) {
    //                 // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
    //                 if (income > 10000000 && income < 20050000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 let diff = 0;
    //                 let sur = 0;
    //                 let edu_cess1 = 0;
    //                 if (income > 10000000 && income <= 10050000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = tax * 10 / 100
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);

    //                 }
    //                 else if (income > 10050000 && income <= 10060000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 10060000 && income <= 10110000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 10110000 && income < 20000000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }

    //             }
    //             else if (income >= 20000000 && income < 50000000) {
    //                 if (income >= 20000000 && income <= 50000000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;

    //                 }

    //                 let diff = 0;
    //                 let sur = 0;
    //                 let edu_cess1 = 0;
    //                 if (income >= 20000000 && income <= 20050000) {
    //                     diff = income - 20000000 - (tax - 855000);
    //                     sur = tax * 15 / 100
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);

    //                 }
    //                 else if (income > 20050000 && income <= 20060000) {
    //                     const C = (income - 50000) - 20000000
    //                     const A = 5700000 + (C * 30 / 100)
    //                     const B = A * 25 / 100;
    //                     const D = C - (A - 5700000)
    //                     const sss = (5700000 * 15 / 100) + D
    //                     edu_cess1 = (tax + sss) * 4 / 100
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sss);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 20060000 && income <= 20110000) {
    //                     const C = (income - 50000) - 20000000
    //                     const A = 5700000 + (C * 30 / 100)
    //                     const B = A * 25 / 100;
    //                     const D = C - (A - 5700000)
    //                     const sss = (5700000 * 15 / 100) + D
    //                     edu_cess1 = (tax + sss) * 4 / 100
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sss);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 20110000 && income < 50000000) {
    //                     const T = parseInt(tax);
    //                     const sss = T * 25 / 100;
    //                     const S = parseInt(sss);
    //                     edu_cess1 = (T + S) * 4 / 100
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(T);
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //             }
    //             else if (income >= 50000000) {
    //                 let edu_cess1 = 0;
    //                 if (income >= 50000000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 const T = parseInt(tax);
    //                 const sss = T * 25 / 100;
    //                 const S = parseInt(sss);
    //                 edu_cess1 = (T + S) * 4 / 100
    //                 const E1 = parseInt(edu_cess1);
    //                 setTaxAtNormal(T);
    //                 setTaxBeforeRebate(T);
    //                 setTaxRebate87A("0");
    //                 setTaxAfterRebate(T);
    //                 setSurcharge(S);
    //                 setHealthEducation(E1);
    //                 setTotalTaxOnIncome(T + S + E1);
    //             }
    //             // if (income > 0 && income <= 10000000) {
    //             //     if (income > 0 && income < 350000) {
    //             //         tax = 0;
    //             //     } else if (income >= 350000 && income <= 650000) {
    //             //         tax = (income - 300000) * 0.05;
    //             //     } else if (income > 650000 && income <= 950000) {
    //             //         tax = 300000 * 0.05 + (income - 600000) * 0.1;
    //             //     } else if (income > 950000 && income <= 1250000) {
    //             //         tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
    //             //     } else if (income > 1250000 && income <= 1550000) {
    //             //         tax =
    //             //             300000 * 0.05 +
    //             //             300000 * 0.1 +
    //             //             300000 * 0.15 +
    //             //             (income - 1200000) * 0.2;
    //             //     } else {
    //             //         tax =
    //             //             300000 * 0.05 +
    //             //             300000 * 0.1 +
    //             //             300000 * 0.15 +
    //             //             300000 * 0.2 +
    //             //             (income - 1500000) * 0.3;
    //             //     }
    //             //     // debugger
    //             //     const diff = income - 5000000 - (tax - 1200000);
    //             //     const diff1 = income - 1500000 - (tax - 750000);
    //             //     const y = Math.abs(diff1) - 50000;
    //             //     let D = 0;
    //             //     D = ((tax - y) * 4) / 100;
    //             //     let Surcharge = 0;
    //             //     let Edu_Cess = 0;
    //             //     let sur = 0;
    //             //     Surcharge = (tax * 10) / 100;
    //             //     Edu_Cess = ((tax + diff) * 4) / 100;
    //             //     sur = ((tax + Surcharge) * 4) / 100;
    //             //     let Education_Cess = 0;
    //             //     Education_Cess = (tax * 4) / 100;
    //             //     const Totalpaytax = tax + Education_Cess;
    //             //     let b = 0;
    //             //     b = income - 750000;
    //             //     let c = 0;

    //             //     // console.log(tax)
    //             //     // console.log(income)
    //             //     c = income;
    //             //     let tttt = 300000 * 0.05 + (c - 600000) * 0.1
    //             //     // console.log(tttt)
    //             //     // console.log(aaaaa)
    //             //     // console.log(c)

    //             //     if (c > 1 && c <= 700000) {
    //             //         // console.log("hi")
    //             //         setTaxAtNormal(parseInt(tax));
    //             //         setTaxBeforeRebate(parseInt(tax));
    //             //         setTaxRebate87A(parseInt(tax));
    //             //         setTaxAfterRebate(0);
    //             //         setSurcharge(0);
    //             //         setHealthEducation(parseInt(0));
    //             //         setTotalTaxOnIncome(0);
    //             //     }

    //             //     else if(income > 700000 && income <= 750000)
    //             //     {

    //             //         setTaxAtNormal(parseInt(tax));
    //             //         setTaxBeforeRebate(parseInt(tax));
    //             //         setTaxRebate87A(0);
    //             //         setTaxAfterRebate(parseInt(tax));
    //             //         setSurcharge(0);
    //             //         setHealthEducation(parseInt((tax * 4) / 100));
    //             //         setTotalTaxOnIncome(parseInt(tax + (tax * 4) / 100));
    //             //     } 

    //             //     else if (income > 750000 && income <= 775000) {
    //             //         setTaxAtNormal(parseInt(tax));
    //             //         setTaxBeforeRebate(parseInt(tax));
    //             //         setTaxRebate87A(parseInt(tax - b));
    //             //         setTaxAfterRebate(parseInt(b));
    //             //         setSurcharge(0);
    //             //         setHealthEducation(parseInt((b * 4) / 100));
    //             //         setTotalTaxOnIncome(parseInt(b + (b * 4) / 100));
    //             //     } else if (income > 775000 && income < 1500000) {
    //             //         const sur = 0;
    //             //         const E = (b * 4) / 100;
    //             //         if (tax - b < 0) {
    //             //             const T = parseInt(tax);
    //             //             const F = parseInt((tax * 4) / 100);
    //             //             setTaxAtNormal(parseInt(T));
    //             //             setTaxBeforeRebate(parseInt(T));
    //             //             setTaxRebate87A(0);
    //             //             setTaxAfterRebate(parseInt(T));
    //             //             setSurcharge(parseInt(sur));
    //             //             setHealthEducation(parseInt(F));
    //             //             setTotalTaxOnIncome(parseInt(T + F));
    //             //         } else if (tax - b > 0) {
    //             //             setTaxAtNormal(parseInt(tax));
    //             //             setTaxBeforeRebate(parseInt(tax));
    //             //             setTaxRebate87A(parseInt(tax - b));
    //             //             setTaxAfterRebate(parseInt(b));
    //             //             setSurcharge(parseInt(sur));
    //             //             setHealthEducation(parseInt(D));
    //             //             setTotalTaxOnIncome(parseInt(D + b));
    //             //         }
    //             //     } else if (income >= 1500000 && income <= 5000000) {
    //             //         setTaxAtNormal(parseInt(tax));
    //             //         setTaxBeforeRebate(parseInt(tax));
    //             //         setTaxRebate87A(0);
    //             //         setTaxAfterRebate(parseInt(tax));
    //             //         setSurcharge(0);
    //             //         setHealthEducation(parseInt(Education_Cess));
    //             //         setTotalTaxOnIncome(parseInt(Education_Cess + tax));
    //             //     } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
    //             //         setTaxAtNormal(parseInt(tax));
    //             //         setTaxBeforeRebate(parseInt(tax));
    //             //         setTaxRebate87A(0);
    //             //         setTaxAfterRebate(parseInt(tax));
    //             //         setSurcharge(parseInt(Surcharge));
    //             //         setHealthEducation(parseInt(sur));
    //             //         setTotalTaxOnIncome(parseInt(tax + Surcharge + sur));
    //             //     } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
    //             //         setTaxAtNormal(parseInt(tax));
    //             //         setTaxBeforeRebate(parseInt(tax));
    //             //         setTaxRebate87A(0);
    //             //         setTaxAfterRebate(parseInt(tax));
    //             //         setSurcharge(parseInt(diff));
    //             //         setHealthEducation(parseInt(Edu_Cess));
    //             //         setTotalTaxOnIncome(parseInt(tax + Edu_Cess + diff));
    //             //     }
    //             // }
    //             // else if (income > 10000000 && income < 20000000) {
    //             //     // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
    //             //     if (income > 10000000 && income < 20050000) {
    //             //         tax =
    //             //             300000 * 0.05 +
    //             //             300000 * 0.1 +
    //             //             300000 * 0.15 +
    //             //             300000 * 0.2 +
    //             //             (income - 1500000) * 0.3;
    //             //     }
    //             //     let diff = 0;
    //             //     let sur = 0;
    //             //     let edu_cess1 = 0;
    //             //     if (income > 10000000 && income <= 10050000) {
    //             //         diff = income - 10000000 - (tax - 2700000);
    //             //         sur = tax * 10 / 100
    //             //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sur);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);

    //             //     }
    //             //     else if (income > 10050000 && income <= 10060000) {
    //             //         diff = income - 10000000 - (tax - 2700000);
    //             //         sur = (270000 + diff);
    //             //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sur);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);
    //             //     }
    //             //     else if (income > 10060000 && income <= 10110000) {
    //             //         diff = income - 10000000 - (tax - 2700000);
    //             //         sur = (270000 + diff);
    //             //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sur);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);
    //             //     }
    //             //     else if (income > 10110000 && income < 20000000) {
    //             //         diff = income - 10000000 - (tax - 2700000);
    //             //         sur = (270000 + diff);
    //             //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sur);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);
    //             //     }

    //             // }
    //             // else if (income >= 20000000 && income < 50000000) {
    //             //     if (income >= 20000000 && income <= 50000000) {
    //             //         tax =
    //             //             300000 * 0.05 +
    //             //             300000 * 0.1 +
    //             //             300000 * 0.15 +
    //             //             300000 * 0.2 +
    //             //             (income - 1500000) * 0.3;

    //             //     }

    //             //     let diff = 0;
    //             //     let sur = 0;
    //             //     let edu_cess1 = 0;
    //             //     if (income >= 20000000 && income <= 20050000) {
    //             //         diff = income - 20000000 - (tax - 855000);
    //             //         sur = tax * 15 / 100
    //             //         edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sur);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);

    //             //     }
    //             //     else if (income > 20050000 && income <= 20060000) {
    //             //         const C = (income - 50000) - 20000000
    //             //         const A = 5700000 + (C * 30 / 100)
    //             //         const B = A * 25 / 100;
    //             //         const D = C - (A - 5700000)
    //             //         const sss = (5700000 * 15 / 100) + D
    //             //         edu_cess1 = (tax + sss) * 4 / 100
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sss);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);
    //             //     }
    //             //     else if (income > 20060000 && income <= 20110000) {
    //             //         const C = (income - 50000) - 20000000
    //             //         const A = 5700000 + (C * 30 / 100)
    //             //         const B = A * 25 / 100;
    //             //         const D = C - (A - 5700000)
    //             //         const sss = (5700000 * 15 / 100) + D
    //             //         edu_cess1 = (tax + sss) * 4 / 100
    //             //         const T = parseInt(tax);
    //             //         const S = parseInt(sss);
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(parseInt(T));
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);
    //             //     }
    //             //     else if (income > 20110000 && income < 50000000) {
    //             //         const T = parseInt(tax);
    //             //         const sss = T * 25 / 100;
    //             //         const S = parseInt(sss);
    //             //         edu_cess1 = (T + S) * 4 / 100
    //             //         const E1 = parseInt(edu_cess1);
    //             //         setTaxAtNormal(T);
    //             //         setTaxBeforeRebate(T);
    //             //         setTaxRebate87A("0");
    //             //         setTaxAfterRebate(T);
    //             //         setSurcharge(S);
    //             //         setHealthEducation(E1);
    //             //         setTotalTaxOnIncome(T + S + E1);
    //             //     }
    //             // }
    //             // else if (income >= 50000000) {
    //             //     let edu_cess1 = 0;
    //             //     if (income >= 50000000) {
    //             //         tax =
    //             //             300000 * 0.05 +
    //             //             300000 * 0.1 +
    //             //             300000 * 0.15 +
    //             //             300000 * 0.2 +
    //             //             (income - 1500000) * 0.3;
    //             //     }
    //             //     const T = parseInt(tax);
    //             //     const sss = T * 25 / 100;
    //             //     const S = parseInt(sss);
    //             //     edu_cess1 = (T + S) * 4 / 100
    //             //     const E1 = parseInt(edu_cess1);
    //             //     setTaxAtNormal(T);
    //             //     setTaxBeforeRebate(T);
    //             //     setTaxRebate87A("0");
    //             //     setTaxAfterRebate(T);
    //             //     setSurcharge(S);
    //             //     setHealthEducation(E1);
    //             //     setTotalTaxOnIncome(T + S + E1);
    //             // }
    //         }

    //         else if (ageCategory == "80") {
    //             //   debugger  
    //             if (income > 0 && income <= 10000000) {
    //                 if (income > 0 && income <= 350000) {
    //                     tax = 0;
    //                 } else if (income > 350000 && income <= 600000) {
    //                     tax = (income - 300000) * 0.05;
    //                 } else if (income > 600000 && income <= 900000) {
    //                     tax = 300000 * 0.05 + (income - 600000) * 0.1;
    //                 } else if (income > 900000 && income <= 1250000) {
    //                     tax = 300000 * 0.05 + 300000 * 0.1 + (income - 900000) * 0.15;
    //                 }
    //                 else if (income > 1250000 && income <= 1550000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         (income - 1200000) * 0.2;
    //                 } else {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 console.log(income)
    //                 // debugger
    //                 const diff = income - 5000000 - (tax - 1200000);
    //                 const diff1 = income - 1500000 - (tax - 750000);
    //                 const y = Math.abs(diff1) - 50000;
    //                 let D = 0;
    //                 D = ((tax - y) * 4) / 100;
    //                 let Surcharge = 0;
    //                 let Edu_Cess = 0;
    //                 let sur = 0;
    //                 Surcharge = (tax * 10) / 100;
    //                 Edu_Cess = ((tax + diff) * 4) / 100;
    //                 sur = ((tax + Surcharge) * 4) / 100;
    //                 let Education_Cess = 0;
    //                 Education_Cess = (tax * 4) / 100;
    //                 const Totalpaytax = tax + Education_Cess;
    //                 let b = 0;
    //                 b = income - 750000;
    //                 let c = 0;

    //                 // console.log(tax)
    //                 // console.log(income)
    //                 c = income - 50000;
    //                 let tttt = 300000 * 0.05 + (c - 600000) * 0.1
    //                 // console.log(tax)
    //                 // console.log(tttt)
    //                 // console.log(aaaaa)
    //                 // console.log(tax-10000)
    //                 // const A=  (income - 300000) * 0.05;
    //                 // console.log(income)
    //                 let tx = 0;
    //                 tx = tax - 10000
    //                 // console.log(tax)
    //                 // debugger
    //                 //    console.log(income)
    //                 if (income > 1 && income <= 500019) {
    //                     //    console.log("hi")
    //                     setTaxAtNormal(parseInt(0));
    //                     setTaxBeforeRebate(parseInt(0));
    //                     setTaxRebate87A(parseInt(0));
    //                     setTaxAfterRebate(0);
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(0));
    //                 }

    //                 else if (income > 500019 && income <= 600000) {
    //                     // console.log(income)
    //                     // console.log("yes")
    //                     setTaxAtNormal(parseInt(tx));
    //                     setTaxBeforeRebate(parseInt(tx));
    //                     setTaxRebate87A(parseInt(tx));
    //                     setTaxAfterRebate(0);
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(0));

    //                 }

    //                 else if (income > 600000 && income <= 750000) {

    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt((tax * 4) / 100));
    //                     setTotalTaxOnIncome(parseInt(tax + (tax * 4) / 100));
    //                 }

    //                 else if (income > 750000 && income <= 775000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(parseInt(tax - b));
    //                     setTaxAfterRebate(parseInt(b));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt((b * 4) / 100));
    //                     setTotalTaxOnIncome(parseInt(b + (b * 4) / 100));
    //                 } else if (income > 775000 && income < 1500000) {
    //                     const sur = 0;
    //                     const E = (b * 4) / 100;
    //                     if (tax - b < 0) {
    //                         const T = parseInt(tax);
    //                         const F = parseInt((tax * 4) / 100);
    //                         setTaxAtNormal(parseInt(T));
    //                         setTaxBeforeRebate(parseInt(T));
    //                         setTaxRebate87A(0);
    //                         setTaxAfterRebate(parseInt(T));
    //                         setSurcharge(parseInt(sur));
    //                         setHealthEducation(parseInt(F));
    //                         setTotalTaxOnIncome(parseInt(T + F));
    //                     } else if (tax - b > 0) {
    //                         setTaxAtNormal(parseInt(tax));
    //                         setTaxBeforeRebate(parseInt(tax));
    //                         setTaxRebate87A(parseInt(tax - b));
    //                         setTaxAfterRebate(parseInt(b));
    //                         setSurcharge(parseInt(sur));
    //                         setHealthEducation(parseInt(D));
    //                         setTotalTaxOnIncome(parseInt(D + b));
    //                     }
    //                 } else if (income >= 1500000 && income <= 5000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(0);
    //                     setHealthEducation(parseInt(Education_Cess));
    //                     setTotalTaxOnIncome(parseInt(Education_Cess + tax));
    //                 } else if (diff > Surcharge && income > 5000000 && income <= 10000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(parseInt(Surcharge));
    //                     setHealthEducation(Math.round(sur));
    //                     setTotalTaxOnIncome(parseInt(tax + Surcharge + Math.round(sur)));
    //                 } else if (diff < Surcharge && income > 5000000 && income <= 10000000) {
    //                     setTaxAtNormal(parseInt(tax));
    //                     setTaxBeforeRebate(parseInt(tax));
    //                     setTaxRebate87A(0);
    //                     setTaxAfterRebate(parseInt(tax));
    //                     setSurcharge(Math.round(diff));
    //                     setHealthEducation(parseInt(Edu_Cess));
    //                     setTotalTaxOnIncome(parseInt(tax + Edu_Cess + Math.round(diff)));
    //                 }
    //             }
    //             else if (income > 10000000 && income < 20000000) {
    //                 // tax = (300000 * 0.05) + (300000 * 0.10) + (300000 * 0.15) + (300000 * 0.20) + ((income - 50000) - 1500000) * 0.30
    //                 if (income > 10000000 && income < 20050000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 let diff = 0;
    //                 let sur = 0;
    //                 let edu_cess1 = 0;
    //                 if (income > 10000000 && income <= 10050000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = tax * 10 / 100
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);

    //                 }
    //                 else if (income > 10050000 && income <= 10060000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 10060000 && income <= 10110000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 10110000 && income < 20000000) {
    //                     diff = income - 10000000 - (tax - 2700000);
    //                     sur = (270000 + diff);
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }

    //             }
    //             else if (income >= 20000000 && income < 50000000) {
    //                 if (income >= 20000000 && income <= 50000000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;

    //                 }

    //                 let diff = 0;
    //                 let sur = 0;
    //                 let edu_cess1 = 0;
    //                 if (income >= 20000000 && income <= 20050000) {
    //                     diff = income - 20000000 - (tax - 855000);
    //                     sur = tax * 15 / 100
    //                     edu_cess1 = ((sur + parseInt(tax)) * 4) / 100;
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sur);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);

    //                 }
    //                 else if (income > 20050000 && income <= 20060000) {
    //                     const C = (income - 50000) - 20000000
    //                     const A = 5700000 + (C * 30 / 100)
    //                     const B = A * 25 / 100;
    //                     const D = C - (A - 5700000)
    //                     const sss = (5700000 * 15 / 100) + D
    //                     edu_cess1 = (tax + sss) * 4 / 100
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sss);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 20060000 && income <= 20110000) {
    //                     const C = (income - 50000) - 20000000
    //                     const A = 5700000 + (C * 30 / 100)
    //                     const B = A * 25 / 100;
    //                     const D = C - (A - 5700000)
    //                     const sss = (5700000 * 15 / 100) + D
    //                     edu_cess1 = (tax + sss) * 4 / 100
    //                     const T = parseInt(tax);
    //                     const S = parseInt(sss);
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(parseInt(T));
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //                 else if (income > 20110000 && income < 50000000) {
    //                     const T = parseInt(tax);
    //                     const sss = T * 25 / 100;
    //                     const S = parseInt(sss);
    //                     edu_cess1 = (T + S) * 4 / 100
    //                     const E1 = parseInt(edu_cess1);
    //                     setTaxAtNormal(T);
    //                     setTaxBeforeRebate(T);
    //                     setTaxRebate87A("0");
    //                     setTaxAfterRebate(T);
    //                     setSurcharge(S);
    //                     setHealthEducation(E1);
    //                     setTotalTaxOnIncome(T + S + E1);
    //                 }
    //             }
    //             else if (income >= 50000000) {
    //                 let edu_cess1 = 0;
    //                 if (income >= 50000000) {
    //                     tax =
    //                         300000 * 0.05 +
    //                         300000 * 0.1 +
    //                         300000 * 0.15 +
    //                         300000 * 0.2 +
    //                         (income - 1500000) * 0.3;
    //                 }
    //                 const T = parseInt(tax);
    //                 const sss = T * 25 / 100;
    //                 const S = parseInt(sss);
    //                 edu_cess1 = (T + S) * 4 / 100
    //                 const E1 = parseInt(edu_cess1);
    //                 setTaxAtNormal(T);
    //                 setTaxBeforeRebate(T);
    //                 setTaxRebate87A("0");
    //                 setTaxAfterRebate(T);
    //                 setSurcharge(S);
    //                 setHealthEducation(E1);
    //                 setTotalTaxOnIncome(T + S + E1);
    //             }
    //         }

    //     }
    // };

    // const oldRegimCalculator = () => {

    //     if (businessImcome > 0
    //         && incomeVal == 0
    //         && incomefromInterest == 0
    //         && rentalIncomeReceived == 0
    //         && incomeFromDigitalAssets == 0
    //         && otherIncome == 0
    //         && interestOnHomeLoanSelfOccupied == 0
    //         && interestOnHomeLoanLetOut == 0
    //         && otherInterest == 0
    //     ) {
    //         let Surcharge = 0;
    //         let HE = 0;
    //         let difference = 0;
    //         let taxableSum = businessImcome;
    //         let tax = 0;
    //         if (taxableSum > 0 && taxableSum <= 250019) {
    //             tax = 0;

    //         }
    //         else if (taxableSum > 250019 && taxableSum <= 500000) {
    //             tax = (taxableSum - 250000) * 0.05;

    //         } else if (taxableSum > 500000 && taxableSum <= 1000000) {
    //             tax = (taxableSum - 500000) * 0.2 + 250000 * 0.05;
    //         }
    //         else if (taxableSum > 1000000 && taxableSum <= 5000000) {
    //             tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

    //         }
    //         else if (taxableSum > 5000000) {
    //             tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

    //         }
    //         // console.log(tax)

    //         if (taxableSum > 0 && taxableSum <= 250019) {
    //             setOldTaxAtNormal(parseInt(tax));
    //             setOldTaxBeforeRebate(0);
    //             setOldTaxRebate87A(0);
    //             setOldTaxAfterRebate(0);
    //             setOldSurcharge(0);
    //             setOldHealthEducation(parseInt(0));
    //             setOldTotalTaxOnIncome(0);
    //         }
    //         else if (taxableSum > 250019 && taxableSum <= 500004) {
    //             // console.log("hi")
    //             setOldTaxAtNormal(parseInt(tax));
    //             setOldTaxBeforeRebate(parseInt(tax));
    //             setOldTaxRebate87A(parseInt(tax));
    //             setOldTaxAfterRebate(0);
    //             setOldSurcharge(0);
    //             setOldHealthEducation(parseInt(0));
    //             setOldTotalTaxOnIncome(0);
    //         }
    //         else if (taxableSum > 500004 && taxableSum <= 1000003) {
    //             const educess = tax * 4 / 100;
    //             setOldTaxAtNormal(parseInt(tax));
    //             setOldTaxBeforeRebate(parseInt(tax));
    //             setOldTaxRebate87A(0);
    //             setOldTaxAfterRebate(parseInt(tax));
    //             setOldSurcharge(0);
    //             setOldHealthEducation(parseInt(educess));
    //             setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //         }
    //         else if (taxableSum > 1000003 && taxableSum <= 5000000) {
    //             const educess = tax * 4 / 100;
    //             setOldTaxAtNormal(parseInt(tax));
    //             setOldTaxBeforeRebate(parseInt(tax));
    //             setOldTaxRebate87A(0);
    //             setOldTaxAfterRebate(parseInt(tax));
    //             setOldSurcharge(0);
    //             setOldHealthEducation(parseInt(educess));
    //             setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //         }
    //         else if (taxableSum > 5000000 && taxableSum <= 10000000) {
    //             let Surcharge = 0;
    //             Surcharge = (parseInt(tax) * 10) / 100;
    //             let difference = 0;
    //             difference = taxableSum - 5000000;
    //             difference = difference * 70 / 100;

    //             let edu = 0;

    //             if (difference < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                 edu = ((tax + difference) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(Math.ceil(difference));
    //                 setOldHealthEducation(parseInt(edu));
    //                 setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + Math.ceil(difference));
    //             }
    //             else if (difference > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                 edu = ((tax + Surcharge) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(edu));
    //                 setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(Surcharge));

    //             }

    //         }

    //         else if (taxableSum > 10000000 && taxableSum <= 20000000) {
    //             let Surcharge = 0;
    //             let difference = 0;
    //             let HE = 0;
    //             if (taxableSum > 10000000 && taxableSum <= 10206105) {
    //                 Surcharge = (2812500 * 10) / 100;
    //                 difference = taxableSum - 10000000;
    //                 difference = difference * 70 / 100;
    //                 difference = difference + Surcharge;
    //                 HE = ((tax + difference) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(Math.ceil(difference));
    //                 setOldHealthEducation(parseInt(HE));
    //                 setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));



    //             }
    //             else if (taxableSum > 10206105 && taxableSum <= 20000000) {
    //                 Surcharge = (tax * 15) / 100;
    //                 HE = ((tax + Surcharge) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(HE));
    //                 setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

    //             }

    //         }
    //         else if (taxableSum > 20000000 && taxableSum <= 50000000) {
    //             let Surcharge = 0;
    //             let HE = 0;
    //             let difference = 0;
    //             if (taxableSum >= 20000001 && taxableSum <= 20912000) {
    //                 Surcharge = (5812500 * 15) / 100;
    //                 difference = taxableSum - 20000000;
    //                 difference = difference * 70 / 100;
    //                 difference = difference + Surcharge;
    //                 HE = ((tax + difference) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(Math.ceil(difference));
    //                 setOldHealthEducation(parseInt(HE));
    //                 setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
    //             }
    //             else if (taxableSum > 20912000 && taxableSum <= 50000000) {
    //                 let Surcharge = 0;
    //                 let HE = 0;
    //                 // let difference=0;
    //                 Surcharge = (tax * 25) / 100;
    //                 HE = ((tax + Surcharge) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(HE));
    //                 setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));
    //             }
    //         }
    //         else if (taxableSum > 50000000) {
    //             if (taxableSum > 50000000 && taxableSum <= 53017825) {
    //                 Surcharge = (14812500 * 25) / 100;
    //                 difference = taxableSum - 50000000;
    //                 difference = difference * 70 / 100;
    //                 difference = difference + Surcharge;
    //                 let HE = 0;
    //                 HE = ((tax + difference) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(Math.ceil(difference));
    //                 setOldHealthEducation(parseInt(HE));
    //                 setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + Math.ceil(difference));
    //             }
    //             else if (taxableSum > 53017825) {
    //                 Surcharge = (parseInt(tax) * 37) / 100;
    //                 let HE = 0;
    //                 HE = ((tax + Surcharge) * 4) / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(HE));
    //                 setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + parseInt(Surcharge));
    //             }
    //         }
    //     } else {

    //         let taxableSum = gross;
    //         let tax = 0;

    //         if (ageCategory == '60') {
    //             // debugger

    //             if (taxableSum > 0 && taxableSum <= 250000) {
    //                 tax = 0;
    //             }
    //             else if (taxableSum > 250000 && taxableSum <= 500000) {
    //                 tax = (taxableSum - 250000) * 0.05;
    //             } else if (taxableSum > 500000 && taxableSum <= 1000000)
    //                 tax = (taxableSum - 500000) * 0.2 + 250000 * 0.05;
    //             else if (taxableSum > 1000000 && taxableSum <= 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

    //             }
    //             else if (taxableSum > 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

    //             }

    //             if (taxableSum > 0 && taxableSum <= 250019) {
    //                 // console.log("hi")

    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(0);
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(0);
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 250019 && taxableSum <= 500004) {
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(parseInt(tax));
    //                 setOldTaxAfterRebate(0);
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 500004 && taxableSum <= 1000003) {
    //                 const educess = tax * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //             }
    //             else if (taxableSum > 1000003 && taxableSum <= 5000000) {
    //                 const educess = tax * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //             }
    //             else if (taxableSum > 5000000 && taxableSum <= 10000000) {
    //                 let Surcharge = 0;
    //                 Surcharge = (parseInt(tax) * 10) / 100;
    //                 let difference = 0;
    //                 difference = taxableSum - 5000000;
    //                 difference = difference * 70 / 100;

    //                 let edu = 0;

    //                 if (difference < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                     edu = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(edu));
    //                     setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + Math.ceil(difference));
    //                 }
    //                 else if (difference > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                     edu = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(edu));
    //                     setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(Surcharge));

    //                 }

    //             }
    //             else if (taxableSum > 10000000 && taxableSum <= 20000000) {
    //                 let Surcharge = 0;
    //                 let difference = 0;
    //                 let HE = 0;
    //                 if (taxableSum > 10000000 && taxableSum <= 10206105) {
    //                     Surcharge = (2812500 * 10) / 100;
    //                     difference = taxableSum - 10000000;
    //                     difference = difference * 70 / 100;
    //                     difference = difference + Surcharge;
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));



    //                 }
    //                 else if (taxableSum > 10206105 && taxableSum <= 20000000) {
    //                     Surcharge = (tax * 15) / 100;
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

    //                 }

    //             }
    //             else if (taxableSum > 20000000 && taxableSum <= 50000000) {
    //                 let Surcharge = 0;
    //                 let HE = 0;
    //                 let difference = 0;
    //                 if (taxableSum >= 20000001 && taxableSum <= 20912000) {
    //                     Surcharge = (5812500 * 15) / 100;
    //                     difference = taxableSum - 20000000;
    //                     difference = difference * 70 / 100;
    //                     difference = difference + Surcharge;
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
    //                 }
    //                 else if (taxableSum > 20912000 && taxableSum <= 50000000) {
    //                     let Surcharge = 0;
    //                     let HE = 0;
    //                     // let difference=0;
    //                     Surcharge = (tax * 25) / 100;
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));
    //                 }
    //             }
    //             else if (taxableSum > 50000000) {
    //                 let Surcharge = 0;
    //                 let difference = 0;
    //                 if (taxableSum > 50000000 && taxableSum <= 53017825) {

    //                     Surcharge = (14812500 * 25) / 100;
    //                     difference = taxableSum - 50000000;
    //                     difference = difference * 70 / 100;
    //                     difference = difference + Surcharge;
    //                     let HE = 0;
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + Math.ceil(difference));
    //                 }
    //                 else if (taxableSum > 53017825) {
    //                     Surcharge = (parseInt(tax) * 37) / 100;
    //                     let HE = 0;
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + parseInt(Surcharge));
    //                 }
    //             }

    //         }
    //         else if (ageCategory == "60-79") {
    //             if (taxableSum > 0 && taxableSum <= 250000) {
    //                 tax = 0;
    //             }
    //             else if (taxableSum > 250000 && taxableSum <= 500000) {
    //                 tax = (taxableSum - 300000) * 0.05;
    //             } else if (taxableSum > 500000 && taxableSum <= 1000000)
    //                 tax = (taxableSum - 500000) * 0.2 + 250000 * 0.04;
    //             else if (taxableSum > 1000000 && taxableSum <= 5000000) {
    //                 // tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.25;
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.24;

    //             }
    //             else if (taxableSum > 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.24;

    //             }
    //             if (taxableSum > 0 && taxableSum <= 250000) {
    //                 tax = 0;
    //             }
    //             else if (taxableSum > 250000 && taxableSum <= 500000) {
    //                 tax = (taxableSum - 250000) * 0.05;
    //             } else if (taxableSum > 500000 && taxableSum <= 1000000)
    //                 tax = (taxableSum - 500000) * 0.2 + 250000 * 0.05;
    //             else if (taxableSum > 1000000 && taxableSum <= 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

    //             }
    //             else if (taxableSum > 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.2 + 250000 * 0.05;

    //             }

    //             if (taxableSum > 0 && taxableSum <= 250019) {
    //                 // console.log("hi")

    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(0);
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(0);
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 250019 && taxableSum <= 500004) {
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(parseInt(tax));
    //                 setOldTaxAfterRebate(0);
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 500004 && taxableSum <= 1000003) {
    //                 const educess = tax * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //             }
    //             else if (taxableSum > 1000003 && taxableSum <= 5000000) {
    //                 const educess = tax * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //             }
    //             else if (taxableSum > 5000000 && taxableSum <= 10000000) {
    //                 let Surcharge = 0;
    //                 Surcharge = (parseInt(tax) * 10) / 100;
    //                 let difference = 0;
    //                 difference = taxableSum - 5000000;
    //                 difference = difference * 70 / 100;

    //                 let edu = 0;

    //                 if (difference < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                     edu = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(edu));
    //                     setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + Math.ceil(difference));
    //                 }
    //                 else if (difference > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                     edu = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(edu));
    //                     setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(Surcharge));

    //                 }

    //             }
    //             else if (taxableSum > 10000000 && taxableSum <= 20000000) {
    //                 let Surcharge = 0;
    //                 let difference = 0;
    //                 let HE = 0;
    //                 if (taxableSum > 10000000 && taxableSum <= 10206105) {
    //                     Surcharge = (2812500 * 10) / 100;
    //                     difference = taxableSum - 10000000;
    //                     difference = difference * 70 / 100;
    //                     difference = difference + Surcharge;
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));



    //                 }
    //                 else if (taxableSum > 10206105 && taxableSum <= 20000000) {
    //                     Surcharge = (tax * 15) / 100;
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));

    //                 }

    //             }
    //             else if (taxableSum > 20000000 && taxableSum <= 50000000) {
    //                 let Surcharge = 0;
    //                 let HE = 0;
    //                 let difference = 0;
    //                 if (taxableSum >= 20000001 && taxableSum <= 20912000) {
    //                     Surcharge = (5812500 * 15) / 100;
    //                     difference = taxableSum - 20000000;
    //                     difference = difference * 70 / 100;
    //                     difference = difference + Surcharge;
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + Math.ceil(difference)));
    //                 }
    //                 else if (taxableSum > 20912000 && taxableSum <= 50000000) {
    //                     let Surcharge = 0;
    //                     let HE = 0;
    //                     // let difference=0;
    //                     Surcharge = (tax * 25) / 100;
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(Math.floor(tax) + HE + parseInt(Surcharge)));
    //                 }
    //             }
    //             else if (taxableSum > 50000000) {
    //                 let Surcharge = 0;
    //                 let difference = 0;
    //                 if (taxableSum > 50000000 && taxableSum <= 53017825) {

    //                     Surcharge = (14812500 * 25) / 100;
    //                     difference = taxableSum - 50000000;
    //                     difference = difference * 70 / 100;
    //                     difference = difference + Surcharge;
    //                     let HE = 0;
    //                     HE = ((tax + difference) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(Math.ceil(difference));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + Math.ceil(difference));
    //                 }
    //                 else if (taxableSum > 53017825) {
    //                     Surcharge = (parseInt(tax) * 37) / 100;
    //                     let HE = 0;
    //                     HE = ((tax + Surcharge) * 4) / 100;
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(HE));
    //                     setOldTotalTaxOnIncome(parseInt(HE) + parseInt(tax) + parseInt(Surcharge));
    //                 }
    //             }

    //         }

    //         else if (ageCategory == "80") {
    //             if (taxableSum > 0 && taxableSum <= 250000) {
    //                 tax = 0;
    //             }
    //             else if (taxableSum > 250000 && taxableSum <= 500000) {
    //                 tax = (taxableSum - 250000) * 0.05;
    //             } else if (taxableSum > 500000 && taxableSum <= 1000000)
    //                 tax = (taxableSum - 500000) * 0.2 + 250000 * 0.0;
    //             else if (taxableSum > 1000000 && taxableSum <= 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.20;

    //             }
    //             else if (taxableSum > 5000000) {
    //                 tax = (taxableSum - 1000000) * 0.3 + 500000 * 0.1 + 250000 * 0.20;

    //             }


    //             if (taxableSum > 0 && taxableSum <= 250000) {

    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(0);
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(0);
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 250000 && taxableSum <= 500000) {

    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(parseInt(tax));
    //                 setOldTaxAfterRebate(0);
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 500000 && taxableSum <= 550000) {
    //                 const educess = tax * 4 / 100;

    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(0));
    //                 setOldTotalTaxOnIncome(0);
    //             }
    //             else if (taxableSum > 550000 && taxableSum <= 1000000) {
    //                 const educess = tax * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(tax + educess));
    //             }
    //             else if (taxableSum > 1000000 && taxableSum <= 5000000) {
    //                 const educess = tax * 4 / 100;

    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(0);
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax));
    //             }

    //             else if (taxableSum > 5000000 && taxableSum <= 10000000) {
    //                 let S = 0;
    //                 S = taxableSum - 5000000;
    //                 let Surcharge = 0;
    //                 Surcharge = tax * 10 / 100;
    //                 const sur = S * 70 / 100;
    //                 const edu = (tax + sur) * 4 / 100;
    //                 const edu1 = (tax + Surcharge) * 4 / 100;
    //                 if (S < Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {

    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(sur));
    //                     setOldHealthEducation(parseInt(edu));
    //                     setOldTotalTaxOnIncome(parseInt(edu) + parseInt(tax) + parseInt(sur));
    //                 }
    //                 else if (S > Surcharge && taxableSum > 5000000 && taxableSum <= 10000000) {
    //                     setOldTaxAtNormal(parseInt(tax));
    //                     setOldTaxBeforeRebate(parseInt(tax));
    //                     setOldTaxRebate87A(0);
    //                     setOldTaxAfterRebate(parseInt(tax));
    //                     setOldSurcharge(parseInt(Surcharge));
    //                     setOldHealthEducation(parseInt(edu1));
    //                     setOldTotalTaxOnIncome(parseInt(edu1) + parseInt(tax) + parseInt(Surcharge));

    //                 }

    //             }

    //             else if (taxableSum > 10000000 && taxableSum <= 20000000) {
    //                 let Surcharge = 0;
    //                 Surcharge = tax * 15 / 100;
    //                 let educess = 0;
    //                 educess = (tax + Surcharge) * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax) + parseInt(Surcharge));
    //             }
    //             else if (taxableSum > 20000000 && taxableSum <= 50000000) {
    //                 let Surcharge = 0;
    //                 Surcharge = tax * 25 / 100;
    //                 let educess = 0;
    //                 educess = (tax + Surcharge) * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax) + parseInt(Surcharge));
    //             }
    //             else if (taxableSum > 50000000) {
    //                 let Surcharge = 0;
    //                 Surcharge = tax * 37 / 100;
    //                 let educess = 0;
    //                 educess = (tax + Surcharge) * 4 / 100;
    //                 setOldTaxAtNormal(parseInt(tax));
    //                 setOldTaxBeforeRebate(parseInt(tax));
    //                 setOldTaxRebate87A(0);
    //                 setOldTaxAfterRebate(parseInt(tax));
    //                 setOldSurcharge(parseInt(Surcharge));
    //                 setOldHealthEducation(parseInt(educess));
    //                 setOldTotalTaxOnIncome(parseInt(educess) + parseInt(tax) + parseInt(Surcharge));
    //             }



    //         }



    //     }

    // };

    return (
        <div>
            <Tabs/>
        <div className="custome-row" >
            <div className="container-fluid">
                <div class="path-text mt-3" style={{marginLeft:40}}>
                     <span className="ta-cursor" onClick={() => {
                        navigate("/")
                    }}>Calculator</span> &gt; <span style={{color:"#0073bb"}}>IncomeTax Calculator</span>
                </div>
                {/* <div class="mt-4 mb-3 fd-cal-text">IncomeTax Calculator</div> */}
                <div className="row" style={{marginLeft:40}}>
                    <div className="col-lg-7 col-12">
                        <div className=" background">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div class="pt-3">
                                        <label for="Select" class="form-label"><strong>Age Category</strong></label>
                                        <select value={ageCategory} onChange={(e) => {
                                            setAgeCategory(e.target.value)
                                            setTimeout(() => {
                                                if (e.target.value == 60) {
                                                    if (parseInt(incomefromInterest) > 10000) {
                                                        setD80TTA(10000);
                                                    } else {
                                                        setD80TTA(incomefromInterest);
                                                    }
                                                } else if (e.target.value != 60) {
                                                    if (parseInt(incomefromInterest) > 50000) {
                                                        setD80TTA(50000);
                                                    } else {
                                                        setD80TTA(incomefromInterest);
                                                    }
                                                }
                                            }, 500);

                                        }} id="Select" class="form-select">
                                            <option value='60'>Below 60 years (Regular Citizen)</option>
                                            <option value='60-79'>Between 60 - 79 years (Senior Citizen)</option>
                                            <option value='80'>80 and above (Super Senior Citizen)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div class="pt-3">
                                        <label for="Select" class="form-label"><strong>Financial Year</strong></label>
                                        <select id="Select" class="form-select"
                                            onChange={(e) => {
                                                if (e.target.value?.split("-")[0] >= "2024") {
                                                    setNewRegime(false);
                                                    setExemptAllowances(0)
                                                    setInterestOnHomeLoanSelfOccupied(0)
                                                    setInterestOnHomeLoanLetOut(0)
                                                } else {
                                                    setNewRegime(false)
                                                }
                                            }}
                                        >
                                            <option value="2024-2025">2024-2025</option>
                                            <option value="2023-2024">2023-2024</option>
                                            <option value="2022-2023">2022-2023</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div class="pt-3">
                                        <label for="Select" class="form-label"><strong>Residential Status</strong></label>
                                        <select id="Select" class="form-select">
                                            <option>RES (Resident)</option>
                                            {/* <option>RNOR (Resident But Not Ordinarily Resident)</option> */}
                                            <option> NR (Non Resident)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion mt-4" id="IncomeaccordionExample1">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="IncomeheadingOne">
                                        <button
                                            class="accordion-button collapsed px-1 shadow-none"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#IncomecollapseOne"
                                            aria-expanded="true"
                                            aria-controls="IncomecollapseOne"
                                        >
                                            &nbsp;&nbsp;Income Details
                                        </button>
                                    </h2>
                                    <div
                                        id="IncomecollapseOne"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="IncomeheadingOne"
                                        data-bs-parent="#IncomeaccordionExample1"
                                    >
                                        <div class="accordion-body px-1">
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Income from Salary</div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setIncome(150000000000);
                                                                    setLessIncomeVal(e.target.value > 50000 ? 50000 : e.target.value);
                                                                } else if (e.target.value == "") {
                                                                    setLessIncomeVal(0);
                                                                    setIncome(0);
                                                                } else if (e.target.value == 0) {
                                                                    setLessIncomeVal(0);
                                                                    setIncome(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setIncome(va);
                                                                        setLessIncomeVal(va > 50000 ? 50000 : va);
                                                                    } else if (
                                                                        incomeVal == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setIncome(va);
                                                                        setLessIncomeVal(va > 50000 ? 50000 : va);
                                                                    } else {
                                                                        setIncome(e.target.value)
                                                                        setLessIncomeVal(e.target.value > 50000 ? 50000 : e.target.value);
                                                                    }
                                                                }
                                                            }


                                                        }}

                                                        value={incomeVal}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        defaultValue={0}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right"
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            {lessIncomeVal > 0 ?
                                                <div className="d-flex justify-content-between mt-3">
                                                    <div className="ta-textTitle">Less: Standard deduction u/s 16(ia)</div>
                                                    <div className="d-flex" style={{ width: 180 }}>
                                                        <span />
                                                        &nbsp;
                                                        <div style={{ textAlign: 'end', width: '100%' }}> &nbsp; &nbsp; <b>₹ {lessIncomeVal}</b></div>
                                                    </div>
                                                </div>
                                                : ""}




                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Less: Entertainment allowance u/s 16(ii)</div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 5000) {
                                                                    setIntertainmentAllownce(5000);
                                                                } else if (e.target.value == "") {
                                                                    setIntertainmentAllownce(0);
                                                                } else if (e.target.value == 0) {
                                                                    setIntertainmentAllownce(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setIntertainmentAllownce(va);
                                                                    } else if (
                                                                        intertainmentAllownce == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setIntertainmentAllownce(va);
                                                                    } else {
                                                                        setIntertainmentAllownce(e.target.value);
                                                                    }
                                                                }
                                                            }

                                                            // setTimeout(() => {
                                                            //     if (ageCategory == 60) {
                                                            //         if (parseInt(e.target.value) > 10000) {
                                                            //             setD80TTA(10000);
                                                            //         } else {
                                                            //             setD80TTA(e.target.value);
                                                            //         }
                                                            //     } else if (ageCategory != 60) {
                                                            //         if (parseInt(e.target.value) > 50000) {
                                                            //             setD80TTA(50000);
                                                            //         } else {
                                                            //             setD80TTA(e.target.value);
                                                            //         }
                                                            //     }
                                                            // }, 500);



                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={intertainmentAllownce}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>


                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Less : Professinal Tax 16(iii)</div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 5000) {
                                                                    setProfessinalTax(5000);
                                                                } else if (e.target.value == "") {
                                                                    setProfessinalTax(0);
                                                                } else if (e.target.value == 0) {
                                                                    setProfessinalTax(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setProfessinalTax(va);
                                                                    } else if (
                                                                        professinalTax == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setProfessinalTax(va);
                                                                    } else {
                                                                        setProfessinalTax(e.target.value);
                                                                    }
                                                                }
                                                            }

                                                            // setTimeout(() => {
                                                            //     if (ageCategory == 60) {
                                                            //         if (parseInt(e.target.value) > 10000) {
                                                            //             setD80TTA(10000);
                                                            //         } else {
                                                            //             setD80TTA(e.target.value);
                                                            //         }
                                                            //     } else if (ageCategory != 60) {
                                                            //         if (parseInt(e.target.value) > 50000) {
                                                            //             setD80TTA(50000);
                                                            //         } else {
                                                            //             setD80TTA(e.target.value);
                                                            //         }
                                                            //     }
                                                            // }, 500);



                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={professinalTax}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>


                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Interest from Saving’s bank account</div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setIncomeFromInterest(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setIncomeFromInterest(0);
                                                                } else if (e.target.value == 0) {
                                                                    setIncomeFromInterest(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setIncomeFromInterest(va);
                                                                    } else if (
                                                                        incomefromInterest == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setIncomeFromInterest(va);
                                                                    } else {
                                                                        setIncomeFromInterest(e.target.value);
                                                                    }
                                                                }
                                                            }

                                                            setTimeout(() => {
                                                                if (ageCategory == 60) {
                                                                    if (parseInt(e.target.value) > 10000) {
                                                                        setD80TTA(10000);
                                                                    } else {
                                                                        setD80TTA(e.target.value);
                                                                    }
                                                                } else if (ageCategory != 60) {
                                                                    if (parseInt(e.target.value) > 50000) {
                                                                        setD80TTA(50000);
                                                                    } else {
                                                                        setD80TTA(e.target.value);
                                                                    }
                                                                }
                                                            }, 500);



                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={incomefromInterest}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Other Interest</div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setOtherInterest(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setOtherInterest(0);
                                                                } else if (e.target.value == 0) {
                                                                    setOtherInterest(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setOtherInterest(va);
                                                                    } else if (
                                                                        otherInterest == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setOtherInterest(va);
                                                                    } else {
                                                                        setOtherInterest(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={otherInterest}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Rental Income Received </div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setRentalIncomeReceived(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setRentalIncomeReceived(0);
                                                                } else if (e.target.value == 0) {
                                                                    setRentalIncomeReceived(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setRentalIncomeReceived(va);
                                                                    } else if (
                                                                        rentalIncomeReceived == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setRentalIncomeReceived(va);
                                                                    } else {
                                                                        setRentalIncomeReceived(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={rentalIncomeReceived}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Income from long term gain </div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setIncomeFromDigitalAssets(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setIncomeFromDigitalAssets(0);
                                                                } else if (e.target.value == 0) {
                                                                    setIncomeFromDigitalAssets(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setIncomeFromDigitalAssets(va);
                                                                    } else if (
                                                                        incomeFromDigitalAssets == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setIncomeFromDigitalAssets(va);
                                                                    } else {
                                                                        setIncomeFromDigitalAssets(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={incomeFromDigitalAssets}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Short term capital gain </div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setShortTermCapitalGain(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setShortTermCapitalGain(0);
                                                                } else if (e.target.value == 0) {
                                                                    setShortTermCapitalGain(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setShortTermCapitalGain(va);
                                                                    } else if (
                                                                        shortTermCapitalGain == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setShortTermCapitalGain(va);
                                                                    } else {
                                                                        setShortTermCapitalGain(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={shortTermCapitalGain}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Other Income </div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setOtherIncome(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setOtherIncome(0);
                                                                } else if (e.target.value == 0) {
                                                                    setOtherIncome(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setOtherIncome(va);
                                                                    } else if (
                                                                        otherIncome == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setOtherIncome(va);
                                                                    } else {
                                                                        setOtherIncome(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={otherIncome}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            {newRegime == true ? '' : <>
                                                {/* <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Exempt Allowances </div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}
                                                        value={exemptAllowances}
                                                       
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) {
                                                            } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setExemptAllowances(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setExemptAllowances(0);
                                                                } else if (e.target.value == 0) {
                                                                    setExemptAllowances(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setExemptAllowances(va);
                                                                    } else if (
                                                                        incomeVal == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setExemptAllowances(va);
                                                                    } else {
                                                                        setExemptAllowances(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div> */}
                                            </>}
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle">Business Income </div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1"
                                                        id="fname"
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        onChange={(e) => {
                                                            if (isNaN(e.target.value)) { } else if (e.target.value.includes("+") == true) {
                                                            } else if (e.target.value.includes("-") == true) {
                                                            } else if (e.target.value.includes(".") == true) {
                                                            } else {
                                                                if (e.target.value > 150000000000) {
                                                                    setBusinessImcome(150000000000);
                                                                } else if (e.target.value == "") {
                                                                    setBusinessImcome(0);
                                                                } else if (e.target.value == 0) {
                                                                    setBusinessImcome(0);
                                                                } else {
                                                                    if (
                                                                        e.target.value.indexOf(0) == "0" &&
                                                                        e.target.value.length > 1
                                                                    ) {
                                                                        let va = e.target.value.substring(1);
                                                                        setBusinessImcome(va);
                                                                    } else if (
                                                                        businessImcome == 0 &&
                                                                        e.target.value?.split("")[1] == "0"
                                                                    ) {
                                                                        let va = e.target.value?.split("")[0];
                                                                        setBusinessImcome(va);
                                                                    } else {
                                                                        setBusinessImcome(e.target.value);
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                        className="shadow-none form-control sm-bg"
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        value={businessImcome}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="ta-textTitle"> Total income</div>
                                                <div className="d-flex" style={{ width: 180 }}>
                                                    <span />
                                                    &nbsp;
                                                    <input
                                                        className="form-control shadow-none sm-fl1 bg-pink1"
                                                        id=""
                                                        readOnly
                                                        defaultValue="₹"
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingRight: 1,
                                                        }}
                                                    />
                                                    <input
                                                        readOnly
                                                        value={displayValue}
                                                        className="shadow-none form-control sm-bg bg-pink1"
                                                        type="text"
                                                        id=""
                                                        name="fname"
                                                        defaultValue={0}
                                                        style={{
                                                            background: "rgba(0, 115, 187, 0.1)",
                                                            paddingLeft: 0,
                                                            textAlign: "right"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {newRegime == true ? "" :
                                    <>
                                        <div class="accordion-item mt-5" id="IncomeaccordionExample1">
                                            <h2 class="accordion-header" id="IncomeheadingTwo">
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#IncomecollapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="IncomecollapseTwo"
                                                >
                                                    &nbsp;&nbsp; Deductions
                                                </button>
                                            </h2>
                                            <div
                                                id="IncomecollapseTwo"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="IncomeheadingTwo"
                                                data-bs-parent="#IncomeaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Basic Deductions - 80C</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000) {
                                                                            setD80C(150000);
                                                                        } else if (e.target.value == "") {
                                                                            setD80C(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setD80C(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setD80C(va);
                                                                            } else if (
                                                                                d80C == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setD80C(va);
                                                                            } else {
                                                                                setD80C(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={d80C}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Medical Insurance - 80D</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 25000) {
                                                                            setD80D(25000);
                                                                        } else if (e.target.value == "") {
                                                                            setD80D(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setD80D(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setD80D(va);
                                                                            } else if (
                                                                                d80D == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setD80D(va);
                                                                            } else {
                                                                                setD80D(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={d80D}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Interest on Educational Loan - 80E </div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000000000) {
                                                                            setD80E(150000000000);
                                                                        } else if (e.target.value == "") {
                                                                            setD80E(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setD80E(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setD80E(va);
                                                                            } else if (
                                                                                d80E == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setD80E(va);
                                                                            } else {
                                                                                setD80E(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={d80E}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Employee's contribution to NPS - 80CCD 1B </div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 50000) {
                                                                            setD80CCD(50000);
                                                                        } else if (e.target.value == "") {
                                                                            setD80CCD(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setD80CCD(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setD80CCD(va);
                                                                            } else if (
                                                                                d80CCD == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setD80CCD(va);
                                                                            } else {
                                                                                setD80CCD(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={d80CCD}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Interest from Deposits - 80TTA</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                readOnly
                                                                // onChange={(e) => {

                                                                // }}

                                                                value={d80TTA}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Donations to Charity - 80G</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 10) {
                                                                            setD80G(10);
                                                                        } else if (e.target.value == "") {
                                                                            setD80G(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setD80G(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setD80G(va);
                                                                            } else if (
                                                                                d80G == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setD80G(va);
                                                                            } else {
                                                                                setD80G(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={d80G}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Interest on Housing Loan - 80EE</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000) {
                                                                            setD80EEA(150000);
                                                                        } else if (e.target.value == "") {
                                                                            setD80EEA(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setD80EEA(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setD80EEA(va);
                                                                            } else if (
                                                                                d80EEA == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setD80EEA(va);
                                                                            } else {
                                                                                setD80EEA(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={d80EEA}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Interest on Home Loan- Self occupied</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                value={interestOnHomeLoanSelfOccupied}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 200000) {
                                                                            setInterestOnHomeLoanSelfOccupied(200000);
                                                                        } else if (e.target.value == "") {
                                                                            setInterestOnHomeLoanSelfOccupied(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setInterestOnHomeLoanSelfOccupied(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setInterestOnHomeLoanSelfOccupied(va);
                                                                            } else if (
                                                                                interestOnHomeLoanSelfOccupied == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setInterestOnHomeLoanSelfOccupied(va);
                                                                            } else {
                                                                                setInterestOnHomeLoanSelfOccupied(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                                    {rentalIncomeReceived > 0 ?
                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div className="ta-textTitle">Interest on Home Loan- Let Out</div>
                                                            <div className="d-flex" style={{ width: 180 }}>
                                                                <span />
                                                                &nbsp;
                                                                <input
                                                                    className="form-control shadow-none sm-fl1"
                                                                    id="fname"
                                                                    readOnly
                                                                    defaultValue="₹"
                                                                    style={{
                                                                        background: "rgba(0, 115, 187, 0.1)",
                                                                        paddingRight: 1,
                                                                    }}
                                                                />
                                                                <input
                                                                    className="shadow-none form-control sm-bg"
                                                                    type="text"
                                                                    id="fname"
                                                                    name="fname"
                                                                    value={interestOnHomeLoanLetOut}
                                                                    style={{
                                                                        background: "rgba(0, 115, 187, 0.1)",
                                                                        paddingLeft: 0,
                                                                        textAlign: "right",
                                                                    }}
                                                                    onChange={(e) => {
                                                                        if (isNaN(e.target.value)) {
                                                                        } else if (e.target.value.includes("+") == true) {
                                                                        } else if (e.target.value.includes("-") == true) {
                                                                        } else if (e.target.value.includes(".") == true) {
                                                                        } else {
                                                                            if (e.target.value > 200000) {
                                                                                setInterestOnHomeLoanLetOut(200000);
                                                                            } else if (e.target.value == "") {
                                                                                setInterestOnHomeLoanLetOut(0);
                                                                            } else if (e.target.value == 0) {
                                                                                setInterestOnHomeLoanLetOut(0);
                                                                            } else {
                                                                                if (
                                                                                    e.target.value.indexOf(0) == "0" &&
                                                                                    e.target.value.length > 1
                                                                                ) {
                                                                                    let va = e.target.value.substring(1);
                                                                                    setInterestOnHomeLoanLetOut(va);
                                                                                } else if (
                                                                                    interestOnHomeLoanLetOut == 0 &&
                                                                                    e.target.value?.split("")[1] == "0"
                                                                                ) {
                                                                                    let va = e.target.value?.split("")[0];
                                                                                    setInterestOnHomeLoanLetOut(va);
                                                                                } else {
                                                                                    setInterestOnHomeLoanLetOut(e.target.value);
                                                                                }
                                                                            }
                                                                        }
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mt-5" id="IncomeaccordionExample1">
                                            <h2 class="accordion-header" >
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#IncomeheadingFour"
                                                    aria-expanded="false"
                                                    aria-controls="IncomeheadingFour"
                                                >
                                                    &nbsp;&nbsp; HRA
                                                </button>
                                            </h2>
                                            <div
                                                id="IncomeheadingFour"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="IncomeheadingFour"
                                                data-bs-parent="#IncomeaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Basic salary received per annum</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000000000) {
                                                                            setBasicHRA(150000000000);
                                                                        } else if (e.target.value == "") {
                                                                            setBasicHRA(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setBasicHRA(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setBasicHRA(va);
                                                                            } else if (
                                                                                basicHRA == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setBasicHRA(va);
                                                                            } else {
                                                                                setBasicHRA(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={basicHRA}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Dearness allowance (DA) received per annum</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000000000) {
                                                                            setDearnessHRA(150000000000);
                                                                        } else if (e.target.value == "") {
                                                                            setDearnessHRA(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setDearnessHRA(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setDearnessHRA(va);
                                                                            } else if (
                                                                                dearnessHRA == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setDearnessHRA(va);
                                                                            } else {
                                                                                setDearnessHRA(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={dearnessHRA}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">HRA received per annum </div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000000000) {
                                                                            setReceivedHRA(150000000000);
                                                                        } else if (e.target.value == "") {
                                                                            setReceivedHRA(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setReceivedHRA(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setReceivedHRA(va);
                                                                            } else if (
                                                                                receivedHRA == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setReceivedHRA(va);
                                                                            } else {
                                                                                setReceivedHRA(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={receivedHRA}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Total rent paid per annum</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                className="shadow-none form-control sm-bg"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"
                                                                onChange={(e) => {
                                                                    if (isNaN(e.target.value)) {
                                                                    } else if (e.target.value.includes("+") == true) {
                                                                    } else if (e.target.value.includes("-") == true) {
                                                                    } else if (e.target.value.includes(".") == true) {
                                                                    } else {
                                                                        if (e.target.value > 150000000000) {
                                                                            setRentPaidHRA(150000000000);
                                                                        } else if (e.target.value == "") {
                                                                            setRentPaidHRA(0);
                                                                        } else if (e.target.value == 0) {
                                                                            setRentPaidHRA(0);
                                                                        } else {
                                                                            if (
                                                                                e.target.value.indexOf(0) == "0" &&
                                                                                e.target.value.length > 1
                                                                            ) {
                                                                                let va = e.target.value.substring(1);
                                                                                setRentPaidHRA(va);
                                                                            } else if (
                                                                                rentPaidHRA == 0 &&
                                                                                e.target.value?.split("")[1] == "0"
                                                                            ) {
                                                                                let va = e.target.value?.split("")[0];
                                                                                setRentPaidHRA(va);
                                                                            } else {
                                                                                setRentPaidHRA(e.target.value);
                                                                            }
                                                                        }
                                                                    }
                                                                }}

                                                                value={rentPaidHRA}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">Do you live in a metro city?</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            <select id="Select" value={metroCityHRA} onChange={(e) => {
                                                                setMetroCityHRA(e.target.value);
                                                            }} class="form-select">
                                                                <option value="yes">YES</option>
                                                                <option value="no">NO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="ta-textTitle">HRA deduction allowed</div>
                                                        <div className="d-flex" style={{ width: 180 }}>
                                                            <span />
                                                            &nbsp;
                                                            <input
                                                                className="form-control shadow-none sm-fl1 bg-pink1"
                                                                id="fname"
                                                                readOnly
                                                                defaultValue="₹"
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingRight: 1,
                                                                }}
                                                            />
                                                            <input
                                                                readOnly
                                                                className="shadow-none form-control sm-bg bg-pink1"
                                                                type="text"
                                                                id="fname"
                                                                name="fname"

                                                                value={hraFinal}
                                                                style={{
                                                                    background: "rgba(0, 115, 187, 0.1)",
                                                                    paddingLeft: 0,
                                                                    textAlign: "right",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-item mt-5">
                                            <h2 class="accordion-header" id="IncomeheadingThree">
                                                <button
                                                    class="accordion-button collapsed px-1 shadow-none"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#IncomecollapseThree"
                                                    aria-expanded="false"
                                                    aria-controls="IncomecollapseThree"
                                                >
                                                    &nbsp;&nbsp; Note
                                                </button>
                                            </h2>
                                            <div
                                                id="IncomecollapseThree"
                                                class="accordion-collapse collapse"
                                                aria-labelledby="IncomeheadingThree"
                                                data-bs-parent="#IncomeaccordionExample1"
                                            >
                                                <div class="accordion-body px-1">
                                                    <strong>
                                                        Not so fast! Please enter your salary and deductions
                                                        details to help us calculate your tax implications
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12 mb-5 pb-5">
                        <div className=" background px-4">
                            <div className="row pt-5">
                                <div className="col-4">
                                    <div></div>
                                </div>
                                <div className="col-4">
                                    <div>
                                        <strong>Old Regime</strong>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div>
                                        <strong>New Regime</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>Tax at Normal Rates</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTaxAtNormal}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {taxAtNormal}</div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>
                                        Tax at Special Rates
                                        <br />
                                        (Capital Gains, Lottery, etc.)
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {(oldTotalTaxOnIncome>0 && totalTaxOnIncome>0)?capGain:0}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {(oldTotalTaxOnIncome>0 && totalTaxOnIncome>0)?capGain:0}</div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>Total Tax before Rebate</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTaxBeforeRebate}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {taxBeforeRebate}</div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>Less: Tax Rebate u/s 87A</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTaxRebate}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {taxRebate}</div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>Net Tax after Rebate</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTaxAfterRebate}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {taxAfterRebate}</div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>Add: Surcharge</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTurcharge}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {surcharge}</div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-4">
                                    <div>Add: Health & Education Cess</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTealthEducation}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {healthEducation}</div>
                                </div>
                            </div>
                            <div className="row pt-4 pb-5">
                                <div className="col-4">
                                    <div>
                                        <strong>Total Tax on Income</strong>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-pink py-1">₹ {oldTotalTaxOnIncome}</div>
                                </div>
                                <div className="col-4">
                                    <div className="bg-blue py-1">₹ {totalTaxOnIncome}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-0" style={{ paddingLeft: 12, paddingRight: 20 }}>
                        <div className="col-lg-7 col-12 background" style={{ marginLeft: 10 }}>
                            <div >
                                <div style={{ marginLeft: 10, marginTop: 10 }}>

                                    <strong>FAQs</strong>
                                </div>
                            </div>
                            <div class="accordion mt-0 mb-5" id="INTAXaccordionExample1">
                                {/* <div class="accordion-item">
                                    <h2 class="accordion-header" id="INTAXheadingOne">
                                        <button
                                            class="accordion-button collapsed px-1 shadow-none"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#INTAXcollapseOne"
                                            aria-expanded="true"
                                            aria-controls="INTAXcollapseOne"
                                        >
                                            &nbsp;&nbsp;What is Income tax?
                                        </button>
                                    </h2>
                                    <div
                                        id="INTAXcollapseOne"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="INTAXheadingOne"
                                        data-bs-parent="#INTAXaccordionExample1"
                                    >
                                        <div class="accordion-body px-1">
                                            <>
                                                <p id="isPasted">
                                                    Insurance can indeed offer opportunities to save on income tax through various provisions and
                                                    sections of the tax code.
                                                </p>
                                            </>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="accordion-item pb-md-0" id="INTAXaccordionExample1">
                                    <h2 class="accordion-header" id="INTAXheadingTwo">
                                        <button
                                            class="accordion-button collapsed px-1 shadow-none"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#INTAXcollapseTwo"
                                            aria-expanded="false"
                                            aria-controls="INTAXcollapseTwo"
                                        >
                                            &nbsp;  How can Insurance help?
                                        </button>
                                    </h2>
                                    <div
                                        id="INTAXcollapseTwo"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="INTAXheadingTwo"
                                        data-bs-parent="#INTAXaccordionExample1"
                                    >
                                        <div class="accordion-body px-1">
                                            <p style={{ textAlign: "left" }} id="isPasted">
                                                Insurance can indeed offer opportunities to save on income tax through various provisions and
                                                sections of the tax code. Here are ways insurance can help you save income tax:<br /><br />

                                                <strong>Section 80C Deductions:</strong> Under Section 80C of the Income Tax Act in India, the premium paid for life
                                                insurance policies, including policies for yourself, your spouse, and your children, is eligible for
                                                deductions up to a specified limit (which is subject to change and should be verified each financial
                                                year). This deduction is part of the overall limit for various eligible investments and expenses.
                                                <br /> The maturity proceeds or death benefits received from a life insurance
                                                policy, including policies from both government and private insurers, are generally tax-free under
                                                Section 10(10D) of the Income Tax Act, provided certain conditions are met.<br /><br />
                                                <strong>Health Insurance Premium (Section 80D):</strong> Premiums paid for health insurance policies for yourself,
                                                your family, or parents can be claimed as deductions under Section 80D of the Income Tax Act in
                                                India. This deduction helps reduce your taxable income.
                                                <br /><br /><strong>Pension Plans (Section 80CCC and 10(10A)):</strong> Contributions made to pension plans offered by
                                                insurance companies can be eligible for deductions under Section 80CCC of the Income Tax Act.
                                                Additionally, the commuted value of pension received under these plans is partially exempted from
                                                tax under Section 10(10A).
                                                <br /><br /> <strong>Tax-Saving Riders:</strong> Some insurance policies offer riders (additional benefits) that qualify for tax
                                                deductions. For example, critical illness riders and disability riders may be eligible for deductions.
                                                <br /><br /> <strong>Income Replacement:</strong> Insurance provides financial protection in the event of unforeseen events,
                                                reducing the need for setting aside emergency funds, which could potentially be invested in tax-
                                                saving options. It's important to note that the tax benefits offered by insurance policies can vary
                                                based on the specific policy type, premium amount, and the provisions of the Income Tax Act in your
                                                country.
                                                Additionally, insurance should primarily be considered for its intended purpose, which is risk
                                                protection and financial security, rather than solely for tax-saving purposes. Before purchasing an
                                                insurance policy for tax-saving purposes, it's advisable to consult with a tax advisor or financial
                                                planner to ensure that it aligns with your overall financial goals and tax planning strategy. Tax laws
                                                and regulations can change, so staying informed about the latest updates is also essential.
                                            </p>

                                        </div>
                                        {/* <div className="pb-5"></div> */}
                                    </div>
                                    {/* <div className="pb-5"></div> */}
                                </div>
                                <div class="accordion-item" id="INTAXaccordionExample1">
                                    <h2 class="accordion-header" >
                                        <button
                                            class="accordion-button collapsed px-1 shadow-none"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#INTAXheadingFour"
                                            aria-expanded="false"
                                            aria-controls="INTAXheadingFour"
                                        >
                                            &nbsp; How to use the Income tax calculator for FY 2023-24 (AY 2024-25)?
                                        </button>
                                    </h2>
                                    <div
                                        id="INTAXheadingFour"
                                        class="accordion-collapse collapse"
                                        aria-labelledby="INTAXheadingFour"
                                        data-bs-parent="#INTAXaccordionExample1"
                                    >
                                        <div class="accordion-body px-1">
                                        Using the tax calculator involves these steps:<br /><br />      
                                        <strong>1.Select Your Age Category:</strong>  Tax liability in India varies based on age groups.
                                        <br/><strong>2.Choose the Financial Year:</strong>  Select the applicable financial year for tax calculation.
                                        <br/><strong>3.Enter Taxable Salary:</strong>  If using old tax slabs, enter salary after deducting
                                        exemptions like HRA, LTA, etc.
                                        <br/><strong>4.Or Enter Gross Salary:</strong>  For new tax slabs, enter the salary without availing
                                        exemptions.
                                        <br/><strong>5.Additional Income Details:</strong> Enter interest income, rental income, home loan
                                        interest for rented/self-occupied property, and income from digital assets.
                                        <br/><strong>6.Investment Declaration:</strong> For old tax slabs, enter tax-saving investments under
                                        sections 80C, 80D, 80G, 80E, and 80TTA.<br/><br/>
                                        <strong>Note:</strong><br/>
                                        ➔ If a field isn't applicable, enter "0".<br/> 
                                        ➔ The results will display instantly upon entering required details, showing a
                                        comparison between Old and New Regime for:<br/>
                                        <br/><strong>1.</strong> Tax at Normal Rates
                                        <br/><strong>2.</strong> Tax at Special Rates (e.g., Capital Gains)
                                        <br/><strong>3.</strong> Total Tax before Rebate
                                        <br/><strong>4.</strong> Less: Tax Rebate u/s 87A
                                        <br/><strong>5.</strong> Net Tax after Rebate
                                        <br/><strong>6.</strong> Additional Surcharge
                                        <br/><strong>7.</strong> Health & Education Cess
                                        <br/><strong>8.</strong> Total Tax on Income<br/>
                                        Effortlessly compute your tax liability under different regimes!
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5 col-12 ">
                        </div>
                        <div className="pb-5"></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}
