import React, { useEffect, useState } from "react";
import "./Health.css";
import axios from 'axios';
import "react-tooltip/dist/react-tooltip.css";
import "react-rangeslider/lib/index.css";
import { json, useNavigate } from "react-router-dom";
import Tabs from "../../components/tabs/tabs";
import City from "./city.json";
import Medicare from "./Medicare.json";
import MedicarePre from "./MedicarePremier.json";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MedicalModalComponent from "./modal-content";
import MedicalModalComponent1 from "./modal-content1";
import toast, { Toaster } from "react-hot-toast";
import img1 from '../../../assets/images/link-img-1.png'
import img2 from '../../../assets/images/link-imgs2.png'
export default function CalculatorSpeech() {
  const [getPlanFlag, setGetPlanFlag] = useState(false);
  const [cityValue, setCity] = useState("Zone-A");
  const [er, setEr] = useState("");
  const [nameEr, setNameEr] = useState("");
  const [name, setName] = useState("");
  const [eamil, setEmail] = useState("");
  const [insuredValue, setInsuredValue] = useState("5-lacs");
  const [ageValue, setAgeValue] = useState(0);
  const [insuredAmount1, setInsuredAmount1] = useState("");
  const [insuredAmountPre, setInsuredAmountPre] = useState("");
  const [insuredAmount, setInsuredAmount] = useState("");
  const [dob, setDob] = useState("");
  const [getMedicarePlanFlag, setMedicarePlanFlag] = useState(true);
  const [onShow, setonShow] = useState(false);
  const [onShow1, setonShow1] = useState(false);
  const [number, setNumber] = useState("");
  const [age, setAge] = useState("");
  const [option1, setoption1] = useState("NO");
  const [option2, setoption2] = useState("NO");
  const [option3, setoption3] = useState("NO");
  const [policyTenure, setPolicyTenure] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [basePremium, setBasePremium] = useState([]);
  const [erCov, setErCov] = useState("")
  const navigate = useNavigate();
  

  useEffect(() => {
    if (localStorage.getItem("state")) {
      let info = localStorage.getItem("state");
      let usr=JSON.parse(info);
      setName(usr?.name);
      setEmail(usr?.email);
      setNumber(usr?.number);
      setDob(usr?.age);
      setCity(usr?.cityValue?.split(",")[0]);
      if(usr?.age!=""){
        const getAge = Math.floor(
          (new Date() - new Date(usr?.age).getTime()) / 3.15576e10
        );
        setAge(getAge);
        if(usr?.policy==""){
          setPolicyTenure(getAge)
        }else{
          setPolicyTenure(parseInt(usr?.policy));
        }
      }
    } else {
      let initial = {
        name: '',
        email: "",
        age: '',
        age: "",
        cityValue: "Zone-A",
        number: "",
        policy:""
      }
      localStorage.setItem("state", JSON.stringify(initial));
    }
  }, []);


  return (
    <div>
      <Tabs />
      <Toaster
        position="top-right"
        reverseOrder={false}
        style={{ zIndex: 999999999 }}
      />
      <div className="custome-row">
        <div className="container-fluid ">
          <div className="path-text mt-4" style={{ marginLeft: 14 }}>
            {" "}
            <span
              className="ta-cursor"
              onClick={() => {
                navigate("/");
              }}
            >
              Calculator
            </span>{" "}
            &gt;<span style={{color:"#0073bb"}}>Health Calculator</span>
          </div>
          {/* <div
            className="mt-0 fd-cal-text mt-4 pb-1"
            style={{ marginLeft: 14 }}
          >
            Medical Calculator
          </div> */}
          <div className="">
            <div className="container-fluid ">
              <div className="row">
              </div>
            </div>
            <div className="m-5 pb-5">

              <div className="row">
                <div className="d-flex justify-content-center col-lg-12">
                     <a href="https://www.tataaia.com/health-insurance-plans/tata-aia-pro-fit.html"  target="_blank" style={{textDecoration:"underline"}}>
                      <div><img className="link-img" src={img2}  width={420} height={390}/></div>
                      </a>

                      <div style={{width:'10%'}}></div>
                      <div className="mt-lg-0">
                      <a href="https://vacademy.tataaia.com/Default.aspx?metadata=Zr2WplddJX3U2tDG9egQBz0yxVN9pSoSnElmhoM+eXQuVs3I3UIT5nt3YF+JgCoFZnNH2ITw89wkw1j89wPT3hywgDc3PpKyKTzpCVJc8/+NdIQVJYzuSjkOMRzwhcKPldbd3JOaH0O94janb2jWdhhTlUD0w5ouw8KmqhIXIyxMxLrlaEwcWrZFq5JeltK/" target="_blank">
                        <img className="link-img"  src={img1} width={420} height={390}/>
                        </a>
                        </div>
                </div>
                {/* <div class="form-check">
                <a href="https://www.tataaia.com/health-insurance-plans/tata-aia-pro-fit.html"  target="_blank" style={{textDecoration:"underline"}}>
                 <label class="form-check-label paraa" id="a-link" for="flexCheckDefault">
                 Tata AIA Pro Fit - Health and Financial Wellness Solution
                  </label>
                  </a>
                </div>

                <div class="form-check mt-2">
              <a href="https://vacademy.tataaia.com/Default.aspx?metadata=Zr2WplddJX3U2tDG9egQBz0yxVN9pSoSnElmhoM+eXQuVs3I3UIT5nt3YF+JgCoFZnNH2ITw89wkw1j89wPT3hywgDc3PpKyKTzpCVJc8/+NdIQVJYzuSjkOMRzwhcKPldbd3JOaH0O94janb2jWdhhTlUD0w5ouw8KmqhIXIyxMxLrlaEwcWrZFq5JeltK/" target="_blank">
                 <label id="a-link" class="form-check-label paraa" for="flexCheckDefault1">
                   Pro-Fit Library
                  </label>
                  </a>
                </div> */}
              </div>
            </div>

          </div>
          <div className="pb-5"></div>
        </div>
      </div>
    </div>
  );
}
